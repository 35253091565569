import { Directive, ElementRef, Input } from '@angular/core';
import { PermissionService } from '../services/permission.service';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[routerLink]',
})
export class RouterLinkDirective {
	@Input()
	set routerLink(route) {
		if (route) {
			if (route instanceof Array) {
				route = route.join('/');
			}
			if (route[0] === '/') {
				route = route.slice(1);
			}
			this.element.className = this.startingClass + (this.permissions.allowedToNavigate(route) ? '' : ' no-link');
		}
	}

	startingClass = '';
	element;

	constructor(private el: ElementRef, private permissions: PermissionService) {
		this.element = this.el.nativeElement;
		this.startingClass = this.el.nativeElement.className;
	}
}
