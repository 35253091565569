import sha256 from 'crypto-js/sha256';
import crylib from 'crypto-js/lib-typedarrays';
import base64 from 'crypto-js/enc-base64';

export class Utils {
	public static random(bytes: number) {
		return crylib.random(bytes);
	}

	public static sha256(value: string) {
		return sha256(value);
	}

	public static base64URLEncode(value) {
		return base64
			.stringify(value)
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	}
}
