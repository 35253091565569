import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpanderComponent } from './components/expander/expander.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
	imports: [CommonModule, MatExpansionModule],
	declarations: [ExpanderComponent],
	exports: [ExpanderComponent],
})
export class ExpanderModule {}
