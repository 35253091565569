import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { PageComponent } from './components/page/page.component';
import { TabsModule } from '@mtc/shared/tabs';
import { DirectivesModule } from '@mtc/shared/directives';
import { AlertComponent } from './components/alert/alert.component';
import { SharedPermissionModule } from '@mtc/shared/permissions';
import { PipesModule } from '@mtc/shared/pipes';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		MatProgressBarModule,
		MatIconModule,
		TabsModule,
		SharedPermissionModule,
		PipesModule,
	],
	declarations: [PageComponent, AlertComponent],
	exports: [PageComponent, AlertComponent, DirectivesModule, SharedPermissionModule],
})
export class ToolsCommonModule {}
