import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateConfig } from '../../models/dialog-template.config';

@Component({
	selector: 'mtc-confirmation',
	templateUrl: './confirmation.dialog.html',
	styleUrls: ['./confirmation.dialog.scss'],
})
export class ConfirmationDialog implements OnInit {
	dataInput = {
		cancelButtonText: 'cancel',
		confirmationButtonText: 'remove',
		content: 'Are you sure?',
		title: '',
		disableClose: false,
	};

	@Input() config: any;
	@Output() confirmation = new EventEmitter<boolean>();

	dialogTemplateConfig: DialogTemplateConfig = {
		title: '',
	};

	constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data: any) {}

	ngOnInit() {
		this.dataInput = this.config || this.data || this.dataInput;
		this.setDialogTemplateConfig();
	}

	setDialogTemplateConfig() {
		this.dialogTemplateConfig = {
			title: this.dataInput.title,
			cancelText: this.dataInput.disableClose ? '' : this.dataInput.cancelButtonText,
			confirmText: this.dataInput.confirmationButtonText,
			cancelClick: () => this.cancel(),
			confirmClick: () => this.confirm(),
			closeClick: () => this.dialogRef.close(null),
			isSimpleConfirmation: true,
			// TODO: What is this?
			formId: 'simpleConfirmationForm',
		};
	}

	cancel() {
		if (this.config) {
			this.confirmation.emit(false);
		} else {
			this.dialogRef.close(false);
		}
	}

	confirm() {
		if (this.config) {
			this.confirmation.emit(true);
		} else {
			this.dialogRef.close(true);
		}
	}
}
