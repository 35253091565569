export class AuthConfig {
	domain: string;
	provider: string;
	clientId: string;
	refreshDays: number;
	scopes: string[];
	redirectUri: string;
	logoutUri?: string;
	isMobile?: boolean;
	notifyBeforeExp?: number; // minutes
}
