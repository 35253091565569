import { Component, OnInit, Inject } from '@angular/core';
import { MtcInfoService } from '@mtc/shared/info';
import { PermissionService } from '@mtc/shared/permissions';
import { DialogTemplateConfig } from '@mtc/shared/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MtcDatePipe } from '@mtc/shared/pipes';
import * as moment from 'moment';

@Component({
	selector: 'mtc-edit-missionary-dialog',
	templateUrl: './edit-missionary-assignment.dialog.html',
	styleUrls: ['./edit-missionary-assignment.dialog.scss'],
})
export class EditMissionaryAssignmentDialog implements OnInit {
	form: FormGroup;
	missionaryAssignmentInfo;
	changes = [];
	filteredResidences = [];
	sameGender = true;
	youngMissionaries = [];

	dialogTemplateConfig: DialogTemplateConfig = {
		title: 'Edit Assignment Information',
	};

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private fb: FormBuilder,
		private mtcDate: MtcDatePipe,
		public mtcInfo: MtcInfoService,
		public permission: PermissionService,
	) {}

	ngOnInit() {
		this.missionaryAssignmentInfo = this.dialogData.assignmentInfo || {};
		this.initForm();
		this.initRooms();
	}

	initRooms() {
		this.sameGender = this.dialogData.missionaries.every(
			(m) => m.gender === this.dialogData.missionaries[0].gender,
		);
		this.youngMissionaries = this.dialogData.missionaries.filter((m) =>
			['Elder', 'Sister'].includes(m.missionaryType),
		);
		this.filteredResidences = this.mtcInfo.info.residences.filter((r) => {
			return (
				!r.roomType ||
				(!this.youngMissionaries.length && (r.roomType === 'Couple' || r.roomType === 'Senior Sister')) ||
				(this.sameGender &&
					this.youngMissionaries.length === this.dialogData.missionaries.length &&
					r.roomType === this.dialogData.missionaries[0].missionaryType)
			);
		});
	}

	initForm() {
		this.form = this.fb.group({
			actualArrivalDate: [this.mtcDate.transform(this.missionaryAssignmentInfo.actualArrivalDate)],
			actualArrivalTime: [this.missionaryAssignmentInfo.actualArrivalDate],
			actualDepartureDate: [this.mtcDate.transform(this.missionaryAssignmentInfo.actualDepartureDate)],
			actualDepartureTime: [this.missionaryAssignmentInfo.actualDepartureDate],
			trainingLanguage: [this.missionaryAssignmentInfo.trainingLanguage],
			trainingGroup: [
				this.mtcInfo.info.traininggroups.find(
					(group) => group.id.toString() === this.missionaryAssignmentInfo.trainingGroupId,
				),
			],
			branchDistrict: [this.missionaryAssignmentInfo.branchDistrict],
			classroom: [this.missionaryAssignmentInfo.classroom],
			residence: [this.missionaryAssignmentInfo.residenceRoomNum],
			schedule: [this.missionaryAssignmentInfo.schedule],
			mailbox: [this.missionaryAssignmentInfo.mailboxName],
			subStatus: [this.missionaryAssignmentInfo.subStatus],
			schedArrivalDate: [this.mtcDate.transform(this.missionaryAssignmentInfo.schedArrivalDate)],
			schedDepartureDate: [this.mtcDate.transform(this.missionaryAssignmentInfo.schedDepartureDate)],
		});
	}

	save() {
		if (this.form.valid) {
			Object.keys(this.form.controls).forEach((c) => {
				if (!this.form.controls[c].pristine) {
					if (c.includes('Date') || c.includes('Time')) {
						this.addDateTimeChange(c);
					} else {
						this.addChange(c);
					}
				}
			});
			this.dialogRef.close({ changes: this.changes, updatedAssignmentInfo: this.missionaryAssignmentInfo });
		}
	}

	addDateTimeChange(controlName) {
		const dateControlName = controlName.slice(0, -4) + 'Date';
		const timeControlName = controlName.slice(0, -4) + 'Time';
		const newDate = moment(this.form.value[dateControlName])
			.add({
				hours: +moment(this.form.value[timeControlName]).format('H'),
				minutes: +moment(this.form.value[timeControlName]).format('mm'),
			})
			.toDate();
		this.form.controls[dateControlName].setValue(newDate);

		if (!this.changes.some((c) => c.name === this.getName(dateControlName))) {
			this.addChange(dateControlName);
		}
	}

	addChange(controlName) {
		const change = {
			name: this.getName(controlName),
			value: this.form.value[controlName].id || this.form.value[controlName].getTime(),
			valueName: this.form.value[controlName].name,
		};
		this.changes.push(change);

		const id = controlName === 'branchDistrict' ? 'ecclGroupId' : controlName + 'Id';

		this.missionaryAssignmentInfo[controlName] = change.valueName || change.value;
		this.missionaryAssignmentInfo[id] = change.value;
	}

	/**
	 * Convert the formControlName to match the map (switch statements) in the missionary change controller.
	 *
	 * @param controlName
	 */
	getName(controlName) {
		switch (controlName) {
			case 'subStatus':
				return 'Sub-status';
			case 'schedArrivalDate':
				return 'Scheduled Arrival Date';
			case 'schedDepartureDate':
				return 'Scheduled Departure Date';
			case 'branchDistrict':
				return 'District';
		}
		return (controlName.charAt(0).toUpperCase() + controlName.substr(1)).split(/(?=[A-Z])/).join(' ');
	}
}
