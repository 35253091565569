<mtc-dialog-template cy-elm="edit-assignment-info-modal" [config]="dialogTemplateConfig">
	<div class="sub-header" cy-elm="edit-assignment-sub-header" [class.with-count]="dialogData.missionaries.length">
		<div *ngIf="dialogData.missionaries.length"><strong>{{'Missionary' | pluralize : dialogData.missionaries.length : true}}</strong> will be affected by this change.</div>
		Only the following assignment information can be edited.
	</div>
	<form id="formId" [formGroup]="form" (ngSubmit)="save()">
		<div class="input-container">
			<ng-container *ngIf="permission.has('MSNY_ASMT_EDIT_ACTUAL')">
				<mtc-date-picker cy-elm="edit-actual-arrival-date" class="form-field" placeholder="Actual Arrival Date" formControlName="actualArrivalDate"></mtc-date-picker>
				<mtc-time-picker cy-elm="edit-actual-arrival-time" class="form-field" placeholder="Actual Arrival Time" formControlName="actualArrivalTime"></mtc-time-picker>
				<mtc-date-picker cy-elm="edit-actual-departure-date" class="form-field" placeholder="Actual Departure Date" formControlName="actualDepartureDate"></mtc-date-picker>
				<mtc-time-picker cy-elm="edit-actual-departure-time" class="form-field" placeholder="Actual Departure Time" formControlName="actualDepartureTime"></mtc-time-picker>
			</ng-container>
			<ng-container *ngIf="permission.has('MSNY_ASMT_EDIT_SCHD')">
				<mtc-autocomplete cy-elm="edit-training-language" class="form-field" placeholder="Training Language" items="traininglanguages" formControlName="trainingLanguage"></mtc-autocomplete>
				<mtc-autocomplete cy-elm="edit-training-group" class="form-field" placeholder="Training Group" items="traininggroups" formControlName="trainingGroup"></mtc-autocomplete>
				<mtc-autocomplete cy-elm="edit-schedule" class="form-field" placeholder="Training Schedule" items="schedules" formControlName="schedule"></mtc-autocomplete>
				<mtc-autocomplete cy-elm="edit-branch-district" class="form-field" placeholder="District" items="districts" formControlName="branchDistrict"></mtc-autocomplete>
				<mtc-autocomplete cy-elm="edit-classroom" class="form-field" placeholder="Classroom" items="classrooms" formControlName="classroom"></mtc-autocomplete>
				<mtc-autocomplete cy-elm="edit-residence-room" *ngIf="filteredResidences.length" class="form-field" placeholder="Residence" [items]="filteredResidences" formControlName="residence"></mtc-autocomplete>
				<mat-form-field class="example-full-width" *ngIf="!filteredResidences.length">
					<input matInput placeholder="Residence Room" disabled>
					<mat-hint class="disabled-hint">Editable if same gender selected</mat-hint>
				</mat-form-field>
				<mtc-autocomplete cy-elm="edit-mailbox" class="form-field" placeholder="Mailbox" items="mailboxes" formControlName="mailbox"></mtc-autocomplete>
				<mtc-date-picker cy-elm="edit-scheduled-arrival" class="form-field" placeholder="Scheduled Arrival" formControlName="schedArrivalDate"></mtc-date-picker>
				<mtc-autocomplete cy-elm="edit-sub-status" class="form-field" placeholder="Sub-Status" items="substatuses" formControlName="subStatus"></mtc-autocomplete>
			</ng-container>
			<mtc-date-picker *ngIf="permission.has('MSNY_ASMT_EDIT_TRVL')" cy-elm="edit-scheduled-departure"
				class="form-field" placeholder="Scheduled Departure" formControlName="schedDepartureDate">
			</mtc-date-picker>
		</div>
	</form>
</mtc-dialog-template>
