<div class="end-menu">
	<div class="header">
		<div class="user-info">
			<img src="http://app.mtc.byu.edu/mtctools/v1/photo?type=person&id={{user?.ldsId}}">
			<div class="name">{{user?.preferredName}}</div>
			<div class="mtc">{{selectedMTC}}</div>
		</div>
		<div class="line"></div>
	</div>
	<div class="links">
		<div class="link" (click)="showMtcs()" *ngIf="permissions.has('CHANGE_MTC')">Change MTC</div>
		<ng-container *ngIf="showAllMtcs">
			<div class="mtc link" *ngFor="let mtc of mtcs" (click)="updateUserMtc(mtc)">{{mtc.unitName}}</div>
		</ng-container>
		<div class="link" [routerLink]="['/staff/employee/view', user?.ldsId]" (click)="close.emit()" cy-elm="view-my-profile">View My Profile</div>
		<div class="link" (click)="openManageFavoritesModal()">Manage Favorites</div>
		<div class="link" (click)="logout()">Logout</div>
		<div class="version">Version {{version}}</div>
	</div>
</div>
