import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Self, Optional, OnInit } from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { isString, isNil, isObject, isEqual, find, includes, pullAllWith } from 'lodash';
import { MtcInfoService } from '@mtc/shared/info';
import { OnChange } from 'property-watch-decorator';
import { ChipChangeArguments, InputHeightArguments, ChosenItem, SearchArguments } from './multi-autocomplete.model';

@Component({
	selector: 'mtc-multi-autocomplete',
	templateUrl: './multi-autocomplete.component.html',
	styleUrls: ['./multi-autocomplete.component.scss'],
	providers: [],
})
export class MultiAutocompleteComponent implements OnInit, ControlValueAccessor {
	@ViewChild('input', { static: false }) private input: ElementRef;
	@ViewChild('multis', { static: false }) private multis: ElementRef;
	@Output() itemChosenChange: EventEmitter<any> = new EventEmitter<any>();
	//returns an [] that if passed back into autocomplete will return the same chips and Items selected
	@Output() chipsChange: EventEmitter<any> = new EventEmitter<any>();
	@OnChange(function() {
		if (this.lastValue) {
			this.writeValue(this.lastValue);
		}
	})
	@Input()
	displayBy = 'name';
	@Input() partial = false;
	@Input() placeholder: string;
	@OnChange(function() {
		if (this.items) {
			if (isString(this.items)) {
				const data = this.infoService.info[this.items];
				if (data) {
					this.searchResults = data;
					this.itemList = data.filter((d) => d[this.displayBy]);
				}
			} else {
				this.searchResults = this.items;
				this.itemList = this.items.filter((d) => d[this.displayBy]);
			}
		}
	})
	@Input()
	items;
	@OnChange(function() {
		this.setUpChips(this.itemChosen);
		if (isNil(this.itemChosen) || !this.itemChosen.length) {
			this.childControl.setValue('');
			this.hideInput = false;
		}
	})
	@Input()
	itemChosen;
	@Input() pillRows = 6;

	private confirmBackspace: boolean;
	private isFocused = false;
	itemList: any = [];
	formChange: (value: any) => void;
	childControl = new FormControl('');
	childControlValue;
	lastValue = '';
	multiItems = [];
	multiChips: any[] = [];
	showDropDown: boolean;
	searchResults = [];
	keyPress: Subject<any> = new Subject<any>();
	inputHeight;
	hideInput = false;
	onTouched = () => {};

	get errors() {
		return this.childControl.errors;
	}
	get parentControl() {
		if (this.controlDir) {
			return this.controlDir.control;
		}
	}

	constructor(
		public infoService: MtcInfoService,
		@Self()
		@Optional()
		public controlDir: NgControl,
	) {
		if (this.controlDir) {
			this.controlDir.valueAccessor = this;
		}
	}

	ngOnInit() {
		this.initForm();
	}

	setUpChips(value) {
		if (isObject(value)) {
			this.multiChips = [];
			const arrayHolder = this.chipChange({
				value: value,
				items: this.multiItems,
				chips: this.multiChips,
				display: this.displayBy,
			});
			this.multiItems = arrayHolder.items;
			this.multiChips = arrayHolder.chips;
			this.hideInput = !!this.multiChips.length;
		} else if (value === undefined) {
			this.multiChips = [];
			this.multiItems = [];
		}

		setTimeout(() => {
			this.inputHeight = this.setHeight({
				clientHeight: this.multis.nativeElement.clientHeight,
				hideInput: this.hideInput,
			});
		});
	}

	onKeyUp(keyPress: KeyboardEvent) {
		this.childControlValue = this.childControl.value;
		switch (keyPress.key) {
			case 'ArrowUp':
			case 'ArrowDown':
			case 'Enter':
				this.keyPress.next(keyPress.key);
				keyPress.stopPropagation();
				break;
			case 'Tab':
				this.setFocus(true);
				break;
			case 'Backspace':
				if (!this.childControl.value && this.multiItems.length && this.confirmBackspace) {
					this.multiItems = this.removeMultiItems({
						chips: this.multiChips.pop().childItems,
						items: this.multiItems,
					});
					this.changeValues();
					this.setFocus(false);
				}
				this.confirmBackspace = !this.childControl.value;
				this.search();
				break;
			default:
				this.confirmBackspace = !this.childControl.value;
				this.search();
		}
	}

	chooseItem(chosenItem: any) {
		const arrayHolder = this.handleChosenItem(
			{ items: this.multiItems, chips: this.multiChips, display: this.displayBy },
			{ controlValue: this.childControlValue, chosenItem: chosenItem, partial: this.partial },
			this.searchResults,
		);
		this.multiChips = arrayHolder.chips;
		this.multiItems = arrayHolder.items;

		this.search();
		this.changeValues();
		this.setFocus(false);
		setTimeout(() => {
			this.setHeight({
				clientHeight: this.multis.nativeElement.clientHeight,
				hideInput: this.hideInput,
			});
		});
	}

	search() {
		const updatedSearch: any = this.filterSearchResults({
			searchResults: this.searchResults,
			items: this.itemList,
			display: this.displayBy,
			controlValue: this.childControl.value,
			multiItems: this.multiItems,
			partial: this.partial,
			dropdown: this.showDropDown,
		});

		this.searchResults = updatedSearch.searchResults;
		this.showDropDown = updatedSearch.dropdown;
	}

	removeItem(chip, i) {
		this.multiItems = this.removeMultiItems({ chips: chip.childItems, items: this.multiItems });
		this.multiChips.splice(i, 1);
		this.changeValues();
		setTimeout(() => {
			this.inputHeight = this.setHeight({
				clientHeight: this.multis.nativeElement.clientHeight,
				hideInput: this.hideInput,
			});
		});
		if (!this.multiChips.length) {
			this.hideInput = false;
		}
	}

	inputClick() {
		if (!this.hideInput) {
			this.setFocus(true);
		}
	}

	setFocus(bool) {
		this.isFocused = bool;
		if (!this.isFocused) {
			if (this.multiChips.length) {
				this.hideInput = true;
				this.childControl.setValue('');
			}
		}

		setTimeout(() => {
			this.showDropDown = bool;
			if (bool) {
				this.search();
			}
			this.inputHeight = this.setHeight({
				clientHeight: this.multis.nativeElement.clientHeight,
				hideInput: this.hideInput,
			});
			if (this.parentControl) {
				this.parentControl.updateValueAndValidity();
			}
			this.childControl.updateValueAndValidity();
		}, 200);
	}

	onAdd() {
		this.hideInput = false;
		this.setFocus(true);
		this.inputHeight = this.setHeight({
			clientHeight: this.multis.nativeElement.clientHeight,
			hideInput: this.hideInput,
		});
		setTimeout(() => this.input.nativeElement.focus());
	}

	closeInput() {
		this.hideInput = true;
		this.inputHeight = this.setHeight({
			clientHeight: this.multis.nativeElement.clientHeight,
			hideInput: this.hideInput,
		});
	}

	changeValues() {
		this.chipsChange.emit(this.multiItems.concat(this.multiChips));
		this.itemChosenChange.emit(this.multiItems);
		this.childControl.setValue('');
	}

	//Form Control Interface Implementation
	initForm() {
		if (this.controlDir) {
			const validators =
				this.controlDir.validator || this.parentControl.validator
					? [this.required(), this.invalidInput()]
					: [this.invalidInput()];
			if (this.parentControl) {
				this.parentControl.setValidators([
					...validators,
					this.controlDir.validator || this.parentControl.validator || (() => null),
				]);
			}
			this.parentControl.updateValueAndValidity();
			this.childControl.setValidators(validators);
			this.childControl.updateValueAndValidity({ emitEvent: false });
			if (this.multiChips.length) {
				this.childControl.markAsTouched();
			}
		} else {
			this.childControl.setValidators(this.invalidInput());
		}
	}

	writeValue(value: any) {
		if (value) {
			this.lastValue = value;
			this.setUpChips(value.chips || value);
			setTimeout(() => {
				this.formChange(this.multiItems);
			});
			this.childControl.setValue('');
		} else {
			this.childControl.setValue('');
			this.childControl.markAsUntouched({ onlySelf: true });
			this.childControl.markAsPristine({ onlySelf: true });
		}
	}

	registerOnChange(formChange: (value: any) => void) {
		this.formChange = formChange;
		this.itemChosenChange.subscribe((value) =>
			this.formChange(
				this.partial
					? {
							items: value,
							chips: value.concat(this.multiChips),
					  }
					: value,
			),
		);
	}

	registerOnTouched(onTouched) {
		this.onTouched = onTouched;
	}
	setDisabledState(disabled: boolean) {
		this.childControl[disabled ? 'disable' : 'enable']();
	}
	public required() {
		return () => {
			if (!this.multiItems.length && !this.childControl.value) {
				return { required: true };
			}
		};
	}

	public invalidInput() {
		return () => {
			if ((this.itemList || []).length) {
				const isMatching = (this.itemList || []).some((item) => {
					return item[this.displayBy].toLowerCase().includes(this.childControl.value.toLowerCase());
				});
				return !isMatching || (!this.isFocused && this.childControl.value) ? { invalidInput: true } : null;
			}
			return this.childControl.value ? { invalidInput: true } : null;
		};
	}

	chipChange(arg: ChipChangeArguments) {
		let items = arg.items;
		let chips = arg.chips;

		//sets multiItems to include only those who aren't multiChips
		items = arg.value.filter((chip) => {
			if (chip.display) {
				//if item is multiChip then add it to chips
				chips.push(chip);
				return false;
			}
			return true;
		});

		//if chips where not in the string make a chip for each chosenItem passed in
		if (!chips.length) {
			chips = items.map((multiItem) => {
				return {
					display: multiItem[arg.display],
					childItems: [multiItem],
				};
			});
		}

		return { items: items, chips: chips };
	}

	handleChosenItem(args: ChipChangeArguments, chosenItem: ChosenItem, searchResult) {
		let items = args.items;
		const chips = args.chips;
		if (chosenItem.partial && isEqual(chosenItem.chosenItem, searchResult[0])) {
			const chipName = chosenItem.controlValue ? `"${chosenItem.controlValue}"` : `All`;
			chips.push({
				display: chipName,
				childItems: searchResult.slice(1),
			});
			items = items.concat(searchResult.slice(1));
		} else {
			chips.push({
				display: chosenItem.chosenItem[args.display],
				childItems: [chosenItem.chosenItem],
			});
			items.push(chosenItem.chosenItem);
		}
		return { items: items, chips: chips };
	}

	filterSearchResults(searchArgs: SearchArguments) {
		let searchResults = searchArgs.searchResults;
		//pull out items that match search text
		searchResults = searchArgs.items.filter((item) => {
			return (
				item[searchArgs.display] &&
				includes(item[searchArgs.display].toLowerCase(), searchArgs.controlValue.toLowerCase())
			);
		});

		//remove items that have already been selected
		pullAllWith(searchResults, searchArgs.multiItems, isEqual);

		//if partial add all item containing chip
		if (searchArgs.partial) {
			const partialText = searchArgs.controlValue ? `All containing "${searchArgs.controlValue}"` : 'All';
			searchResults.unshift({
				[searchArgs.display]: partialText,
			});
			searchArgs.dropdown = searchResults.length > 1;
		} else {
			searchArgs.dropdown = searchResults.length > 0;
		}

		return { searchResults: searchResults, dropdown: searchArgs.dropdown };
	}

	removeMultiItems(args: ChipChangeArguments) {
		const chips = args.chips;
		let items = args.items;
		items = items.filter((item) => {
			return !find(chips, item);
		});
		return items;
	}

	setHeight(heightArgs: InputHeightArguments) {
		return heightArgs.clientHeight - (heightArgs.hideInput ? 20 : 0);
	}
}
