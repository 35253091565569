import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MobxAngularModule } from 'mobx-angular';
import { MtcOverlayModule } from '@mtc/shared/overlay';
import { OmniTableComponent } from './components/omni-table/omni-table.component';
import { AutocompleteCellComponent } from './components/autocomplete-cell/autocomplete-cell.component';
import { ManageColumnsDialog } from './dialogs/manage-columns/manage-columns.dialog';
import { ImportDialog } from './dialogs/import/import.dialog';
import { PipesModule } from '@mtc/shared/pipes';
import { DateModule } from '@mtc/shared/date';
import { DialogModule } from '@mtc/shared/dialog';
import { AngularResizedEventModule } from 'angular-resize-event';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DragDropModule,
		RouterModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
		MobxAngularModule,
		MtcOverlayModule,
		PipesModule,
		DateModule,
		DialogModule,
		AngularResizedEventModule,
	],
	declarations: [OmniTableComponent, AutocompleteCellComponent, ImportDialog, ManageColumnsDialog],
	exports: [OmniTableComponent],
	entryComponents: [ImportDialog, ManageColumnsDialog],
})
export class OmniTableModule {}
