import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { KebabPipe } from './pipes/kebab.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { MtcDateTimePipe } from './pipes/mtc-date-time.pipe';
import { MtcDatePipe } from './pipes/mtc-date.pipe';
import { MtcTimePipe } from './pipes/mtc-time.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { VirtualScrollPipe } from './pipes/virtual-scroll.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		MomentPipe,
		SafeHtmlPipe,
		KebabPipe,
		AddressPipe,
		DynamicPipe,
		MtcDateTimePipe,
		MtcDatePipe,
		MtcTimePipe,
		YesNoPipe,
		PluralizePipe,
		VirtualScrollPipe,
	],
	exports: [
		MomentPipe,
		SafeHtmlPipe,
		KebabPipe,
		AddressPipe,
		DynamicPipe,
		MtcDateTimePipe,
		MtcDatePipe,
		MtcTimePipe,
		YesNoPipe,
		PluralizePipe,
		VirtualScrollPipe,
	],
	providers: [
		MomentPipe,
		SafeHtmlPipe,
		KebabPipe,
		AddressPipe,
		DynamicPipe,
		MtcDateTimePipe,
		MtcDatePipe,
		MtcTimePipe,
		YesNoPipe,
		PluralizePipe,
	],
})
export class PipesModule {}
