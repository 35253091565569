<div class="pop-up" layout="column">
	<strong>API Versions:</strong>
	<mat-spinner *ngIf="loading; else loaded"></mat-spinner>
	<ng-template #loaded>
		<div class="url-container" *ngFor="let api of apiInformation">
			<div class="info">API: {{ api.title }}</div>
			<div>version: {{ api.version }}</div>
		</div>
		<button mat-button class="align-right" (click)="dialogRef.close()">close</button>
	</ng-template>
</div>
