import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MobxAngularModule } from 'mobx-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteModule } from '@mtc/shared/autocomplete';
import { DateModule } from '@mtc/shared/date';
import { DialogModule } from '@mtc/shared/dialog';
import { OmniTableModule } from '@mtc/shared/omni-table';
import { PipesModule } from '@mtc/shared/pipes';
import { TimePickerModule } from '@mtc/shared/time-picker';
import { ExpanderModule } from '@mtc/shared/expander';
import { AddMissionaryDialog } from './dialogs/add-missionary/add-missionary.dialog';
import { EditMissionaryAssignmentDialog } from './dialogs/edit-missionary-assignment/edit-missionary-assignment.dialog';
import { MissionaryDragCardComponent } from './components/missionary-drag-card/missionary-drag-card.component';
import { MissionaryPickerComponent } from './components/missionary-picker/missionary-picker.component';
import { MissionaryPickerApi } from './services/missionary-picker/missionary-picker.api';
import { PrintSummaryOnePageComponent } from './components/print-summary-one-page/print-summary-one-page.component';
import { PrintSummaryClinicalComponent } from './components/print-summary-clinical/print-summary-clinical.component';
import { TrainingInfoExpanderComponent } from './components/training-info-expander/training-info-expander.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MobxAngularModule,
		ReactiveFormsModule,
		RouterModule,
		MatCardModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		AutocompleteModule,
		DateModule,
		DialogModule,
		OmniTableModule,
		PipesModule,
		TimePickerModule,
		ExpanderModule,
	],
	declarations: [
		AddMissionaryDialog,
		EditMissionaryAssignmentDialog,
		MissionaryDragCardComponent,
		MissionaryPickerComponent,
		PrintSummaryOnePageComponent,
		PrintSummaryClinicalComponent,
		TrainingInfoExpanderComponent,
	],
	exports: [
		AddMissionaryDialog,
		EditMissionaryAssignmentDialog,
		MissionaryDragCardComponent,
		MissionaryPickerComponent,
		PrintSummaryOnePageComponent,
		PrintSummaryClinicalComponent,
		TrainingInfoExpanderComponent,
	],
	providers: [MissionaryPickerApi],
})
export class MissionaryModule {}
