import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';

@Pipe({
	name: 'clockHighlight',
})
export class ClockHighlightPipe implements PipeTransform {
	@memo((arg1, arg2, arg3) => arg1 + arg2 + arg3)
	transform(hoverAngle: number, type: 'hours' | 'minutes', tick: number): any {
		const step = type === 'hours' ? 5 : 1;
		const nearestStep = Math.round(hoverAngle / (step * 6)) * step;
		return nearestStep === tick;
	}
}
