<div class="favorites" [class.unset-overflow]="nav.moreIndex !== -1" #favoritesContainer *mobxAutorun (click)="closeMenu.emit()" (resized)='setFavorites()'>
	<ng-container *ngFor="let favLink of nav.favoritesThatFit">
		<ng-container *ngIf="!favLink.isMore">
			<div *ngIf="!favLink.isExternal" class="fav-link" [routerLink]="favLink.url" routerLinkActive="active-route" [class.active-route]="nav.showMoreLinks && favLink.isMore" #favorite>
				<div class="title">{{favLink.title}}</div>
			</div>
			<a *ngIf="favLink.isExternal" class="fav-link" [href]="favLink.url" target="_blank" #favorite>
				<div class="title">{{favLink.title}}</div>
			</a>
		</ng-container>
		<div class="fav-link" *ngIf="favLink.isMore" [class.active-route]="nav.showMoreLinks" (clickOutside)="nav.showMoreLinks = false">
			<div class="title more" (click)="nav.showMoreLinks = !nav.showMoreLinks">
				MORE <mat-icon>arrow_drop_down</mat-icon>
			</div>
			<mat-card class="dropdown" *ngIf="nav.showMoreLinks">
				<ng-container *ngFor="let overflowLink of favLink.links">
					<div *ngIf="!overflowLink.isExternal" class="dropdown-link" [routerLink]="overflowLink.url" routerLinkActive="active-route" (click)="nav.showMoreLinks = false">{{overflowLink.title}}</div>
					<a *ngIf="overflowLink.isExternal" class="dropdown-link" [href]="overflowLink.url" target="_blank" (click)="closeMoreLinks()">{{overflowLink.title}}</a>
				</ng-container>
			</mat-card>
		</div>
	</ng-container>
</div>
