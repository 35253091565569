import { Component, OnInit, Input, Output, EventEmitter, Self, Optional } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { get, has } from 'lodash';
import { env } from '@mtc/shared/urls';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
	private _time: any;

	/** The time that is currently selected in the time picker */
	@Input()
	set time(time: any) {
		if (time) {
			this._time = moment(time);
			let amPm;
			let noonAdjusted;
			if (this._time.hours() < 12) {
				amPm = 'am';
				noonAdjusted = this._time.hours() || 12;
			} else {
				amPm = 'pm';
				noonAdjusted = this._time.hours() - 12 || 12;
			}
			const hoursLeadingZeros = noonAdjusted < 10 ? '0' : '';
			const minutesLeadingZeros = this._time.minutes() < 10 ? '0' : '';
			const fullTime =
				hoursLeadingZeros + noonAdjusted + ':' + minutesLeadingZeros + this._time.minutes() + ' ' + amPm;
			this.inputTime = fullTime;
			this.childControl.setValue(fullTime);
		} else {
			this.childControl.setValue('');
		}
	}
	get time() {
		return this._time;
	}

	get parentControl() {
		return get(this.controlDir, 'control');
	}

	@Output() timeChange = new EventEmitter();

	/** Text that is displayed in the time picker before a time is selected */
	@Input() placeholder: any;
	/** Whether or not to automatically switch to minutes after an hour is selected */
	@Input() autoSwitch = true;
	/** Whether or not to put a required validator on the field */
	@Input() required = false;
	@OnChange(function() {
		this.childControl.updateValueAndValidity();
	})
	/** Timezone for the time picker */
	@Input()
	timezone;
	/** Validates user input to ensure the selected time is after the given time */
	@Input()
	@OnChange(function() {
		this.childControl.updateValueAndValidity();
	})
	isAfter;
	/** Validates user input to ensure the selected time is before the given time */
	@Input()
	@OnChange(function() {
		this.childControl.updateValueAndValidity();
	})
	isBefore;
	/** Disables the input of the time-picker */
	@Input()
	@OnChange(function(val) {
		if (this.childControl) {
			if (val) this.childControl.disable({ onlySelf: true });
			else {
				this.childControl.enable({ onlySelf: true });
				this.childControl.updateValueAndValidity();
			}
		}
	})
	isDisabled = false;

	public inputTime;
	public showClock = false;
	public dropUp = false;
	public childControl = new FormControl();
	public formChange: (value: any) => void;
	validTimeExpression = /([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(\s{1})?(am|pm)?/;

	constructor(
		@Self()
		@Optional()
		public controlDir: NgControl,
	) {
		if (controlDir) {
			this.controlDir.valueAccessor = this;
		}
	}

	ngOnInit() {
		let validator: any = () => {};
		if (this.controlDir) {
			validator = this.parentControl.validator || this.controlDir.validator || validator;
			this.required = has(validator(''), 'required');
			this.parentControl.setValidators([validator, this.validate()]);
			this.parentControl.updateValueAndValidity();
		}
		this.childControl.setValidators([validator.bind(this.parentControl), this.validate()]);
		if (this.isDisabled) this.childControl.disable({ onlySelf: true });
		this.childControl.updateValueAndValidity();

		this.childControl.valueChanges.pipe(debounceTime(1000)).subscribe((newTime) => {
			if (this.validTimeExpression.test(newTime) && newTime !== this.inputTime) {
				const times = newTime.split(':');
				const minutes = times[1].substring(0, 2);
				const amPm = times[1]
					.substring(2)
					.toLowerCase()
					.includes('pm')
					? 12
					: 0;
				const hours =
					Number(times[0]) > 12 ? Number(times[0]) : times[0] === '12' ? amPm : Number(times[0]) + amPm;
				this._time = moment(this._time);
				this._time.hours(hours);
				this._time.minutes(minutes);
				this.setTime(this._time);
			}
			this.parentControl?.updateValueAndValidity();
		});
	}

	populateField() {
		if (!this.isDisabled) {
			if (!this._time) {
				this.time = new Date();
			}
			this.setTime(this._time);
		}
	}

	setTime(time: any) {
		if (this.formChange) {
			this.formChange(time);
		}
		this.time = moment(time);
		this.timeChange.emit(this.time);
	}

	toggleClock() {
		if (env === 'cypress' || this.isDisabled) this.showClock = false;
		else this.showClock = !this.showClock;
	}

	writeValue(value: any) {
		this.time = value;
	}

	registerOnChange(formChange: (value: any) => void) {
		this.formChange = formChange;
	}

	registerOnTouched() {}

	public validate() {
		return () => {
			if (!this.childControl.value && this.required) {
				return { required: true };
			}
			if (
				this.childControl.value &&
				!this.validTimeExpression.test(this.childControl.value) &&
				!moment.isMoment(this.childControl.value)
			) {
				return { invalidInput: true };
			}

			if (this.time >= this.isBefore) {
				return { isAfter: true };
			}

			if (this.time <= this.isAfter) {
				return { isBefore: true };
			} else return null;
		};
	}
}
