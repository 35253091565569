import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	AfterContentChecked,
} from '@angular/core';
import { pullAllBy, clamp, isEmpty, findIndex, find } from 'lodash';
import { Router } from '@angular/router';
import { observable, computed, action } from 'mobx';
import { OnChange } from 'property-watch-decorator';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Title } from '@angular/platform-browser';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'mtc-dynamic-tabs',
	templateUrl: './dynamic-tabs.component.html',
	styleUrls: ['./dynamic-tabs.component.scss'],
	animations: [
		trigger('step', [
			state(
				'left',
				style({
					transform: 'translateX(-100%)',
				}),
			),
			state('visible', style({})),
			state(
				'right',
				style({
					transform: 'translateX(100%)',
				}),
			),
			state(
				'left-hidden',
				style({
					transform: 'translateX(-100%)',
					display: 'none',
				}),
			),
			state(
				'right-hidden',
				style({
					transform: 'translateX(100%)',
					display: 'none',
				}),
			),
			transition('void <=> *', []),
			transition('* <=> *', [animate('1.3s ease-in-out')]),
		]),
	],
})
export class DynamicTabsComponent implements OnInit, AfterContentChecked {
	@ViewChild('container', { static: false }) tabsContainer: ElementRef;
	/** The list of objects that create the tabs */
	@observable @Input() tabs;
	/** If the page is still loading. If true, the loading bar will display */
	@Input() loading = false;
	/** The name of the attribute, on the objects in the tabs input, that contains the text to display on the tab. */
	@Input() titleAttr;
	/** The name of the attribute, on the objects in the tabs input, that is the unique identifier for the tab*/
	@Input() uniqueId;
	/** The tab that is currently selected */
	@OnChange(function() {
		const tab = !isEmpty(this.passedCurrentTab) && find(this.tabs, this.passedCurrentTab || false);
		if (tab) {
			this.setActiveTab(tab);
		}
	})
	@Input('currentTab')
	passedCurrentTab;
	/** The name of the material icon you want to display as a button to the far left of the tabs */
	@Input() navigationIcon: 'magnify' | 'history';
	/** The text you want to display as a button with or instead of the navigationIcon. It is displayed to the far
	 * left of the tabs, but to the right of the navigationIcon.
	 */
	@Input() navigationText: string;
	/** The route to which the user will navigate if the navigationIcon or navigationText is clicked */
	@Input() homeLink: string;

	/** Event triggered when a tab is clicked. Output is the tab clicked */
	@Output() currentTabChange: EventEmitter<any> = new EventEmitter<any>();
	/** Event triggered when a tab is removed. Output the updated list of tabs */
	@Output() tabsChange: EventEmitter<any> = new EventEmitter<any>();
	/** Event triggered when the x on a tab is clicked. Output is the tab closed */
	@Output() tabRemoved: EventEmitter<number> = new EventEmitter<number>();
	/** Event triggered when the close all button is clicked */
	@Output() allRemoved: EventEmitter<any> = new EventEmitter<any>();

	@observable tabGroup = 0;
	@observable containerWidth = 0;
	@observable currentTab;
	@observable stepping = false;
	minTabLength = 132;
	maxTabLength = 240;

	constructor(private router: Router, private title: Title) {}

	ngOnInit() {}

	ngAfterContentChecked(): void {
		this.setContainerWidth();
	}

	@computed get visibleTabs() {
		return this.groupedTabs[this.tabGroup]?.tabs || [];
	}

	@computed get groupedTabs() {
		const tabGroups = [];
		let group = -1;
		if (this.maxTabs) {
			this.tabs.forEach((tab, i) => {
				if (i % this.maxTabs === 0) {
					tabGroups[++group] = { group, tabs: [tab] };
				} else {
					tabGroups[group].tabs.push(tab);
				}
			});
		}
		return tabGroups;
	}

	@computed get maxTabs() {
		return Math.floor(this.containerWidth / (this.minTabLength + 10)); // This extra 10 is to account for some padding that is added per tab
	}

	@computed get tabWidth() {
		return clamp(this.containerWidth / this.visibleTabs.length, this.minTabLength, this.maxTabLength);
	}

	@computed get showStepper() {
		return this.canScrollLeft || this.canScrollRight;
	}

	@computed get canScrollLeft() {
		return this.tabGroup > 0;
	}

	@computed get canScrollRight() {
		return this.tabGroup < Object.keys(this.groupedTabs).length - 1;
	}

	@computed get navActive() {
		return !this.currentTab || isEmpty(this.currentTab);
	}

	@action setContainerWidth() {
		this.containerWidth = this.tabsContainer ? this.tabsContainer.nativeElement.getBoundingClientRect().width : 0;
	}

	@action
	setActiveTab(tab) {
		this.tabs.forEach((t) => delete t.isActiveTab);
		tab.isActiveTab = true;
		this.currentTab = tab;
		this.currentTabChange.emit(this.currentTab);
		this.tabsChange.emit(this.tabs);
		this.scrollToTab(tab);
		this.title.setTitle(tab[this.titleAttr] + ' - MTC Tools');
	}

	@action scrollToTab(tab) {
		const index = findIndex(this.tabs, tab);
		if (index > -1) {
			let inGroup = Math.floor(index / this.maxTabs);
			if (isNaN(inGroup) || inGroup === Infinity) {
				inGroup = 0;
			}
			while (inGroup !== this.tabGroup) {
				if (inGroup < this.tabGroup) {
					this.step('left');
				} else {
					this.step('right');
				}
			}
		}
	}

	@action
	removeTab(tab) {
		pullAllBy(this.tabs, [tab], this.uniqueId || this.titleAttr);
		this.tabRemoved.emit(tab);
		this.tabsChange.emit(this.tabs);
		if (!this.visibleTabs.length) {
			this.step('left');
		}
		if (tab.isActiveTab) {
			if (this.tabs.length) {
				this.setActiveTab(this.tabs[0]);
			} else {
				this.navButtonClick();
			}
		}
	}

	@action
	removeAllTabs() {
		this.tabs = [];
		this.allRemoved.emit(this.tabs);
		this.tabsChange.emit(this.tabs);
		this.navButtonClick();
	}

	@action step(direction) {
		if (direction === 'left' && this.canScrollLeft && !this.stepping) {
			this.stepping = true;
			this.tabGroup--;
		} else if (direction === 'right' && this.canScrollRight && !this.stepping) {
			this.stepping = true;
			this.tabGroup++;
		}
		setTimeout(() => (this.stepping = false), 1300);
	}

	@action
	navButtonClick() {
		this.tabs.forEach((t) => delete t.isActiveTab);
		this.currentTab = {};
		this.currentTabChange.emit(this.currentTab);
		this.tabGroup = 0;
		if (this.homeLink) {
			this.router.navigate([this.homeLink]);
		}
	}

	getState(group) {
		return group === this.tabGroup
			? 'visible'
			: group === this.tabGroup - 1
			? 'left'
			: group === this.tabGroup + 1
			? 'right'
			: group < this.tabGroup
			? 'left-hidden'
			: 'right-hidden';
	}
}
