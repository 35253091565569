import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationStoreService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
	selector: 'tools-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
	@Output() close: EventEmitter<any> = new EventEmitter<any>();

	constructor(public nav: NavigationStoreService, private router: Router) {}

	ngOnInit() {}

	handleLinkClick(link) {
		if (link.childrenCount) {
			link.showChildren = !link.showChildren;
		} else {
			this.close.emit();
		}
	}

	isMidRouteActive(midLink) {
		return midLink.children.some((link) => link.url === this.router.url.slice(1));
	}
}
