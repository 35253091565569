import { Observable, of } from 'rxjs';
import { AuthConfig } from './config.model';
import { Platform, WebPlatform, MobilePlatform, OAuth, Token } from './oauth';
import { first, filter } from 'rxjs/operators';
import { isNil } from 'lodash';

class MTCAuthService {
	private config: AuthConfig;
	private platform: Platform;
	private oauth: OAuth;
	private token = null;

	public configure(config: AuthConfig) {
		this.config = config;
		this.platform = this.config.isMobile ? new MobilePlatform(config) : new WebPlatform(config);
		this.oauth = new OAuth(this.config, this.platform);
		this.oauth.getAccessToken().subscribe((token) => (this.token = token));
	}

	public getToken(): any {
		return this.token;
	}

	public loaded(): Observable<Token> {
		return this.oauth.getAccessToken().pipe(
			filter((token) => !isNil(token)),
			first(),
		);
	}

	public refreshToken(): boolean {
		this.oauth.dispatch('checkExpired');
		return true;
	}

	public refreshTokenAndWait(): Observable<Token> {
		return this.oauth.getAccessToken();
	}

	public logout() {
		this.oauth.dispatch('logout');
	}
}

export const MTCAuth = new MTCAuthService();
