import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { flow, find, isNil } from 'lodash';
import { map, shareReplay } from 'rxjs/operators';
import { env, mtcToolsUrl } from '@mtc/shared/urls';
import { routes } from '../route-permissions';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	env = 'local';
	public _permissions: any[] = [];
	get permissions() {
		return this.env === 'cypress' ? (window as any).permissions : this._permissions;
	}
	set permissions(permissions) {
		if (this.env === 'cypress') {
			(window as any).permissions = permissions;
		} else {
			this._permissions = permissions;
		}
	}
	public areLoaded = false;

	private observable = this.http.get(`${mtcToolsUrl}/permissions`).pipe(
		map((permissions: any[]) => {
			this.permissions = permissions;
			this.areLoaded = true;
			return permissions;
		}),
		shareReplay(1),
	);

	constructor(private http: HttpClient) {
		this.env = env;
	}

	load() {
		return this.observable;
	}

	allowedToNavigate(path) {
		const cleanPath = path.replace(/\/\d+/g, '');
		for (let i = 0; i < routes.length; i++) {
			const route = routes[i];
			if (route.path === cleanPath) {
				if (route.permission === 'MSNY_NEW' && !this.has(route.permission)) {
					// TODO remove this if statement when old-missionary is phased out
					return this.has('MSNY');
				}
				return this.has(route.permission);
			}
		}
		return true;
	}

	has(permission) {
		if (!permission) {
			return true;
		}
		return !flow([find, isNil])(this.permissions, ['name', permission]);
	}
}
