import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isNil, isString } from 'lodash';

@Pipe({
	name: 'mtcTime',
})
export class MtcTimePipe implements PipeTransform {
	militaryTime = /([01]?[0-9]|2[0-3]):[0-5][0-9]/;

	constructor() {}

	transform(value: any): any {
		if (isNil(value)) {
			return value;
		} else if (this.militaryTime.test(value) && value.length === 5) {
			const seperatedTime = value.split(':');
			value = new Date().setHours(seperatedTime[0], seperatedTime[1]);
		} else if (isString(value)) {
			value = new Date(value);
		}

		return moment(value).format('h:mm A');
	}
}
