const host = window.location.host;
let env;

if (host.includes('stage')) {
	env = 'stage';
} else if (host.includes('support')) {
	env = 'support';
} else {
	env = 'prod';
}

export const ENVIRONMENT = env;
