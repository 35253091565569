import { Injectable } from '@angular/core';
import { ToastStore } from '@mtc/shared/toast';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { env, messagingUrl } from '@mtc/shared/urls';
import { EmailOptions } from '../models/email-options.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class EmailService {
	constructor(private toaster: ToastStore, private http: HttpClient) {}

	public sendEmail(options: EmailOptions) {
		if (env !== 'prod') {
			options.recipients = 'mtcqa@mtc.byu.edu';
		}
		options.attachments = options.attachments || [];
		return this.send(options);
	}

	private send(options: EmailOptions) {
		const formData = new FormData();
		options.attachments.forEach((f) => formData.append('attachments', f, f.name));
		if (options.from) {
			formData.append('from', options.from);
		}
		if (options.recipientType) {
			formData.append('recipientType', options.recipientType);
		}
		if (options.subject) {
			formData.append('subject', options.subject);
		}
		formData.append('text', options.text);
		formData.append('recipients', options.recipients);
		return this.http.post(messagingUrl + '/email', formData).pipe(
			catchError((err) => {
				console.error(err);
				this.toaster.failure(options.failMessage || `The email was not sent to ${options.recipients}.`);
				return EMPTY;
			}),
			tap(() => {
				this.toaster.success(options.successMessage || `The email was sent to ${options.recipients}.`);
			}),
		);
	}
}
