import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EnvironmentComponent } from './components/environment/environment.component';
import { ApiVersionsDialog } from './dialogs/api-versions/api-versions.dialog';

@NgModule({
	imports: [CommonModule, FormsModule, MatDialogModule, MatButtonModule, MatProgressSpinnerModule],
	declarations: [EnvironmentComponent, ApiVersionsDialog],
	exports: [EnvironmentComponent],
	entryComponents: [ApiVersionsDialog],
})
export class EnvironmentModule {}
