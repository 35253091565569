import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DateModule } from '@mtc/shared/date';
import { ConfirmationDialog } from './dialogs/confirmation/confirmation.dialog';
import { DialogTemplateComponent } from './components/dialog-template/dialog-template.component';
import { DialogService } from './services/dialog.service';
import { DialogTemplateHeightPipe } from './pipes/dialog-template-height.pipe';

@NgModule({
	imports: [CommonModule, MatDialogModule, MatIconModule, MatProgressBarModule, DateModule],
	declarations: [ConfirmationDialog, DialogTemplateComponent, DialogTemplateHeightPipe],
	exports: [ConfirmationDialog, DialogTemplateComponent, MatDialogModule, DialogTemplateHeightPipe],
	providers: [DialogService, DialogTemplateHeightPipe],
})
export class DialogModule {}
