<mtc-toast class="no-print"></mtc-toast>
<div class="app-container" *ngIf="loaded; else loading">
	<mtc-environment [includeVersion]="true" class="no-print" [apiUrls]="apiUrls"></mtc-environment>
	<tools-layout></tools-layout>
</div>
<ng-template #loading>
	<div class="loading-header logo-container">
		<img src="./assets/images/tools-logo.svg" class="tools-logo">
	</div>
	<div class="loading-body">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		<div class="loading-subheader"></div>
		<div class="loading-image" ></div>
		<div class="loading-text show-on-load" style="display:none">Loading</div>
	</div>
</ng-template>
