import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
	selector: 'mtc-autocomplete-dropdown',
	templateUrl: './autocomplete-dropdown.component.html',
	styleUrls: ['./autocomplete-dropdown.component.scss'],
})
export class AutocompleteDropdownComponent implements OnInit {
	@ViewChild('dropdown', { static: false }) private dropdown: ElementRef;
	@Input() items: Array<any>;
	@Input() displayBy: string;
	@Input() currentItemIndex = 0;
	@Output() currentItemIndexChange: EventEmitter<any> = new EventEmitter<any>();
	@Input()
	set keyPress(keySubject) {
		keySubject.subscribe((key) => {
			if (key === 'ArrowUp' && this.currentItemIndex > 0) {
				this.currentItemIndex--;
			} else if (key === 'ArrowDown' && this.currentItemIndex + 1 < this.items.length) {
				this.currentItemIndex++;
			} else if (key === 'Enter') {
				if (this.items && this.items.length !== 0 && this.currentItemIndex > -1) {
					this.currentItemIndexChange.emit(this.currentItemIndex);
				}
			}
			if (this.dropdown.nativeElement) {
				this.scrollToItemAt();
			}
		});
	}

	ngOnInit() {}

	scrollToItemAt() {
		const itemSize = 30;
		const rowsDisplayed = 5;
		if (itemSize * this.currentItemIndex > this.dropdown.nativeElement.scrollTop + itemSize * rowsDisplayed) {
			this.dropdown.nativeElement.scrollTop += itemSize;
		} else {
			this.dropdown.nativeElement.scrollTop -= itemSize;
		}
	}

	selectItem() {
		this.currentItemIndexChange.emit(this.currentItemIndex);
	}
}
