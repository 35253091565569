import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MtcOverlayModule } from '@mtc/shared/overlay';
import { PipesModule } from '@mtc/shared/pipes';
import { DirectivesModule } from '@mtc/shared/directives';

import { DateStepperComponent, DatePickerComponent, MiniCalendarComponent } from './components';

@NgModule({
	declarations: [DateStepperComponent, DatePickerComponent, MiniCalendarComponent],
	exports: [DateStepperComponent, DatePickerComponent, MiniCalendarComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatCardModule,
		MatIconModule,
		PipesModule,
		MtcOverlayModule,
		DirectivesModule,
	],
})
export class DateModule {}
