import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '@mtc/shared/auth';

@Component({
	selector: 'mtc-print-summary-clinical',
	templateUrl: './print-summary-clinical.component.html',
	styleUrls: ['./print-summary-clinical.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintSummaryClinicalComponent implements OnInit {
	@Input() missionary: any;

	constructor(public user: UserService) {}

	ngOnInit() {}
}
