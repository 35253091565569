<div class="missionary-picker">
	<form [formGroup]="form">
		<mat-form-field class="name-input">
			<input matInput placeholder="Search by Missionary Name or ID" formControlName="missionaryNameOrId" (keyup.enter)="getMissionaries($event)" (focus)="isMissionaryNameOrIdFocused = true" (blur)="isMissionaryNameOrIdFocused = false" cy-elm="missionary-picker-input">
			<mat-icon class="field-icon"  matSuffix [class.active]="isMissionaryNameOrIdFocused" *ngIf="!form.value.missionaryNameOrId">search</mat-icon>
			<mat-icon class="field-icon clickable"  matSuffix [class.active]="isMissionaryNameOrIdFocused" *ngIf="form.value.missionaryNameOrId" (click)="form.get('missionaryNameOrId').setValue('')">clear</mat-icon>
		</mat-form-field>
		<mat-icon *ngIf="showModal" class="arrow-up">arrow_drop_up</mat-icon>
	</form>

	<div class="pop-up" [class.hidden]="!showModal" (clickOutside)="showModal = false">
		<mat-card class="checkbox-card">
			<ng-container *ngIf="showCheckboxes">
				<mat-checkbox [(ngModel)]="preMTC" (change)="updateOptions('preMTC', $event)"  cy-elm="missionary-picker-pre-mtc-checkbox">Pre-MTC </mat-checkbox>
				<mat-checkbox [(ngModel)]="inResidence" (change)="updateOptions('inResidence', $event)"  cy-elm="missionary-picker-in-residence-checkbox">In Residence</mat-checkbox>
			</ng-container>
			<mat-icon class="clickable clear-icon" (click)="showModal = false" cy-elm="close-missionary-picker">clear</mat-icon>
			<mtc-omni-table [columns]="columns" *ngIf="showModal" [rows]="displayMissionaries" [config]="omniTableConfig" cy-elm="missionary-picker-table"></mtc-omni-table>
		</mat-card>
	</div>
</div>
