<div *mobxAutorun>
	<div class="mat-progress-bar-container" *ngIf="loading">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
	<div class="tab-bar">
		<div class="icon-container" (click)="navButtonClick()" [class.active]="navActive">
			<mat-icon *ngIf="navigationIcon" class="icon" [svgIcon]="navigationIcon"></mat-icon>
			<span *ngIf="navigationText" class="text">{{navigationText}}</span>
		</div>
		<mat-icon *ngIf="showStepper" class="stepper left" [class.scrollable]="canScrollLeft" (click)="step('left')" cy-elm="scroll-tabs-left">chevron_left</mat-icon>
		<div class="tabs-container" #container>
			<div *ngFor="let group of groupedTabs" class="tab-group" [@step]="getState(group.group)">
				<div *ngFor="let tab of group.tabs" class="tab" [style.width.px]="tabWidth" [class.active]="tab.isActiveTab" (click)="setActiveTab(tab)">
					<div class="title" [attr.cy-elm]="tab[titleAttr] + ' tab' | kebab">{{tab[titleAttr]}}</div>
					<mat-icon class="remove" (click)="removeTab(tab)">clear</mat-icon>
				</div>
			</div>
		</div>
		<mat-icon *ngIf="showStepper" class="stepper right" [class.scrollable]="canScrollRight" (click)="step('right')" cy-elm="scroll-tabs-right">chevron_right</mat-icon>
		<div *ngIf="tabs.length" class="close-all" (click)="removeAllTabs()" cy-elm="tabs-close-all">Close All</div>
	</div>
</div>
