import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MtcOverlayModule } from '@mtc/shared/overlay';
import { DirectivesModule } from '@mtc/shared/directives';
import { PipesModule } from '@mtc/shared/pipes';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { MultiAutocompleteComponent } from './components/multi-autocomplete/multi-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	imports: [
		CommonModule,
		MtcOverlayModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule,
		MatCardModule,
		PipesModule,
		DirectivesModule,
		MatIconModule,
	],
	declarations: [AutocompleteComponent, AutocompleteDropdownComponent, MultiAutocompleteComponent],
	exports: [AutocompleteComponent, AutocompleteDropdownComponent, MultiAutocompleteComponent],
})
export class AutocompleteModule {}
