import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationStoreService } from '../services/navigation.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'tools-landing',
	template: '<tools-page [loading]="true"><tools-page>',
	styles: [],
})
export class LandingComponent implements OnInit {
	constructor(private router: Router, private nav: NavigationStoreService) {
		if (this.nav.areLoaded) {
			this.navigateFirst();
		} else {
			const subject = new Subject<boolean>();
			this.nav.load().subscribe(() => {
				subject.next(this.navigateFirst());
			});
		}
	}

	ngOnInit() {}

	navigateFirst() {
		this.router.navigate([this.nav.firstLink]);
		return true;
	}
}
