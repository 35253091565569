<mtc-expander *ngIf="trainingInfo?.teachers || trainingInfo?.trainingSupervisor || trainingInfo?.trainingManager" cy-elm="training-expander" [title]="title">
	<div class="info-group-container">
		<div class="info-column">
			<div class="info-group">
				<div class="header">Teachers</div>
				<ng-container *ngFor="let teacher of trainingInfo.teachers; let index = index">
					<div class="info teacher-name" [class.first]="index === 0">
						<div class="short attr">Name</div>
						<div [attr.cy-elm]="'teacher-' + index" class="value">{{teacher.fullName}}</div>
					</div>
					<div class="info">
						<div class="short attr">Mobile Phone</div>
						<div [attr.cy-elm]="'teacher-' + index + '-mobile-phone'" class="value">{{teacher.cellPhone}}</div>
					</div>
					<div class="info">
						<div class="short attr">Email</div>
						<a [attr.cy-elm]="'teacher-' + index + '-email'" href="mailto:{{teacher.email}}" class="value">{{teacher.email}}</a>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="info-column">
			<div class="info-group" *ngIf="trainingInfo.trainingSupervisor as supervisor">
				<div class="header">Supervisor</div>
				<div class="info">
					<div class="attr">Name</div>
					<div cy-elm="supervisor" class="value">{{supervisor.fullName}}</div>
				</div>
				<div class="info">
					<div class="attr">Office Location</div>
					<div cy-elm="supervisor-office" class="value">{{supervisor.officeLocation}}</div>
				</div>
				<div class="info">
					<div class="attr">Work Phone</div>
					<div cy-elm="supervisor-work-phone" class="value">{{supervisor.workPhone}}</div>
				</div>
				<div class="info">
					<div class="attr">Mobile Phone</div>
					<div cy-elm="supervisor-mobile-phone" class="value">{{supervisor.cellPhone}}</div>
				</div>
				<div class="info">
					<div class="attr">Email</div>
					<a cy-elm="supervisor-email" href="mailto:{{supervisor.email}}" class="value">{{supervisor.email}}</a>
				</div>
			</div>
		</div>
		<div class="info-column">
			<div class="info-group" *ngIf="trainingInfo.trainingManager as manager">
				<div class="header">Manager</div>
				<div class="info">
					<div class="attr">Name</div>
					<div cy-elm="manager" class="value">{{manager.fullName}}</div>
				</div>
				<div class="info">
					<div class="attr">Office Location</div>
					<div cy-elm="manager-office" class="value">{{manager.officeLocation}}</div>
				</div>
				<div class="info">
					<div class="attr">Work Phone</div>
					<div cy-elm="manager-work-phone" class="value">{{manager.workPhone}}</div>
				</div>
				<div class="info">
					<div class="attr">Mobile Phone</div>
					<div cy-elm="manager-mobile-phone" class="value">{{manager.cellPhone}}</div>
				</div>
				<div class="info">
					<div class="attr">Email</div>
					<a cy-elm="manager-email" href="mailto:{{manager.email}}" class="value">{{manager.email}}</a>
				</div>
			</div>
		</div>
	</div>
</mtc-expander>
