import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionService } from '@mtc/shared/permissions';
import { ExpanderConfig } from '../../models/expander-config.model';

@Component({
	selector: 'mtc-expander',
	templateUrl: './expander.component.html',
	styleUrls: ['./expander.component.scss'],
})
export class ExpanderComponent implements OnInit {
	@Input() config: ExpanderConfig = {};
	@Input() data: any;
	@Input() title: any;
	@Output() tabChange = new EventEmitter();
	showButton = false;

	constructor(public permissions: PermissionService) {}

	ngOnInit() {
		this.showButton = this.config.button && this.permissions.has(this.config.button.permission);
	}

	handleButtonClick(event) {
		event.stopPropagation();
		this.config.button.function(this.title);
	}
}
