<mtc-dialog-template [config]="dialogTemplateConfig">
	<div class="columns">
		<div class="header">COLUMNS</div>
		<div class="options" cdkDropList (cdkDropListDropped)="onItemDrop($event)">
			<div *ngFor="let column of columns" cdkDrag class="option">
				<ng-container *ngIf="column.title">
					<mat-icon cdkDragHandle class="drag-indicator">drag_indicator</mat-icon>
					<span class="column-name">{{ column.title }}</span>
					<mat-icon class="column-setting" [class.active]="column.visible" (click)="change('visible',column)" [attr.cy-elm]="column.title + ' eye' | kebab">remove_red_eye</mat-icon>
				</ng-container>
			</div>
		</div>
		<mat-checkbox [(ngModel)]="saveAsDefault">Save As Default</mat-checkbox>
	</div>
</mtc-dialog-template>
