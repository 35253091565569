import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PreventAutocompleteDirective } from './directives/prevent-autocomplete.directive';
import { PreventFormSubmitDirective } from './directives/prevent-form-submit.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [ClickOutsideDirective, PreventAutocompleteDirective, PreventFormSubmitDirective],
	exports: [ClickOutsideDirective, PreventAutocompleteDirective, PreventFormSubmitDirective],
})
export class DirectivesModule {}
