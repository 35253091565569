import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationStoreService } from '../../services/navigation.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { ToastStore } from '@mtc/shared/toast';
import { PermissionService } from '@mtc/shared/permissions';
import { SearchStore } from '../../services/search.store';
import { env } from '@mtc/shared/urls';

@Component({
	selector: 'tools-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
	showAllMtcs = false;
	searchIsOpen = false;
	inTesting = env === 'cypress';

	@ViewChild('navmenu') navmenu: MatSidenav;
	@ViewChild('endmenu') endmenu: MatSidenav;
	@ViewChild('container') container: MatSidenavContainer;
	@ViewChild('searchInput')
	set searchInput(input) {
		if (input) input.nativeElement.focus();
	}

	constructor(
		public nav: NavigationStoreService,
		private toaster: ToastStore,
		private el: ElementRef,
		public searchStore: SearchStore,
		public permissions: PermissionService,
	) {}

	ngOnInit() {}

	open(type) {
		if (type === 'endmenu') {
			this.navmenu.close();
			this.endmenu.toggle();
		} else if (type === 'navmenu') {
			this.endmenu.close();
			this.navmenu.toggle();
		} else {
			this.endmenu.close();
			this.navmenu.close();
		}
		this.searchIsOpen = type === 'search';
		this.showAllMtcs = false;
		this.toaster.clearMessage();
	}

	search(event) {
		this.searchStore.search(event).subscribe(() => (this.searchIsOpen = false));
	}

	closeMenu() {
		this.endmenu.close();
		this.closeNavMenu();
	}

	closeNavMenu() {
		this.navmenu
			.close()
			.then(() => this.el.nativeElement.querySelector('.mat-drawer-inner-container').scrollTo(0, 0));
	}

	closeSearch() {
		this.searchIsOpen = false;
		this.searchStore.searchQuery = '';
	}

	runChangeDetection() {
		//this function is for testing purposes, do not remove!
	}
}
