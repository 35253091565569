import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'moment',
})
export class MomentPipe implements PipeTransform {
	constructor() {}

	transform(value: any, returnDate?: any): any {
		if (moment.isMoment(value)) {
			return value;
		}
		if (returnDate) {
			return moment(value).toDate();
		}
		return moment(value);
	}
}
