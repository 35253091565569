import { Pipe, PipeTransform } from '@angular/core';
import { kebabCase } from 'lodash';

@Pipe({
	name: 'kebab',
})
export class KebabPipe implements PipeTransform {
	constructor() {}

	transform(value: string): string {
		return kebabCase(value);
	}
}
