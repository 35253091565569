import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isNil } from 'lodash';

@Pipe({
	name: 'mtcDate',
})
export class MtcDatePipe implements PipeTransform {
	longDateFormat = 'DD MMM YYYY';
	noYearFormat = 'DD MMM';

	constructor() {}

	transform(date: any, endDate: any = null, includeYear: boolean = true): any {
		if (isNil(date)) {
			return '';
		}
		date = moment(new Date(date));
		if (endDate) {
			endDate = moment(new Date(endDate));
			const endingDisplay = moment(endDate).format(includeYear ? this.longDateFormat : this.noYearFormat);
			let startingDisplay;
			if (endDate.isSame(date, 'day')) {
				return endingDisplay;
			} else if (endDate.isSame(date, 'month')) {
				startingDisplay = date.format('DD');
			} else if (endDate.isSame(date, 'year')) {
				startingDisplay = date.format('DD MMM');
			} else {
				startingDisplay = moment(date).format(includeYear ? this.longDateFormat : this.noYearFormat);
			}
			return startingDisplay + ' - ' + endingDisplay;
		} else if (endDate === '') {
			return date.format(this.longDateFormat) + ' - ';
		} else {
			return date.format(this.longDateFormat);
		}
	}
}
