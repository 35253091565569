<div class="top-link" *ngFor="let topLink of nav.originalLinks">
	<div class="title">{{topLink.title}}</div>
	<div class="mid-links">
		<div *ngFor="let midLink of topLink.children" (click)="handleLinkClick(midLink)">
			<div class="mid-link" [attr.cy-elm]="'sidenav ' + midLink.title | kebab">
				<ng-container *ngIf="midLink.childrenCount; else noChildren">
					<div class="title" [class.active-route]="isMidRouteActive(midLink)">{{midLink.title}}</div>
					<mat-icon>expand_{{midLink.showChildren ? 'less' : 'more'}}</mat-icon>
				</ng-container>
				<ng-template #noChildren>
					<a *ngIf="midLink.isExternal" class="title" [href]="midLink.url" (click)="nav.postNavAction(midLink.url)" target="_blank">{{midLink.title}}</a>
					<div *ngIf="!midLink.isExternal" class="title" [routerLink]="midLink.url" routerLinkActive="active-route">{{midLink.title}}</div>
				</ng-template>
			</div>
			<div class="bottom-links" *ngIf="midLink.showChildren">
				<div class="bottom-link" [attr.cy-elm]="'sidenav ' + bottomLink.title | kebab" *ngFor="let bottomLink of midLink.children" (click)="close.emit()">
					<div class="title" routerLinkActive="active-route" [routerLink]="bottomLink.url">{{bottomLink.title}}</div>
				</div>
			</div>
		</div>
	</div>
</div>
