import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PermissionService } from '@mtc/shared/permissions';
import { isBoolean, isString } from 'lodash';
import { computed, observable } from 'mobx-angular';

@Component({
	selector: 'mtc-static-tabs',
	templateUrl: './static-tabs.component.html',
	styleUrls: ['./static-tabs.component.scss'],
})
export class StaticTabsComponent implements OnInit {
	/** Event emitted when the currently selected tab changes. Allows for double binding ex: [(currentTab)] */
	@Output() currentTabChange = new EventEmitter();

	/** The currently selected tab's label */
	@Input() currentTab;
	/** The style of the navigation tabs. Either primary or secondary */
	@Input() type = 'primary';
	/** The different tabs to choose from. Can be a string or an object with the following format: {label: 'tab name', permission: 'PERMISSION_NAME'} */
	@observable @Input() tabs;

	constructor(public permissions: PermissionService, private ref: ChangeDetectorRef) {}

	ngOnInit() {}

	tabSelected(tab) {
		this.currentTab = tab.value || tab.label;
		if (isBoolean(tab.value)) {
			this.currentTab = tab.value;
		}
		this.currentTabChange.emit(this.currentTab);
		this.ref.detectChanges();
	}

	@computed
	get filteredTabs() {
		return (this.tabs || [])
			.map((tab) => {
				return isString(tab) ? { label: tab } : tab;
			})
			.filter((tab) => {
				if (!tab.permission || this.permissions.has(tab.permission)) {
					return tab;
				}
			});
	}
}
