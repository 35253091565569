import { Component, OnInit, Input, HostListener } from '@angular/core';
import { env } from '@mtc/shared/urls';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ApiVersionsDialog } from '../../dialogs/api-versions/api-versions.dialog';

@Component({
	selector: 'mtc-environment',
	templateUrl: './environment.component.html',
	styleUrls: ['./environment.component.scss'],
})
export class EnvironmentComponent implements OnInit {
	@Input() includeVersion: boolean;
	@Input() apiUrls: string[] = [];

	env = 'dev';
	version = 'v0.0.0';
	includeInProd = false;

	@HostListener('window:keyup.control.shift.|')
	onKeyUp() {
		this.includeInProd = !this.includeInProd;
	}

	constructor(private http: HttpClient, private dialog: MatDialog) {}

	showVersion() {
		return this.env !== 'prod' || this.includeInProd;
	}

	ngOnInit() {
		this.env = env;
		if (this.includeVersion) {
			this.http.get('assets/version.json?v=' + Date.now()).subscribe(
				(v: any) => (this.version = v.version),
				() => {
					throw new Error(
						`You've decided to include the version # but the application is missing a version.json file`,
					);
				},
			);
		}
	}

	openEndpointVersions() {
		if (!this.dialog.openDialogs.length && this.apiUrls.length) {
			this.dialog
				.open(ApiVersionsDialog, {
					width: '300px',
					data: this.apiUrls,
				})
				.afterClosed();
		}
	}
}
