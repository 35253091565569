<!-- TODO this component should be redone using grid to clean up html and css -->
<mat-progress-bar mode="indeterminate" [class.hidden]="!dialogService?.dialogLoading"></mat-progress-bar>
<div class="dialog" [class.loading]="dialogService?.dialogLoading" (keyup.enter)="confirm()">
	<div class="dialog-header" [class.blue-header]="dialogConfig.advanced">
		<h2 mat-dialog-title cy-elm="dialog-title">{{dialogConfig.title}}</h2>
		<button class="icon-btn" (click)="close()">
			<mat-icon cy-elm="dialog-x">clear</mat-icon>
		</button>
	</div>
	<div class="loading-screen" *ngIf="dialogService?.dialogLoading">
		<div class="dialog-loading-image"></div>
		<div class="loading-text">Loading</div>
	</div>
	<div #dialog class="dialog-container" [class.hidden]="dialogService?.dialogLoading">
		<div class="dialog-items" mat-dialog-content>
			<div *ngIf="dialogConfig.items" class="item-columns-container" [ngStyle]="{'max-height': content | dialogTemplateHeight}">
				<div *ngFor="let column of itemNames; let i = index" class="item-column">
					<div class="column-header" *ngIf="column !== 'noTitle'">{{column}}</div>
					<div *ngFor="let item of dialogConfig.items[column]" class="item">{{item}}</div>
				</div>
			</div>
			<div class="content" [class.has-display-items]="dialogConfig.items" #content>
				<ng-content></ng-content>
				<div *ngIf="usingDatePicker" class="small-date-picker">
					<mtc-date-picker [(date)]="dialogConfig.effectiveDate" (dateChange)="effectiveDateChange($event)" placeholder="Effective Date" [isAfter]="dialogConfig.isAfter || 'today'" [isBefore]="dialogConfig.isBefore || ''"></mtc-date-picker>
				</div>
			</div>
		</div>
		<div mat-dialog-actions class="dialog-buttons-container" [class.extra-padding]="dialogConfig.extraPadding">
			<div class="dialog-actions">
				<div class="dialog-buttons-center" *ngIf="dialogConfig.isSimpleConfirmation; else rightAlign">
					<button class="secondary-btn" *ngIf="dialogConfig.cancelText" (click)="cancel()" cy-elm="secondary-btn">{{cancelText}}</button>
					<button class="primary-btn" [attr.form]="dialogConfig.formId" *ngIf="dialogConfig.confirmText" type="submit" (click)="confirm()" cy-elm="primary-btn">{{confirmText}}</button>
				</div>
				<ng-template #rightAlign>
					<div class="dialog-buttons-start" *ngIf="dialogConfig.showDelete">
						<button class="flat-btn delete-btn" (click)="dialogConfig.deleteClick()">
								<div class="btn-title">Delete</div>
								<mat-icon class="delete-icon" cy-elm="dialog-delete">delete</mat-icon>
						</button>
					</div>
					<div class="dialog-buttons-end">
						<button class="secondary-btn" *ngIf="cancelText" (click)="cancel()" cy-elm="dialog-cancel" >{{cancelText}}</button>
						<button class="primary-btn" *ngIf="confirmText" [attr.form]="dialogConfig.formId" type="submit" (click)="confirm()" cy-elm="dialog-confirm">{{confirmText}}</button>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
