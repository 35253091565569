<div class="time-picker" [class.enabled]="!isDisabled">
	<mat-form-field (mousedown)="populateField()" (click)="toggleClock()">
		<input #overlayHost matInput class="time-display" [required]="required" [placeholder]="placeholder" [formControl]="childControl" cy-elm="time-picker-input">
		<mat-icon [class.enabled]="!isDisabled" cy-elm="time-picker-icon" matSuffix>access_time</mat-icon>
		<mat-error *ngIf="childControl.errors?.required" cy-elm="time-picker-required">Required Field</mat-error>
		<mat-error *ngIf="childControl.errors?.invalidInput" cy-elm="time-picker-invalid">HH:MM am/pm</mat-error>
		<mat-error *ngIf="childControl.errors?.isBefore" cy-elm="time-picker-is-before">Time must be after start time</mat-error>
		<mat-error *ngIf="childControl.errors?.isAfter" cy-elm="time-picker-is-after">Time must be before end time</mat-error>
	</mat-form-field>
	<mtc-overlay [(toggleOverlay)]="showClock" [connectTo]="overlayHost" [offset]="3">
		<mat-card class="clock-card">
			<mtc-mini-clock [time]="time" (timeChange)="setTime($event)" [autoSwitch]="autoSwitch" [timezone]="timezone"></mtc-mini-clock>
		</mat-card>
	</mtc-overlay>
</div>
