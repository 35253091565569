import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MTCAuthPreset } from '@mtc/shared/auth';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
const { authDomain, authProvider, clientId, refreshDays, redirectUri } = environment;

if (environment.production) {
	enableProdMode();
}

MTCAuthPreset.configure(authDomain, authProvider, clientId, refreshDays, redirectUri, false);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
