import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import memo from 'memo-decorator';

@Pipe({
	name: 'address',
})
export class AddressPipe implements PipeTransform {
	constructor() {}

	@memo()
	transform(address): any {
		if (isNil(address)) {
			return '';
		}

		return address
			.split(/\r\n|\r|\n/)
			.map((line) => `<span>${line}</span>`)
			.join('<br>');
	}
}
