import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkDirective } from './directives/router-link.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [RouterLinkDirective],
	exports: [RouterLinkDirective],
})
export class SharedPermissionModule {}
