/**
 * List of routes in the app and their corresponding permissions
 * Note that if the route has a param (i.e. :id) do not add it in the path
 * /missionary/:id/profile -> /missionary/profile
 */
export const routes = [
	// ActionLogModule
	{ path: 'developer/action-log', permission: 'DEVELOPER' },
	// PermissionsModule
	{ path: 'developer/permissions/edit', permission: 'MNG_PERMISSIONS' },
	{ path: 'developer/permissions/roles', permission: 'MNG_ROLE_PERMISSIONS' },
	{ path: 'developer/permissions/users', permission: 'USER_PERMISSIONS' },
	// ServerLogModule
	{ path: 'developer/server-logs', permission: 'DEVELOPER' },
	// SharedExamplesModule
	{ path: 'developer/shared/autocomplete', permission: 'DEVELOPER' },
	{ path: 'developer/shared/calendar', permission: 'DEVELOPER' },
	{ path: 'developer/shared/color-picker', permission: 'DEVELOPER' },
	{ path: 'developer/shared/date-picker', permission: 'DEVELOPER' },
	{ path: 'developer/shared/dynamic-tabs', permission: 'DEVELOPER' },
	{ path: 'developer/shared/missionary-picker', permission: 'DEVELOPER' },
	{ path: 'developer/shared/omni-table', permission: 'DEVELOPER' },
	{ path: 'developer/shared/quill', permission: 'DEVELOPER' },
	{ path: 'developer/shared/static-tabs', permission: 'DEVELOPER' },
	{ path: 'developer/shared/time-picker', permission: 'DEVELOPER' },
	{ path: 'developer/shared/options', permission: 'DEVELOPER' },
	// SiteBannerModule
	{ path: 'developer/site-banner', permission: 'SITE_BANNER' },
	// BranchesModule
	{ path: 'ecclesiastical/branches/search', permission: 'BRANCH' },
	{ path: 'ecclesiastical/branches/view', permission: 'BRANCH' },
	// MissionaryReportsModule
	{ path: 'ecclesiastical/missionary-reports/weekly', permission: 'WKLY_RPT_MSNY' },
	{ path: 'ecclesiastical/missionary-reports/thread', permission: null },
	{ path: 'ecclesiastical/missionary-reports/branch', permission: 'WKLY_RPT_BRANCH' },
	{ path: 'ecclesiastical/missionary-reports/teacher', permission: 'WKLY_RPT_TRNG' },
	{ path: 'ecclesiastical/missionary-reports/report', permission: 'WKLY_RPT_BRANCH' },
	// MissionsModule
	{ path: 'ecclesiastical/missions/view', permission: 'MISSION' },
	{ path: 'ecclesiastical/missions/search', permission: 'MISSION' },
	// MailboxesModule
	{ path: 'facilities/mailboxes', permission: 'MAILBOX' },
	// RoomsModule
	{ path: 'facilities/rooms/view', permission: 'ROOM' },
	{ path: 'facilities/rooms/search', permission: 'ROOM' },
	// ServiceAssignmentsModule
	{ path: 'facilities/service-assignments', permission: 'SERVICE_ASMT' },
	// DashboardModule
	{ path: 'home/dashboard', permission: 'DASHBOARD' },
	{ path: 'home/dashboard/no-itinerary', permission: 'DASHBOARD_TRVL' },
	{ path: 'home/dashboard/past-departure-date/young', permission: 'DASHBOARD_TRVL' },
	{ path: 'home/dashboard/past-departure-date/senior', permission: 'DASHBOARD_SCHD' },
	{ path: 'home/dashboard/nonmatching-itinerary', permission: 'DASHBOARD_TRVL' },
	{ path: 'home/dashboard/missing-packets', permission: 'DASHBOARD_TRVL' },
	{ path: 'home/dashboard/missionary-leaders', permission: 'DASHBOARD_MSNY' },
	{ path: 'home/dashboard/companionless/detail', permission: 'DASHBOARD_SCHD' },
	// AppointmentsModule
	{ path: 'missionary/appointments', permission: 'APPOINTMENT' },
	// MissionaryAssignmentsModule
	{ path: 'missionary/arrivals/missionary-assignments', permission: 'MSNY_SCHD_ASSIGNMENTS' },
	// ArrivalTimesModule
	{ path: 'missionary/arrivals/times', permission: null },
	// AssistanceModule
	{ path: 'missionary/assistance/items', permission: 'MSNY_ASST_ITEM' },
	{ path: 'missionary/assistance/missionaries', permission: 'MSNY_ASST' },
	// CheckInOutModule
	{ path: 'missionary/check-in-out', permission: 'MSNY_CHK_IN_OUT' },
	// CompanionshipsModule
	{ path: 'missionary/companionships', permission: 'MSNY_COMP' },
	// DriversVehiclesModule
	{ path: 'missionary/departures/drivers-vehicles', permission: 'SETTING_DRVR_VEH' },
	// ItinerariesModule
	{ path: 'missionary/departures/itineraries', permission: 'ITINERARY' },
	// ScheduleModule
	{ path: 'missionary/departures/departure-schedule', permission: 'DEPARTURE_SCHD' },
	// MobileDevicesModule
	{ path: 'missionary/devices', permission: 'MOBILE_DEVICE' },
	// DigitalSignageModule
	{ path: 'missionary/notifications/digital-signage', permission: 'MSG_DIGITAL_SIGN' },
	// EmailModule
	{ path: 'missionary/notifications/send-email', permission: 'MSG_EMAIL' },
	// MissionaryProfileModule
	{ path: 'missionary/profile/overview', permission: 'MSNY_NEW' },
	{ path: 'missionary/profile/search', permission: 'MSNY_NEW' },
	{ path: 'missionary/profile/print-summary-one-page', permission: 'MSNY_PRINT' },
	{ path: 'missionary/profile/print-summary-clinical', permission: 'MSNY_PRINT' },
	// RecordsModule
	{ path: 'missionary/records/request', permission: 'MSNY_DOWNLOAD' },
	{ path: 'missionary/records/new', permission: 'MSNY_CREATE' },
	// TrainingGroupsModule
	{ path: 'missionary/training-groups/view', permission: 'TRAINING_GRP' },
	{ path: 'missionary/training-groups/search', permission: 'TRAINING_GRP' },
	// TrainingSchedulesModule
	{ path: 'missionary/training-schedules/view', permission: 'TRAINING_SCHD' },
	{ path: 'missionary/training-schedules/search', permission: 'TRAINING_SCHD' },
	// ZonesModule
	{ path: 'missionary/zones', permission: 'ZONE' },
	// OldMissionaryModule
	{ path: 'old-missionary', permission: 'MSNY' },
	{ path: 'old-missionary/overview', permission: 'MSNY' },
	{ path: 'old-missionary/mtc', permission: 'MSNY' },
	{ path: 'old-missionary/mission', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/personal', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/ecclesiastical', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/training', permission: 'MSNY' },
	{ path: 'old-missionary/teacher-comments', permission: 'MSNY_TCHR_CMNT' },
	{ path: 'old-missionary/notes', permission: 'MSNY_NOTE' },
	{ path: 'old-missionary/web-history', permission: 'MSNY_WEB_HIST' },
	{ path: 'old-missionary/change-history', permission: 'MSNY_CHG' },
	{ path: 'old-missionary/tags', permission: 'MSNY_NOTE_TAG' },
	{ path: 'old-missionary/print-summary-one-page', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/print-summary-clinical', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/print-summary-clinical', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/assistance', permission: 'MSNY_ASST' },
	{ path: 'old-missionary/search', permission: 'MSNY' },
	{ path: 'old-missionary/print-summary-one-page', permission: 'MSNY_PRINT' },
	{ path: 'old-missionary/print-summary-clinical', permission: 'MSNY_PRINT' },
	// MinisterialCertificatesModule
	{ path: 'orders/ministerial-certificates', permission: 'MSNY_ORD' },
	// NametagSettingsModule
	{ path: 'orders/nametag-settings', permission: 'SETTING_NAMETAG' },
	// TranslationsModule
	{ path: 'orders/translations/assign', permission: 'TRANSLTN_ASSIGN' },
	{ path: 'orders/translations/default', permission: 'TRANSLTN_DEFAULT' },
	{ path: 'orders/translations/pending', permission: 'TRANSLTN_PENDING' },
	{ path: 'orders/translations/track', permission: 'TRANSLTN_TRACK' },
	// ChangesModule
	{ path: 'search-and-reports/changes', permission: 'MSNY_CHG' },
	// CustomSearchModule
	{ path: 'search-and-reports/custom-search', permission: 'MSNY_SEARCH' },
	// ReportsModule
	{ path: 'search-and-reports/reports', permission: 'REPORT' },
	// SavedSearchesModule
	{ path: 'search-and-reports/saved-searches', permission: 'MSNY_SEARCH' },
	// ActivitiesModule
	{ path: 'system-settings/activities', permission: 'ACTIVITY_EDIT' },
	// CompanionshipPrioritiesModule
	{ path: 'system-settings/companionship-priorities', permission: 'MSNY_CMP_PRIORITIES' },
	// CalendarModule
	{ path: 'staff/calendar', permission: null },
	// PayRaisesModule
	{ path: 'staff/pay-raises/evaluations', permission: 'PAY_EVAL' },
	{ path: 'staff/pay-raises/queue', permission: 'PAY_EVAL_HR' },
	// StaffProfileModule
	{ path: 'staff/employee/view', permission: 'PROFILE_EMP_INFO' },
	{ path: 'staff/employee/search', permission: 'PROFILE_EMP_INFO' },
	// SupervisorAssignmentsModule
	{ path: 'training/assignments/supervisor', permission: 'EMP_MGR_MGR' },
	// TeacherAssignmentsModule
	{ path: 'training/assignments/teacher', permission: 'EMP_MGR_TCHR_VIEW' },
];
