import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mtcToolsUrl } from '@mtc/shared/urls';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	configs: any[];

	constructor(private http: HttpClient) {}

	load() {
		return this.getConfigs().pipe(tap((configs: any[]) => (this.configs = configs)));
	}

	getConfigs() {
		return this.http.get(`${mtcToolsUrl}/userconfigs`);
	}

	setConfigs(configs) {
		this.configs = configs;
		return this.http.put(`${mtcToolsUrl}/userconfigs`, configs);
	}

	getConfig(configGroup, name) {
		return (
			this.configs.find((config) => {
				return config.configGroup === configGroup && config.name === name;
			}) || {}
		);
	}

	setConfig(configGroup, name, value) {
		const config = this.getConfig(configGroup, name);
		config.value = value;
		return this.http.put(`${mtcToolsUrl}/userconfig`, config, { responseType: 'text' });
	}

	getMtc() {
		return this.http.get(`${mtcToolsUrl}/userconfigs/mtc`);
	}

	setMtcId(mtcNum) {
		return this.http.put(`${mtcToolsUrl}/userconfig/mtcid/${mtcNum}`, null, {
			responseType: 'text',
		});
	}

	getLastViewedList(id: string) {
		return this.http.get(`${mtcToolsUrl}/vieweditem/${id}`);
	}

	setNewViewed(viewedObj) {
		return this.http.put(`${mtcToolsUrl}/vieweditem/`, viewedObj);
	}
}
