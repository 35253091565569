import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Output,
	EventEmitter,
	QueryList,
	ViewChildren,
	AfterViewInit,
} from '@angular/core';
import { NavigationStoreService } from '../../services/navigation.service';

@Component({
	selector: 'tools-favorites-bar',
	templateUrl: './favorites-bar.component.html',
	styleUrls: ['./favorites-bar.component.scss'],
})
export class FavoritesBarComponent implements OnInit, AfterViewInit {
	@ViewChild('favoritesContainer', { static: false }) favoritesContainer: ElementRef;
	@ViewChildren('favorite') favoritesElement: QueryList<ElementRef>;
	@Output() closeMenu: EventEmitter<any> = new EventEmitter<any>();

	constructor(public nav: NavigationStoreService) {}

	ngOnInit() {
		this.nav.favoriteChange.subscribe(() => this.setFavorites());
	}

	ngAfterViewInit() {
		//TODO find better initialization method
		let attempts = 5;
		const init = setInterval(() => {
			if (this.favoritesElement.length || attempts < 1) {
				this.setFavorites();
				clearInterval(init);
			}
			--attempts;
		}, 100);
	}

	setFavorites() {
		this.nav.moreIndex = -1;
		let currentSum = 100;
		setTimeout(() => {
			const barWidth = this.favoritesContainer.nativeElement.clientWidth;
			const widths = this.favoritesElement.map((el) => (currentSum += el.nativeElement.clientWidth));
			this.nav.moreIndex = widths.findIndex((width) => width > barWidth);
		});
	}

	closeMoreLinks() {
		setTimeout(() => (this.nav.showMoreLinks = false));
	}
}
