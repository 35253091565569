import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'clockNum',
})
export class ClockNumPipe implements PipeTransform {
	transform(type: 'hours' | 'minutes', tick: any): any {
		const n = Number(tick);
		if (type === 'hours') {
			return n % 5 ? null : n / 5;
		} else {
			return n % 5 ? ' ' : n === 60 ? 0 : n;
		}
	}
}
