import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { mtcToolsUrl, missionaryUrl } from '@mtc/shared/urls';
import { ToastStore } from '@mtc/shared/toast';
import { EmailService } from '@mtc/shared/messaging';

@Injectable({ providedIn: 'root' })
export class MissionarySearchService {
	criteria: any = {
		preMTC: false,
		scheduled: false,
		inResidence: true,
		departed: false,
		all: false,
		departedSince: '',
	};

	constructor(private http: HttpClient, private toaster: ToastStore, private emailService: EmailService) {}

	search(criteria = this.criteria, tableGroup = null) {
		let url = `${mtcToolsUrl}/customsearch`;
		if (tableGroup) {
			url += `?tableGroup=${tableGroup}`;
		}
		return this.http.post(url, criteria);
	}

	createChange(changeInfo, missionaryIds) {
		if (changeInfo[0]) {
			const change = {
				actionEmailGroup: 'missionarychanges@mtc.byu.edu',
				actionId: 11,
				actionName: 'Other',
				effectiveDate: moment().format('DD MMM YYYY'),
				fields: changeInfo,
				missionaryIds: missionaryIds,
			};
			this.editMissionariesFutureChanges(change).subscribe(() => {
				this.toaster.success('Change created.');
				this.sendMissionariesEmail(change, missionaryIds);
			});
		}
	}

	editMissionariesFutureChanges(change) {
		const tempAssignment = change.fields.find((f) => f.name === 'Temporary Mission');
		if (change.actionName === 'Temporary Assignment' && tempAssignment) {
			tempAssignment.valueName = tempAssignment.value;
			tempAssignment.value = 'Pending';
		}
		change.fields = change.fields.filter((field) => {
			if (field.value) {
				switch (field.fieldTypeName) {
					case 'date':
						field.value = new Date(field.value).getTime();
					case 'text':
						field.valueName = null;
				}
			}
			return field.value;
		});

		return this.http.post(`${mtcToolsUrl}/missionarychange`, change);
	}

	sendMissionariesEmail(change, missionaries) {
		missionaries.forEach((missionary) => {
			change.missionaryName = missionary.fullName;
			change.missionaryId = missionary.missionaryId;
			change.missionaryType = missionary.personalInfo
				? missionary.personalInfo.missionaryType
				: missionary.missionaryType;

			this.sendMissionaryEmail(change);
		});
	}

	sendMissionaryEmail(change) {
		const subject = 'Change Notification: ' + change.missionaryName + '  #' + change.missionaryId;
		const text = `<p>
					A change has been created or modified for the following missionary:
				</p>
				<p>
					Missionary:&nbsp;${change.missionaryName}<br>
					Missionary Type:&nbsp;${change.missionaryType}<br>
					Missionary ID: &nbsp; ${change.missionaryId} <br>
					Action:&nbsp;${change.actionName}
				</p>
				<p>
					Please visit <a href="https://apps.mtc.byu.edu/tools">MTC Tools</a> for more information.
				</p>
				<p>
					*This is an automated response. Please do not reply to this message.
				</p>`;
		this.emailService.sendEmail({ recipients: change.actionEmailGroup, text, subject });
	}

	getMissionaries(searchOptions, query) {
		const searchQueries = `preMTC=${searchOptions.preMTC || false}
							&scheduled=${searchOptions.scheduled || false}
							&inResidence=${searchOptions.inResidence || false}
							&departed=${searchOptions.departed || false}
							&all=${searchOptions.all || false}
							&allMtcs=${searchOptions.allMtcs || false}`;
		return this.http.get(`${mtcToolsUrl}/missionaries?${searchQueries}&query=${query}`);
	}

	getMissionarySearchHistory() {
		return this.http.get(`${missionaryUrl}/missionaries/history`);
	}

	getEmployees(query) {
		return this.http.get(`${mtcToolsUrl}/employeemanager/search?query=${query}`);
	}

	getEmployeeSearchHistory() {
		return this.http.get(`${mtcToolsUrl}/employeemanager/search/history`);
	}
}
