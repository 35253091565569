import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	Self,
	Optional,
	ChangeDetectorRef,
} from '@angular/core';
import { PermissionService } from '@mtc/shared/permissions';
import { MissionaryPickerApi } from '../../services/missionary-picker/missionary-picker.api';
import { DialogService } from '@mtc/shared/dialog';
import { ConfigService } from '@mtc/shared/info';
import { OmniTableConfig, OmniTableColumn } from '@mtc/shared/omni-table';
import { assign, isNil, cloneDeep } from 'lodash';
import { FormControl, FormGroup, NgControl, ControlValueAccessor } from '@angular/forms';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-missionary-picker',
	templateUrl: './missionary-picker.component.html',
	styleUrls: ['./missionary-picker.component.scss'],
	providers: [MissionaryPickerApi],
})
export class MissionaryPickerComponent implements OnInit, ControlValueAccessor {
	@ViewChild('input', { static: false }) input;
	/** Initially selected missionaries */
	@Input() selected = [];
	@Output() selectedChange: EventEmitter<any> = new EventEmitter();
	/** Whether or not to include pre-mtc missionaries. Unless explicitly defined, it is based off the configService's records for the current user */
	@Input() preMTC;
	/** Whether or not to include in residence missionaries. Unless explicitly defined, it is based off the configService's records for the current user */
	@Input() inResidence;
	/** Whether or not to include in field missionaries */
	@Input() inField = false;
	/** Whether or not to show the checkboxes that toggle pre-mtc and in residence missionaries */
	@Input() showCheckboxes = true;
	/** A list of missionaries that will not be returned in the search result */
	@Input() excludedMissionaries = [];
	/** Sets the disabled state of the inputs */
	@OnChange(function() {
		this.form[this.disabled ? 'disable' : 'enable']();
	})
	@Input()
	disabled;
	/** Restricts results to missionaries arriving within the week of the day indicated */
	@OnChange(function(date) {
		if (date) {
			assign(this.criteria, {
				scheduledArrivalStart: date.clone().startOf('week'),
				scheduledArrivalEnd: date.clone().endOf('week'),
			});
		}
	})
	@Input()
	groupDate;
	/** Emits when results are loading */
	@Output() loading = new EventEmitter();
	/** Emits the missionary that was just selected */
	@Output() resultSelected: EventEmitter<any> = new EventEmitter();
	/** Emits an array of missionaries that were just unselected */
	@Output() resultsRemoved: EventEmitter<any> = new EventEmitter();
	searchResults = [];
	showModal = false;
	allMissionaries = [];
	displayMissionaries = [];
	isMissionaryNameOrIdFocused = false;
	criteria: any = {
		name: '',
		scheduledArrivalStart: null,
		scheduledArrivalEnd: null,
	};
	form = new FormGroup({
		missionaryNameOrId: new FormControl(''),
	});
	columns: OmniTableColumn[] = [
		{ title: 'ID', attr: 'missionaryId', minWidth: '80px' },
		{ title: 'Type', attr: 'missionaryType', minWidth: '90px' },
		{ title: 'Name', attr: 'fullName', maxWidth: '3fr' },
	];
	omniTableConfig: OmniTableConfig = {
		rowFunction: (result) => {
			if (this.form.value.missionaryNameOrId) {
				this.showModal = false;
			}
			this.emitResultSelected(result);
		},
		resultsCountName: 'Missionary',
		noLinks: true,
	};
	formChange: any = () => {};
	onTouched = () => {};

	constructor(
		public missionaryPickerApi: MissionaryPickerApi,
		private dialogService: DialogService,
		private configService: ConfigService,
		private permissions: PermissionService,
		private changeDetection: ChangeDetectorRef,
		@Self()
		@Optional()
		public controlDir: NgControl,
	) {
		if (isNil(this.preMTC)) {
			this.preMTC = this.configService.getConfig('Missionary Search', 'preMTC').value;
		}
		if (isNil(this.inResidence)) {
			this.inResidence = this.configService.getConfig('Missionary Search', 'inResidence').value;
		}
		if (controlDir) {
			this.controlDir.valueAccessor = this;
		}
		if (this.permissions.has('MSNY_SEARCH_ALL')) {
			this.columns.push({ title: 'MTC', attr: 'mtc' });
		}
	}

	ngOnInit() {
		this.form.get('missionaryNameOrId').valueChanges.subscribe((name) => (this.criteria.name = name));
	}

	emitResultSelected(result) {
		result.disabled = true;
		const cloneResult = assign({}, result, { disabled: false });
		this.selected.push(cloneResult);
		this.selectedChange.emit(cloneDeep(this.selected));
		this.resultSelected.emit(cloneResult);
		this.formChange(this.selected.slice());
	}

	getMissionaries(event = null) {
		if (event !== null) event.stopPropagation();
		if (Object.values(this.criteria).some((c) => c)) {
			this.setLoading(true);
			this.criteria.inField = this.inField;
			this.missionaryPickerApi.getMissionaries(this.criteria).subscribe((missionaries: any) => {
				this.setLoading(false);
				this.allMissionaries = missionaries;
				this.filterDisplayMissionaries();
				if (this.displayMissionaries.length === 1) {
					this.showModal = false;
					this.emitResultSelected(this.displayMissionaries[0]);
				} else {
					this.showModal = true;
					this.changeDetection.detectChanges();
				}
			});
		}
	}

	removeMissionaries(missionaries) {
		this.selected = this.selected.filter((s) => !missionaries.some((m) => s.missionaryId === m.missionaryId));
		missionaries.map((m) => {
			m.selected = false;
			m.disabled = false;
		});
		this.formChange(this.selected.slice());
		this.resultsRemoved.emit(cloneDeep(missionaries));
		this.selectedChange.emit(cloneDeep(this.selected));
	}

	setLoading(loading: boolean) {
		this.dialogService.dialogLoading = loading;
		this.loading.emit(loading);
	}

	filterDisplayMissionaries() {
		this.displayMissionaries = this.allMissionaries.filter(
			(m) =>
				((this.preMTC && m.status === 'Pre-MTC') ||
					(this.preMTC && m.status === 'Scheduled') ||
					(this.inResidence && m.status === 'In-Residence') ||
					(this.inField && m.status === 'In Field')) &&
				this.excludedMissionaries.every((miss) => miss.missionaryId !== m.missionaryId),
		);
	}

	updateOptions(option, event) {
		this.configService.setConfig('Missionary Search', option, +event.checked).subscribe();
		this.filterDisplayMissionaries();
	}
	writeValue(value: any) {
		this.selected = value || [];
	}

	registerOnChange(formChange?: (value: any) => void) {
		this.formChange = formChange;
	}

	registerOnTouched(onTouched) {
		this.onTouched = onTouched;
	}
}
