import { Pipe, PipeTransform } from '@angular/core';
import * as pluralize from 'pluralize';
@Pipe({
	name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
	constructor() {}

	transform(value: string, count?: number, inclusive?: boolean): any {
		return pluralize(value, count, inclusive);
	}
}
