<div class="container" [class.warning]="config.warningText">
	<mat-expansion-panel [expanded]="true">
		<mat-expansion-panel-header [expandedHeight]="'50px'" [collapsedHeight]="'50px'">
			<mat-panel-title class="subheading">{{config.title || title}}</mat-panel-title>
			<mat-panel-description>
				<button class="button flat-btn" *ngIf="showButton && (!config.button.displayFunction || config.button.displayFunction())" (click)="handleButtonClick($event)">{{config.button.title}}</button>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<ng-content></ng-content>
	</mat-expansion-panel>
</div>
