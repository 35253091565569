<div class="print clinical-page" *mobxAutorun>
	<div>
		<header class="header">
			<div>Printed by: {{user.preferredName}}</div>
			<strong class="missionary-name" cy-elm="msny-name">{{missionary.fullName | uppercase}} ({{missionary.missionaryId}})</strong>
		</header>
		<div>
			<hr>
		</div>
		<div class="missionary-info">
			<div>
				<img class="miss-pic" [src]="missionary.photoUrl" alt="missionary picture">
			</div>
			<div class="missionary-info-table">
				<span class="label">{{missionary.missionaryType}}:</span>
				<span>{{missionary.fullName | uppercase}}</span>

				<ng-container *ngFor="let companion of [missionary.assignmentInfo?.companionFullName1, missionary.assignmentInfo?.companionFullName2]">
					<span class="label">Companion:</span>
					<span>{{companion}}</span>
				</ng-container>

				<span class="label">MTC Status:</span>
				<span>{{missionary.status}}</span>

				<span class="label">Sub Status:</span>
				<span>{{missionary.assignmentInfo.subStatus}}</span>

				<span class="label">Component:</span>
				<span>{{missionary.assignmentInfo.component}}</span>

				<span class="label">Group Date:</span>
				<span>{{missionary.assignmentInfo.groupDate | mtcDate}}</span>

				<span class="label">Depart Date:</span>
				<span>{{missionary.schedDepartureDate | mtcDateTime}}</span>

				<span class="label">Birthday:</span>
				<span>{{missionary.personalInfo.birthDate | mtcDate}}</span>
			</div>
			<div class="missionary-info-table">
				<div>
					<span class="label" class="label">ID:</span>
					<span> {{missionary.missionaryId}}</span>
				</div>

				<div>
					<span class="label" class="label">DMBA:</span>
					<span> {{missionary.personalInfo.dmba}}</span>
				</div>

				<span class="label">Mission Language:</span>
				<span>{{missionary.assignmentInfo.missionLanguage}}</span>

				<span class="label">Training Language:</span>
				<span>{{missionary.assignmentInfo.trainingLanguage}}</span>

				<span class="label">Training Schedule:</span>
				<span>{{missionary.assignmentInfo.trainingScheduleId}}</span>

				<span class="label">Branch-District:</span>
				<span>{{missionary.assignmentInfo.branchDistrict}}</span>

				<span class="label">Residence:</span>
				<span>{{missionary.assignmentInfo.residenceRoomNum}}</span>

				<span class="label">Mailbox:</span>
				<span>{{missionary.assignmentInfo.mailboxName}}</span>

				<span class="label">Classroom:</span>
				<span>{{missionary.assignmentInfo.classroomName}}</span>
			</div>
		</div>
		<div>
			<hr>
		</div>
		<div class="row">
			<div class="contact-table">
				<span class="label">Mission:</span>
				<span>{{missionary.assignmentInfo.missionName}}</span>

				<span class="label">President:</span>
				<span>{{missionary.contacts.missionPresidentName}}</span>

				<span class="label">Mobile:</span>
				<span>{{missionary.contacts.missionPresidentCellPhone}}</span>

				<span class="label">Office:</span>
				<span>{{missionary.contacts.missionOfficePhone}}</span>

				<span class="label">Home:</span>
				<span>{{missionary.contacts.missionHomePhone}}</span>

				<span class="label address">Home Address:</span>
				<span [innerHTML]="missionary.contacts.missionOfficeAddress | address"></span>
			</div>
			<div class="contact-table">
				<span class="label">Stake:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.parentUnitName}}</span>

				<span class="label">President:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.parentUnitLeaders[0]?.fullName}}</span>

				<span class="label">Mobile:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.parentUnitLeaders[0]?.cellPhone}}</span>

				<span class="label address">Address:</span>
				<span [innerHTML]="missionary.contacts.unitInfoList[0]?.parentUnitLeaders[0]?.address.fullAddress | address"></span>

				<span class="label">Home:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.parentUnitLeaders[0]?.homePhone}}</span>

				<span class="label">Email:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.parentUnitLeaders[0]?.email}}</span>
			</div>
			<div class="contact-table">
				<span class="label">Ward:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.unitName}}</span>

				<span class="label">Bishop:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.unitLeaders[0]?.fullName}}</span>

				<span class="label">Mobile:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.unitLeaders[0]?.cellPhone}}</span>

				<span class="label address">Address:</span>
				<span [innerHTML]="missionary.contacts.unitInfoList[0]?.unitLeaders[0]?.address.fullAddress | address"></span>

				<span class="label">Home:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.unitLeaders[0]?.homePhone}}</span>

				<span class="label">Email:</span>
				<span>{{missionary.contacts.unitInfoList[0]?.unitLeaders[0]?.email}}</span>
			</div>
		</div>
		<div>
			<hr>
		</div>
		<div class="row" *ngIf="missionary.contacts.personalContacts as contacts">
			<div class="contact-table" *ngIf="contacts[0] as contact1">
				<span class="label">{{contact1.otherRelationship || contact1.contactType}}:</span>
				<span>{{contact1.name}}</span>

				<span class="label"></span>
				<span class="do-not-contact">{{contact1.doNotContact ? 'DNC' : ''}}</span>

				<span class="label">Member:</span>
				<span>{{contact1.member | yesNo}}</span>

				<span class="label">Deceased:</span>
				<span>{{contact1.deceased | yesNo}}</span>

				<span class="label">Home:</span>
				<span>{{contact1.homePhone}}</span>

				<span class="label">Email:</span>
				<span>{{contact1.emailAddress}}</span>

				<span class="label">Address:</span>
				<div>
					<div>{{contact1.addressLine}}</div>
					<div>{{contact1.city}}, {{contact1.stateProvince}} {{contact1.postalCode}}</div>
				</div>
			</div>
			<div class="contact-table" *ngIf="contacts[1] as contact2">
				<span class="label">{{contact2.otherRelationship || contact2.contactType}}:</span>
				<span>{{contact2.name}}</span>

				<span class="label"></span>
				<span class="do-not-contact">{{contact2.doNotContact ? 'DNC' : ''}}</span>

				<span class="label">Member:</span>
				<span>{{contact2.member | yesNo}}</span>

				<span class="label">Deceased:</span>
				<span>{{contact2.deceased | yesNo}}</span>

				<span class="label">Home:</span>
				<span>{{contact2.homePhone}}</span>

				<span class="label">Email:</span>
				<span>{{contact2.emailAddress}}</span>

				<span class="label">Address:</span>
				<div>
					<div>{{contact2.addressLine}}</div>
					<div>{{contact2.city}}, {{contact2.stateProvince}} {{contact2.postalCode}}</div>
				</div>
			</div>
		</div>
		<br>
		<div>
			<hr>
		</div>
		<div class="row">
			<div class="contact-table" *ngIf="missionary.contacts?.districtPresidentName">
				<span class="label">District Pres: </span>
				<span>{{missionary.contacts?.districtPresidentName}}</span>

				<span class="label">Mobile: </span>
				<span>{{missionary.contacts?.districtPresidentCellPhone}}</span>

				<span class="label">Home: </span>
				<span>{{missionary.contacts?.districtPresidentHomePhone}}</span>

				<span class="label">Email: </span>
				<span>{{missionary.contacts?.districtPresidentEmail}}</span>
			</div>
			<div class="contact-table" *ngIf="missionary.contacts?.branchPresidentName">
				<span class="label">Branch Pres: </span>
				<span>{{missionary.contacts?.branchPresidentName}}</span>

				<span class="label">Mobile: </span>
				<span>{{missionary.contacts?.branchPresidentCellPhone}}</span>

				<span class="label">Home: </span>
				<span>{{missionary.contacts?.branchPresidentHomePhone}}</span>

				<span class="label">Email: </span>
				<span>{{missionary.contacts?.branchPresidentEmail}}</span>
			</div>
		</div>
		<br *ngIf="missionary.contacts?.districtPresidentName">
		<br *ngIf="missionary.contacts?.districtPresidentName">
		<div class="row">
			<div class="column">
				<div>
					<strong>Pre-Existing:</strong>
					<span class="spacing">Yes</span>
					<span class="spacing">No</span>
				</div>
				<strong>Diagnosis:</strong>
				<strong>GAF:</strong>
			</div>
			<div class="column">
				<strong>Medications:</strong>
				<div></div>
				<div></div>
				<strong>Intake:</strong>

			</div>
			<div class="column">
				<strong>Difficulty: 1 2 3 4 5</strong>
				<div></div>
				<div></div>
				<strong>Outcome:</strong>
			</div>
		</div>
	</div>
</div>
