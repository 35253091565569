<div class="person-search-component" cy-elm="person-search" *mobxAutorun>
	<div class="person-search">
		<div class="search-type-container">
			<div class="options">
				<mtc-static-tabs *ngIf="searchStore.tabs.length > 1" [tabs]="searchStore.tabs" [(currentTab)]="searchStore.currentTab" (currentTabChange)="searchStore.getSearchHistory()"></mtc-static-tabs>
				<div class="search-options" *ngIf="searchStore.currentTab === 'Missionary'">
					<mat-checkbox class="search-option" [(ngModel)]="searchStore.searchOptions.preMTC" (change)="searchStore.updateOptions()">Pre-MTC </mat-checkbox>
					<mat-checkbox class="search-option" [(ngModel)]="searchStore.searchOptions.scheduled" (change)="searchStore.updateOptions()">Scheduled </mat-checkbox>
					<mat-checkbox class="search-option" [(ngModel)]="searchStore.searchOptions.inResidence" (change)="searchStore.updateOptions()" cy-elm="in-residence-checkbox">In Residence</mat-checkbox>
					<mat-checkbox class="search-option" [(ngModel)]="searchStore.searchOptions.departed" (change)="searchStore.updateOptions()">Departed </mat-checkbox>
					<mat-checkbox class="search-option" [ngModel]="searchStore.searchAll" (change)="searchStore.updateAllOptions($event.checked)">All </mat-checkbox>
				</div>
				<mat-slide-toggle class="toggle" *ngIf="permissions.has('MSNY_SEARCH_ALL')" color="primary" [checked]="searchStore.searchOptions.allMtcs" (change)="searchStore.toggleAllMtcs($event.checked)">
					All MTCs
				</mat-slide-toggle>
			</div>
			<div class="btn-container">
				<button class="navigation-btn" [routerLink]="['/search-and-reports/custom-search']" (click)="close.emit()" *ngIf="searchStore.currentTab === 'Missionary'">Custom Search</button>
				<span class="clickable column-title" (click)="close.emit()" cy-elm="close-person-search">
					<mat-icon class="remove">clear</mat-icon>
				</span>
			</div>
		</div>
		<div class="page-title">
			{{searchStore.title}}
		</div>
		<div class="omni-table-container">
			<ng-container *ngIf="searchStore.searching; then loading; else loaded"></ng-container>
		</div>
		<ng-template #loading>
			<div class="dialog-loading-image"></div>
			<div class="loading-text">Loading</div>
		</ng-template>
		<ng-template #loaded>
			<mtc-omni-table class="mtc-omni-table" [columns]="searchStore.tableColumns" [rows]="searchStore.tableRows" [config]="config"></mtc-omni-table>
		</ng-template>
	</div>
</div>
