import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../dialogs/confirmation/confirmation.dialog';
import { Injectable } from '@angular/core';
import { env, mtcToolsUrl } from '@mtc/shared/urls';
import { filter } from 'rxjs/operators';
import { MTCAuth } from '@mtc/shared/auth';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DialogService {
	dialogLoading: boolean;

	constructor(private dialog: MatDialog, private http: HttpClient) {}

	confirm(config, expectResponse?: boolean) {
		MTCAuth.refreshToken();
		const confirmConfig = {
			data: {
				cancelButtonText: config.cancelButtonText || 'No',
				confirmationButtonText: config.confirmationButtonText || 'Yes',
				content: config.content,
				title: config.title,
				disableClose: config.disableClose || false,
			},
			height: config.height || '',
			width: '400px',
			disableClose: true,
		};
		return this.dialog
			.open(ConfirmationDialog, confirmConfig)
			.afterClosed()
			.pipe(filter((confirmed) => (expectResponse ? expectResponse : confirmed)));
	}

	alert(config) {
		MTCAuth.refreshToken();
		const alertConfig = {
			data: {
				confirmationButtonText: 'Okay',
				content: config.content,
				title: config.title,
			},
			height: config.height,
			width: '400px',
			panelClass: 'dialog-word-wrap',
			disableClose: true,
		};
		return this.dialog
			.open(ConfirmationDialog, alertConfig)
			.afterClosed()
			.pipe(filter((response) => response));
	}

	open(component: any, config: any = {}) {
		MTCAuth.refreshToken();
		config.disableClose = true;
		if (env !== 'prod' && config.height) {
			throw new Error(
				'<strong>Passing in a height may result in undefined behavior. Are you sure you want to pass in a height?</strong>',
			);
		}
		return this.dialog
			.open(component, config)
			.afterClosed()
			.pipe(filter((response) => response));
	}

	postDialogAction(dialogName) {
		return this.http
			.post(`${mtcToolsUrl}/action?type=Dialog&origin=desktop`, `${dialogName}`, {
				responseType: 'text',
			})
			.subscribe();
	}
}
