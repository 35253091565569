import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, ConnectedPosition } from '@angular/cdk/overlay';
import { TemplatePortal, CdkPortal } from '@angular/cdk/portal';
import { isNil } from 'lodash';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnDestroy {
	@ViewChild(CdkPortal, { static: false }) contentTemplate: CdkPortal;
	@Output() toggleOverlayChange = new EventEmitter();
	@Input() offset = 0;
	@Input('connectTo') overlayContainer;
	@OnChange(function() {
		if (this.toggleOverlay) {
			this.show();
		} else {
			setTimeout(() => {
				this.hide();
				this.toggleOverlayChange.emit(false);
			});
		}
	})
	@Input()
	toggleOverlay;
	portalHost: OverlayRef;
	portal: TemplatePortal<any>;

	constructor(private overlay: Overlay, public ref: ChangeDetectorRef) {}

	ngOnInit() {}

	get overlayConfig() {
		const bottomRight: ConnectedPosition = {
			originX: 'start',
			originY: 'bottom',
			overlayX: 'start',
			overlayY: 'top',
		};
		const topRight: ConnectedPosition = {
			originX: 'start',
			originY: 'top',
			overlayX: 'start',
			overlayY: 'bottom',
		};

		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.overlayContainer)
			.withPush(true)
			.withPositions([bottomRight, topRight]);
		return new OverlayConfig({
			positionStrategy: positionStrategy,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
		});
	}

	show() {
		this.displayOverlay();
	}

	displayOverlay() {
		this.portalHost = this.overlay.create(this.overlayConfig);
		this.portalHost.attach(this.contentTemplate);
		this.portalHost.backdropClick().subscribe(() => this.hide());
	}

	hide() {
		if (!isNil(this.portalHost)) {
			this.portalHost.dispose();
			this.portalHost.detach();
		}
		this.toggleOverlay = false;
	}

	ngOnDestroy() {
		this.hide();
	}
}
