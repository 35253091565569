import { Platform } from './platform';

export class State {
	constructor(public type: string = 'init', public data: any = null) {}

	public static fromString(stringState: string): State {
		if (stringState) {
			try {
				const state = JSON.parse(stringState);
				return new State(state.type, state.data);
			} catch (error) {
				// TODO: error
			}
		}
		return new State();
	}

	public toString(): string {
		return JSON.stringify({ type: this.type, data: this.data });
	}
}

export interface StateDefinition {
	function: Function;
	validTransitions: string[];
}

export interface MachineDefinition {
	[key: string]: StateDefinition;
}

export class Machine {
	constructor(
		private platform: Platform,
		private definition: MachineDefinition,
		private state: State = new State(),
	) {}

	public init() {
		this.definition[this.state.type].function(this.state.data);
	}

	public transition(state: State, soft = false) {
		// if no data in new state, use old state
		state.data = state.data === null ? this.state.data : state.data;

		const currentStateDefinition = this.definition[this.state.type];
		if (currentStateDefinition.validTransitions.includes(state.type)) {
			const destinationState = state.type;
			const destinationStateDefinition = this.definition[destinationState];
			const newState = new State(destinationState, state.data);

			// save updated state to disk on each transition
			this.platform.set('state', newState.toString());
			this.state = newState;

			if (!soft) {
				setTimeout(() => {
					destinationStateDefinition.function(this.state.data);
				});
			}
		}
	}
}
