import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from './permission.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
	constructor(public permissions: PermissionService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (this.permissions.areLoaded) {
			return this.hasPermission(state.url);
		}
		const subject = new Subject<boolean>();
		this.permissions.load().subscribe(() => {
			subject.next(this.hasPermission(state.url));
		});
		return subject.pipe(first());
	}

	hasPermission(path) {
		if (path[0] === '/') {
			path = path.slice(1);
		}
		if (!this.permissions.allowedToNavigate(path)) {
			this.router.navigate(['/unauthorized']);
			return false;
		}
		return true;
	}
}
