import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-date-stepper',
	templateUrl: './date-stepper.component.html',
	styleUrls: ['./date-stepper.component.scss'],
})
export class DateStepperComponent implements OnInit {
	/** Restricts weeks available to before a specific date. Must be a moment or the string 'today' */
	@OnChange(function(date) {
		if (date === 'today') {
			date = moment().startOf('day');
		}
		this.isBefore = date;
	})
	@Input()
	isBefore: any;
	/** Date selected is a single day, navigation buttons change days instead of weeks. Must be passed first. */
	@Input() isDay: any = false;
	/** Prevents date navigation */
	@Input() disabledIcons: any = false;
	/**  Restricts weeks available to after a specific date. Must be a moment or the string 'lastWeek' */
	@OnChange(function(date) {
		if (date === 'lastWeek') {
			date = moment()
				.startOf('week')
				.subtract(1, 'week');
		}
		this.isAfter = date;
	})
	@Input()
	isAfter;
	/** The initial date to be selected. Must be a moment or the strings 'thisWeek' or 'today' */
	@OnChange(function(date) {
		if (date) {
			if (date === 'thisWeek' || date === 'today') {
				date = moment();
			}
			this.type = this.isDay ? 'day' : 'week';
			this.startDate = date.clone().startOf(this.type);
		}
	})
	@Input()
	date;
	/** Emits when date is changed or week navigation is clicked, only if isDay is false */
	@Output() changeWeek = new EventEmitter();
	/** Emits when date is changed or day navigation is clicked, only if isDay is true */
	@Output() dateChange = new EventEmitter();

	type: any = 'week';
	startDate = moment().startOf(this.type);
	showCalendar = false;
	clientRect: any;
	get endDate() {
		if (!this.isDay) {
			return this.startDate.clone().endOf('week');
		}
	}

	constructor() {}

	ngOnInit() {}

	clickArrow(direction) {
		this.type = this.isDay ? 'day' : 'week';
		this.startDate = this.navigateBy(direction, this.type, this.startDate.clone());
		this.emitDate();
	}

	newDateSelected(newDate) {
		if (newDate && moment.isMoment(newDate)) {
			this.startDate = newDate.startOf(this.type);
			this.emitDate();
			this.showCalendar = false;
		}
	}

	emitDate() {
		this.isDay ? this.dateChange.emit(this.startDate) : this.changeWeek.emit([this.startDate, this.endDate]);
	}

	navigateBy(direction: 'add' | 'subtract', type, date: moment.Moment) {
		return moment(date)
			.startOf(type)
			[direction](1, type)
			.clone();
	}

	isArrowClickable(direction: 'left' | 'right') {
		if (this.disabledIcons) {
			return false;
		}
		if (direction === 'left') {
			return !this.isAfter || !this.isAfter.isSameOrAfter(this.startDate);
		}
		return !this.isBefore || !this.isBefore.isSameOrBefore(this.endDate || this.startDate);
	}
}
