<div class="no-text-selection">
	<div class="date-stepper">
		<mat-icon class="icon" [class.disabled-icon]="!isArrowClickable('left')" (click)="clickArrow('subtract')" cy-elm="date-stepper-left-arrow">chevron_left</mat-icon>
		<div #overlayHost class="calendar-popup" (click)="showCalendar = !showCalendar" cy-elm="date-stepper-calendar-icon">
			<mat-icon class="icon" *ngIf="!disabledIcons">{{isDay ? 'event':'date_range'}}</mat-icon>
		</div>
		<mtc-overlay [(toggleOverlay)]="showCalendar" [connectTo]="overlayHost">
			<mtc-mini-calendar [isBefore]="isBefore" [isAfter]="isAfter" [rangePickerType]="!isDay ? 'weekPicker' : ''" [date]="startDate" (changeFirstDate)="newDateSelected($event)" cy-elm="mini-calendar"></mtc-mini-calendar>
		</mtc-overlay>
		<div class="date" cy-elm="date-range">{{(isDay ? startDate : startDate.startOf('week')) | mtcDate : endDate }}</div>
		<mat-icon class="icon" [class.disabled-icon]="!isArrowClickable('right')" (click)="clickArrow('add')" cy-elm="date-stepper-right-arrow">chevron_right</mat-icon>
	</div>
</div>
