import { Injectable } from '@angular/core';
import { MTCAuth } from '@mtc/shared/auth';
import { Observable, zip } from 'rxjs';
import { RoutesRecognized, NavigationStart, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { mtcToolsUrl } from '@mtc/shared/urls';
import { ToastStore } from '@mtc/shared/toast';
import { PermissionService } from '@mtc/shared/permissions';
import { MtcInfoService } from './mtc-info.service';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root',
})
export class AppInitService {
	constructor(
		private router: Router,
		private http: HttpClient,
		private toaster: ToastStore,
		private permissions: PermissionService,
		private mtcInfo: MtcInfoService,
		private config: ConfigService,
	) {}

	load(): Observable<any> {
		this.router.events.subscribe((e) => {
			if (e instanceof RoutesRecognized) {
				this.postNavAction(e.url);
			}

			if (e instanceof NavigationStart) {
				this.toaster.clearMessage();
				MTCAuth.refreshToken();
			}
		});

		return zip(this.permissions.load(), this.mtcInfo.load(), this.config.load());
	}

	postNavAction(url) {
		this.http
			.post(`${mtcToolsUrl}/action?type=Navigation&origin=desktop`, `${url}`, {
				responseType: 'text',
			})
			.subscribe();
	}
}
