<mtc-dialog-template [config]="dialogTemplateConfig">
	<span>To import {{dialogData.name | pluralize: 2}} fill out the <a [href]="templateCSV" [download]="templateFileName">.csv template</a> and then upload it here.</span>
	<input #csvInput type="file" accept=".csv" class="hidden" id="selectedFile" (change)="file = $any($event).target.files[0]">
	<div class="file-container">
		<button class="picker-button primary-btn" (click)="csvInput.click()">Choose File</button>
		<div *ngIf="file" class="file-chip">
			<span class="file-name">{{filename}}</span>
			<mat-icon class="clickable" (click)="clearFile()">clear</mat-icon>
		</div>
	</div>
</mtc-dialog-template>
