import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularResizedEventModule } from 'angular-resize-event';

import { LayoutComponent } from './components/layout/layout.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { EndMenuComponent } from './components/end-menu/end-menu.component';
import { IconModule } from '@mtc/shared/icon';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PersonSearchComponent } from './components/person-search/person-search.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TabsModule } from '@mtc/shared/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OmniTableModule } from '@mtc/shared/omni-table';
import { MobxAngularModule } from 'mobx-angular';
import { SearchStore } from './services/search.store';
import { FavoritesBarComponent } from './components/favorites-bar/favorites-bar.component';
import { ManageFavoritesDialog } from './dialogs/manage-favorites/manage-favorites.dialog';
import { DialogModule } from '@mtc/shared/dialog';
import { DirectivesModule } from '@mtc/shared/directives';
import { NavigationStoreService } from './services/navigation.service';
import { LandingComponent } from './components/landing.component';
import { ToolsCommonModule } from '@mtc/tools/common';
import { PipesModule } from '@mtc/shared/pipes';

const routes: Routes = [
	{ path: 'unauthorized', component: UnauthorizedComponent },
	{ path: 'landing', component: LandingComponent },
];
@NgModule({
	imports: [
		CommonModule,
		MatButtonModule,
		MatSidenavModule,
		FormsModule,
		IconModule,
		RouterModule.forChild(routes),
		MatCheckboxModule,
		TabsModule,
		MatSlideToggleModule,
		OmniTableModule,
		MobxAngularModule,
		MatCardModule,
		DialogModule,
		DragDropModule,
		AngularResizedEventModule,
		DirectivesModule,
		ToolsCommonModule,
		PipesModule,
	],
	declarations: [
		LayoutComponent,
		NavMenuComponent,
		EndMenuComponent,
		UnauthorizedComponent,
		FavoritesBarComponent,
		ManageFavoritesDialog,
		PersonSearchComponent,
		LandingComponent,
	],
	exports: [LayoutComponent],
	providers: [SearchStore, NavigationStoreService],
})
export class CoreModule {}
