import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-mini-calendar',
	templateUrl: './mini-calendar.component.html',
	styleUrls: ['./mini-calendar.component.scss'],
})
export class MiniCalendarComponent implements OnInit {
	@ViewChild('miniCalendar', { static: false }) miniCalendar: ElementRef;
	@Input() secondDate: any;
	@Input() rangePickerType: 'freeRange' | 'weekPicker' | '';
	@Input() yearPicker: boolean;
	@OnChange(function() {
		this.ngOnInit();
	})
	@Input()
	date;
	@OnChange(function() {
		this.ngOnInit();
	})
	@Input()
	isAfter;
	@OnChange(function() {
		this.ngOnInit();
	})
	@Input()
	isBefore;

	@Output() changeFirstDate = new EventEmitter();
	@Output() changeSecondDate = new EventEmitter();
	@Output() offPage = new EventEmitter();
	@Output() calendarClose = new EventEmitter();

	public month: any;
	public weeks: any[];
	public dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	public hoverDate: any;
	private secondDateSelected: boolean;

	@Input()
	setDisabled(date) {
		let disabled = false;
		if (this.isAfter) {
			if (this.isAfter === 'today') {
				this.isAfter = new Date();
			}
			if (date.isBefore(this.isAfter, 'day')) {
				disabled = true;
			}
		}
		if (this.isBefore) {
			if (this.isBefore === 'today') {
				this.isBefore = new Date();
			}
			if (date.isAfter(this.isBefore, 'day')) {
				disabled = true;
			}
		}
		return disabled;
	}

	constructor() {}

	ngOnInit() {
		this.secondDateSelected = !!this.secondDate;
		const start = this.date ? moment(this.date) : moment();
		this.month = start.clone();
		start.date(1);
		this._setMonthStartDay(start.day(0));
		this._buildMonth(start);
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			if (this.date && !this.secondDateSelected) {
				this.secondDate = this.date;
				if (this.rangePickerType === 'freeRange') {
					this.changeSecondDate.emit(this.secondDate);
				}
				this.calendarClose.emit('close');
			} else {
				this.calendarClose.emit('close');
			}
		}
	}

	public getHighlight(day, index?) {
		if (this.rangePickerType === 'weekPicker' && this.isInWeek(day.date)) {
			return this.getWeekPickerStyles(day.date, index);
		} else if (this.rangePickerType === 'freeRange') {
			return this.getRangePickerStyles(day.date);
		} else if (
			(this.date && this.date.isSame(day.date)) ||
			(this.hoverDate && this.hoverDate.isSame(day.date) && this.hoverDate.date() === day.numb)
		) {
			return 'both';
		}
	}

	getWeekPickerStyles(date, index) {
		if (index === 0) {
			return this.isWeekPickerHovering(date) ? 'left-hover' : 'left-selected';
		}
		if (index === 6) {
			return this.isWeekPickerHovering(date) ? 'right-hover' : 'right-selected';
		}
		return this.isWeekPickerHovering(date) ? 'in-range-hover' : 'in-range';
	}

	getRangePickerStyles(date) {
		if (this.isSingleDate(date)) {
			return 'both';
		} else if (this.isDateInRange(date)) {
			return 'in-range';
		} else if (
			(this.secondDate && this.secondDate.isSame(date)) ||
			(this.hoverDate && this.date && !this.secondDate && this.hoverDate.isSame(date))
		) {
			return 'right-selected';
		} else if ((this.date && this.date.isSame(date)) || (this.hoverDate && this.hoverDate.isSame(date))) {
			return 'left-selected';
		}
	}

	isSingleDate(date) {
		return this.date && this.secondDate && this.date.isSame(this.secondDate) && this.secondDate.isSame(date);
	}

	isDateInRange(date) {
		return this.date && this.date.isBefore(date) && this.secondDate && this.secondDate.isAfter(date);
	}

	public isInWeek(day) {
		if (this.date) {
			if (this.rangePickerType === 'weekPicker') {
				const startOfWeek = this.date.clone().startOf('week');
				const endOfWeek = this.date.clone().endOf('week');
				return (
					startOfWeek.isSame(day) ||
					(startOfWeek.isBefore(day) && endOfWeek.isAfter(day)) ||
					this.isWeekPickerHovering(day)
				);
			} else {
				return (
					this.date.isSame(day) ||
					(this.secondDate && this.secondDateSelected ? this.secondDate.isSame(day) : false)
				);
			}
		}
		return this.isWeekPickerHovering(day);
	}

	public isWeekPickerHovering(day) {
		if (this.hoverDate && this.rangePickerType === 'weekPicker') {
			const beginningOfWeek = this.hoverDate.clone().startOf('week');
			const endOfWeek = this.hoverDate.clone().endOf('week');
			return beginningOfWeek.isSame(day) || (beginningOfWeek.isBefore(day) && endOfWeek.isAfter(day));
		}
		return false;
	}

	public daySelect(day) {
		if (!day.isCurrentMonth) {
			if (day.date.isBefore(this.month, 'month')) {
				this.previousMonth();
			} else {
				this.nextMonth();
			}
		}
		if (this.rangePickerType !== 'freeRange' || !this.date) {
			this.firstSelect(day);
		} else if (
			this.rangePickerType === 'freeRange' &&
			this.date &&
			(this.secondDateSelected || this.date.isAfter(day.date))
		) {
			this.firstSelect(day);
			this.secondDate = null;
			this.changeSecondDate.emit(null);
			this.secondDateSelected = false;
		} else if (this.rangePickerType === 'freeRange' && this.date.isSameOrBefore(day.date)) {
			this.secondDateSelected = true;
			this.secondSelect(day);
		}
		this.hoverDate = null;
	}

	public firstSelect(day) {
		if (!day.disabled) {
			this.date = day.date;
			this.date.date(day.numb);
			this.changeFirstDate.emit(this.date);
		}
	}

	public secondSelect(day) {
		if (!day.disabled) {
			this.secondDate = day.date;
			this.changeSecondDate.emit(this.secondDate);
		}
	}

	public setHoverDate(day) {
		if (this.isInWeek(day)) {
			return;
		}

		this.hoverDate = day;
		if (
			this.date &&
			this.rangePickerType === 'freeRange' &&
			!this.secondDateSelected &&
			this.date.isSameOrBefore(day)
		) {
			this.changeSecondDate.emit(this.hoverDate);
		}
	}

	public checkHoverDay() {
		if (!this.secondDateSelected) {
			this.hoverDate = null;
			this.changeSecondDate.emit(this.date);
		}
	}

	public nextMonth() {
		const next = this.month.clone();
		this._setMonthStartDay(next.month(next.month() + 1).date(1));
		this.month.month(this.month.month() + 1);
		this._buildMonth(next);
	}

	public previousMonth() {
		const previous = this.month.clone();
		this._setMonthStartDay(previous.month(previous.month() - 1).date(1));
		this.month.month(this.month.month() - 1);
		this._buildMonth(previous);
	}

	public nextYear() {
		//make Array length 12 and fill with 1s to iterate
		Array(12)
			.fill(1)
			.forEach(() => {
				this.nextMonth();
			});
	}

	public previousYear() {
		//make Array length 12 and fill with 1s to iterate
		Array(12)
			.fill(1)
			.forEach(() => {
				this.previousMonth();
			});
	}

	public _setMonthStartDay(date) {
		date.startOf('week');
	}

	public _buildWeek(date, month) {
		const days = [];
		for (let i = 0; i < 7; i++) {
			days.push({
				name: date.format('dd').substring(0, 1),
				numb: date.date(),
				isCurrentMonth: date.month() === month.month(),
				isToday: date.isSame(new Date(), 'day'),
				disabled: this.setDisabled(date),
				date: date,
			});
			date = date.clone();
			date.add(1, 'd');
		}
		return days;
	}

	public _buildMonth(start) {
		this.weeks = [];
		const date = start.clone();
		let done = false;
		let monthIndex = date.month();
		let count = 0;
		while (!done) {
			this.weeks.push({
				days: this._buildWeek(date.clone(), this.month),
				numb: count,
			});
			date.add(1, 'w');
			done = count++ > 2 && monthIndex !== date.month();
			monthIndex = date.month();
		}
	}
}
