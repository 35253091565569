<div class="clock-picker">
	<div class="time">
		<div class="time-label" [class.active]="type === 'hours'" (click)="type = 'hours'">{{time | moment:true | date:'hh':timezone}}</div>
		<div class="time-label">:</div>
		<div class="time-label" [class.active]="type === 'minutes'" (click)="type = 'minutes'">{{time | moment:true | date:'mm':timezone}}</div>
		<div class="time-label" class="meridian">
			<div class="time-label" [class.active]="meridian === 'AM'" (click)="toggle('AM')">AM</div>
			<div class="time-label" [class.active]="meridian === 'PM'" (click)="toggle('PM')">PM</div>
		</div>
	</div>
	<div class="clock">
		<div class="clock-face">
			<div class="clock-overlay" (click)="timeChangeLocal($event)" (mousemove)="timeChangeLocal($event)">
				<div class="tick" *ngFor="let n of clockNumbers" [attr.minutes]="n"
					[class.highlighted]="hoverAngle | clockHighlight:type:n " [class.dot]="(type | clockNum : n) === ' '">
					{{type | clockNum : n}}
				</div>
			</div>
			<div class="pointer-container" [attr.minutes]="type | rotationMinutes : hours : minutes">
				<div class="pointer" [class.highlighted]="(90 - minutes*5) === hoverAngle-90"></div>
			</div>
		</div>
	</div>
</div>
