import { Platform } from './platform';
import { AuthConfig } from '../config.model';

export class WebPlatform implements Platform {
	private readonly AUTH_SESSION_STORAGE_KEY = 'mtc-oauth';
	private readonly config: AuthConfig;
	private readonly base: string;

	constructor(config: AuthConfig) {
		this.config = config;
		this.base = this.AUTH_SESSION_STORAGE_KEY + '-' + this.config.clientId + '-';
	}

	// storage
	public get(key: string): Promise<string> {
		return Promise.resolve(window.sessionStorage.getItem(this.base + key));
	}

	public set(key: string, value: string) {
		window.sessionStorage.setItem(this.base + key, value);
	}

	public remove(key: string) {
		window.sessionStorage.removeItem(this.base + key);
	}

	// browser
	public open(url: string) {
		window.location.href = url;
	}
}
