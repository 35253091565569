<div class="autocomplete">
	<div class="mtc-autocomplete-input-container">
		<div class="multis" #multis *ngIf="multiChips.length >= 0" [style.max-height]="(21 * pillRows) + 'px'">
			<div class="chip" *ngFor="let chip of multiChips; let i = index">
				<div class="name" [attr.cy-elm]="'chip-' + i">
					{{chip.display}}
				</div>
				<span class="remove" (click)="removeItem(chip, i)" [attr.cy-elm]="'chip-' + i + '-remove'">
					<mat-icon>clear</mat-icon>
				</span>
			</div>
			<mat-icon class="add" [class.active]="showDropDown" (click)="onAdd()" [class.hide]="!multiChips?.length || multiChips?.length === itemList?.length " cy-elm="add-button">add</mat-icon>
		</div>
		<mat-form-field (click)="inputClick()" [floatLabel]="multiChips.length ? 'always' : 'auto'">
			<input #overlayHost matInput type="text" class="mtc-autocomplete-input" #input (keyup)="onKeyUp($event)" [placeholder]="placeholder"
			 [class.hidden]="hideInput" (focusout)="setFocus(false)" [formControl]="childControl" [style.margin-top.px]="inputHeight" cy-elm="autocomplete-input">
			<mat-error>
				<span *ngIf="errors?.required" cy-elm="multi-required">Required Field</span>
				<span *ngIf="errors?.invalidInput" cy-elm="multi-invalid">Invalid Input</span>
			</mat-error>
		</mat-form-field>
		<mtc-overlay [(toggleOverlay)]="showDropDown" [connectTo]="overlayHost">
			<mtc-autocomplete-dropdown [keyPress]="keyPress" [items]="searchResults" [displayBy]="displayBy" (currentItemIndexChange)="chooseItem(searchResults[$event])"></mtc-autocomplete-dropdown>
		</mtc-overlay>
	</div>
</div>
