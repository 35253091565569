import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';

@Pipe({
	name: 'virtualScroll',
})
export class VirtualScrollPipe implements PipeTransform {
	@memo((arg1, arg2, arg3) => arg1.toString() + arg2 + arg3)
	transform(top, index, bottom): any {
		return index >= top && index <= bottom;
	}
}
