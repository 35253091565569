import { Component, OnInit } from '@angular/core';
import { ToastStore } from '../../services/toast.store';

@Component({
	selector: 'mtc-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
	constructor(public toaster: ToastStore) {}

	ngOnInit() {}
}
