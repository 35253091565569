<div class="print summary-page" *mobxAutorun>
	<div [class.first-page]="shouldExpand">
		<header class="header">
			<span>Printed by: {{user.preferredName}}</span>
			<span>Date: {{today | mtcDate}}</span>
			<strong class="missionary-name">{{missionary.fullName | uppercase}} ({{missionary.missionaryId}})</strong>
		</header>
		<div>
			<hr>
		</div>
		<div class="missionary-info">
			<div>
				<img class="miss-pic" [src]="missionary.photoUrl" alt="missionary picture">
			</div>
			<div class="missionary-info-table-1">
				<span class="label">{{missionary.missionaryType}}:</span>
				<span cy-elm="msny-name">{{missionary.fullName | uppercase}}</span>

				<ng-container *ngFor="let companion of [missionary.assignmentInfo?.companionFullName1, missionary.assignmentInfo?.companionFullName2]">
					<span class="label">Companion:</span>
					<span>{{companion|uppercase}}</span>
				</ng-container>

				<span class="label">MTC Status:</span>
				<span>{{missionary.assignmentInfo.status}}</span>

				<span class="label">Sub Status:</span>
				<span>No sub status</span>

				<span class="label">Component:</span>
				<span>{{missionary.assignmentInfo.component}}</span>

				<span class="label">Group Date:</span>
				<span>{{missionary.assignmentInfo.groupDate | mtcDate}}</span>

				<span class="label">Depart Date:</span>
				<span>{{missionary.assignmentInfo.schedDepartureDate | mtcDateTime}}</span>
			</div>
			<div class="missionary-info-table-2">
				<span class="label">ID:</span>
				<span>{{missionary.missionaryId}}</span>

				<span class="label">Mission Language:</span>
				<span>{{missionary.assignmentInfo.missionLanguage}}</span>

				<span class="label">Training Language:</span>
				<span>{{missionary.trainingLanguage}}</span>

				<span class="label">Training Schedule:</span>
				<span>{{missionary.assignmentInfo.trainingScheduleId}}</span>

				<span class="label">Branch-District:</span>
				<span>{{missionary.assignmentInfo.branchDistrict}}</span>

				<span class="label">Residence:</span>
				<span>{{missionary.assignmentInfo.residenceRoomNum}}</span>

				<span class="label">Mailbox:</span>
				<span>{{missionary.assignmentInfo.mailboxName}}</span>

				<span class="label">Classroom:</span>
				<span>{{missionary.assignmentInfo.classroomName}}</span>
			</div>
		</div>
		<div>
			<hr>
		</div>
		<div class="leader-info">
			<div class="contact-table">
				<span class="section-header">Mission Information</span>

				<span class="label">Mission:</span>
				<span>{{missionary.assignmentInfo.missionName}}</span>

				<span class="label">President:</span>
				<span>{{missionary.contacts.missionPresidentName}}</span>

				<span class="label">Office Phone:</span>
				<span>{{missionary.contacts.missionOfficePhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{missionary.contacts.missionPresidentCellPhone}}</span>

				<span class="label">Home Phone:</span>
				<span>{{missionary.contacts.missionHomePhone}}</span>

				<span class="label">Home Address:</span>
				<span [innerHTML]="missionary.contacts.missionHomeAddress | address"></span>
			</div>
			<div class="contact-table" *ngIf="missionary.contacts?.unitInfoList as unitInfoList">
				<span class="section-header">Home Ward Information</span>

				<span class="label">Ward:</span>
				<span>{{unitInfoList[0]?.unitName}}</span>

				<span class="label">Bishop:</span>
				<span>{{unitInfoList[0]?.unitLeaders[0]?.fullName}}</span>

				<span class="label">Work Phone:</span>
				<span>{{unitInfoList[0]?.unitLeaders[0]?.workPhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{unitInfoList[0]?.unitLeaders[0]?.cellPhone}}</span>

				<span class="label">Home Phone:</span>
				<span>{{unitInfoList[0]?.unitLeaders[0]?.homePhone}}</span>

				<span class="label">Email:</span>
				<span>{{unitInfoList[0]?.unitLeaders[0]?.email}}</span>
			</div>
		</div>
		<div class="leader-info">
			<div class="contact-table">
				<span class="section-header">MTC Branch Information</span>

				<span class="label">President:</span>
				<span>{{missionary.contacts.branchPresidentName}}</span>

				<span class="label">Work Phone:</span>
				<span>{{missionary.contacts.branchPresidentWorkPhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{missionary.contacts.branchPresidentCellPhone}}</span>

				<span class="label small-break">Home Phone:</span>
				<span>{{missionary.contacts.branchPresidentHomePhone}}</span>

				<ng-container *ngIf="missionary.contacts.branch1stCounselor as branch1stCounselor">
					<span class="label">1st Counselor:</span>
					<span>{{branch1stCounselor.fullName}}</span>

					<span class="label">Work Phone:</span>
					<span>{{branch1stCounselor.workPhone}}</span>

					<span class="label">Mobile Phone:</span>
					<span>{{branch1stCounselor.cellPhone}}</span>

					<span class="label small-break">Home Phone:</span>
					<span>{{branch1stCounselor.homePhone}}</span>
				</ng-container>
				<ng-container *ngIf="missionary.contacts.branch2ndCounselor as branch2ndCounselor">
					<span class="label">2nd Counselor:</span>
					<span>{{branch2ndCounselor.fullName}}</span>

					<span class="label">Work Phone:</span>
					<span>{{branch2ndCounselor.workPhone}}</span>

					<span class="label">Mobile Phone:</span>
					<span>{{branch2ndCounselor.cellPhone}}</span>

					<span class="label">Home Phone:</span>
					<span>{{branch2ndCounselor.homePhone}}</span>
				</ng-container>
			</div>
			<div class="contact-table" *ngIf="missionary.contacts.unitInfoList as unitInfoList">
				<span class="section-header">Home Stake Information</span>

				<span class="label">Stake: </span>
				<span>{{unitInfoList[0]?.parentUnitName}}</span>

				<span class="label">President:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[0]?.fullName}}</span>

				<span class="label">Work Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[0]?.workPhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[0]?.cellPhone}}</span>

				<span class="label">Home Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[0]?.homePhone}}</span>

				<span class="label small-break">Mailing Address:</span>
				<span class="small-break" [innerHTML]="unitInfoList[0]?.parentUnitLeaders[0]?.address.fullAddress | address"></span>

				<span class="label">1st Counselor:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[1]?.fullName}}</span>

				<span class="label">Work Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[1]?.workPhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[1]?.cellPhone}}</span>

				<span class="label small-break">Home Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[1]?.homePhone}}</span>

				<span class="label">2nd Counselor:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[2]?.fullName}}</span>

				<span class="label">Work Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[2]?.workPhone}}</span>

				<span class="label">Mobile Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[2]?.cellPhone}}</span>

				<span class="label">Home Phone:</span>
				<span>{{unitInfoList[0]?.parentUnitLeaders[2]?.homePhone}}</span>
			</div>
		</div>
		<div class="contact-info">
			<div class="section-header">Contact Information</div>
			<ng-container *ngIf="missionary.contacts?.personalContacts">
				<div *ngFor="let contact of missionary.contacts.personalContacts" class="contact-table">
					<ng-container *ngIf="contact.contactType">
						<span class="label">{{contact.contactType}}:</span>
						<div>
							<span>{{contact.name}}</span>
							<span class="do-not-contact" *ngIf="contact.doNotContact">DNC</span>
						</div>

						<span class="label">Member:</span>
						<span>{{contact.member | yesNo}}</span>

						<span class="label">Deceased:</span>
						<span>{{contact.deceased | yesNo}}</span>

						<span class="label">Home Phone:</span>
						<span>{{contact.homePhone}}</span>

						<span class="label">Email:</span>
						<span>{{contact.emailAddress}}</span>

						<span class="label">Address:</span>
						<div>
							<span>{{contact.addressLine}}</span>
							<span>{{contact.city}}, {{contact.stateProvince}} {{contact.postalCode}}</span>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
	<div *ngIf="shouldExpand">
		<header class="header">
			<span>Printed by: {{user.preferredName}}</span>
			<span>Date: {{today | mtcDate}}</span>
			<strong class="missionary-name">{{missionary.fullName | uppercase}} ({{missionary.missionaryId}})</strong>
		</header>
		<div>
			<hr>
		</div>
	</div>
	<div>
		<div *ngIf="missionary.printSummaryModalInfo.includeSchedule">
			<div class="section-header" *ngIf="schedule.length">Schedule Week {{schedule[0].week}} Day
				{{schedule[0].day }} {{selectedScheduleDate.toDate() | mtcDate}}</div>
			<div *ngIf="schedule.length" class="schedule-table label">
				<span class="underline">Start Time</span>
				<span class="underline">End Time</span>
				<span class="underline">Activity</span>
				<span class="underline">Location</span>
			</div>
			<div *ngFor="let scheduleItem of schedule" class="schedule-table schedule-item no-left-padding"
			[class.current]="scheduleItem.currentLocation">
				<span>{{scheduleItem.startTime | mtcTime}}</span>
				<span>{{scheduleItem.endTime | mtcTime}}</span>
				<span>{{scheduleItem.description}}</span>
				<span>{{scheduleItem.location}}</span>
			</div>
		</div>
		<div *ngIf="missionary.printSummaryModalInfo.includeSchedule && !schedule.length">
			<div class="section-header">Schedule</div>
			<div class="centered">No Schedule</div>
		</div>
		<div>
			<div *ngFor="let category of noteCategories">
				<div *ngIf="showNotes(category)" class="section-header">{{category}}</div>
				<div *ngFor="let note of displayNotes[category]">
					<div *ngIf="showNotes(category) && displayNotes[category].length > 0">
						<div class="note-table">
							<div class="label">{{note.createdByName}}</div>
							<div class="label">{{note.createdDt | mtcDate}}</div>
							<div>{{note.visName}}</div>
						</div>
						<div class="padding-bottom" [innerHTML]="note.summary"></div>
					</div>
				</div>
				<div *ngIf="showNotes(category) && !displayNotes[category]" class="centered">No {{category}} Notes</div>
			</div>
		</div>
	</div>
</div>
