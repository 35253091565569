import { Injectable } from '@angular/core';
import { UserService } from '@mtc/shared/auth';
import { observable, action, computed } from 'mobx';
import { MissionarySearchService } from '@mtc/shared/missionary';
import { Router } from '@angular/router';
import { MtcInfoService } from '@mtc/shared/info';
import { ConfigService } from '@mtc/shared/info';
import { OmniTableColumn } from '@mtc/shared/omni-table';
import { PermissionService } from '@mtc/shared/permissions';
import { Subject } from 'rxjs';

enum Tabs {
	Employee = 'Employee',
	Missionary = 'Missionary',
}
@Injectable()
export class SearchStore {
	@observable searching = true;
	@observable missionarySearchResults: any = [];
	@observable employeeSearchResults: any = [];
	@observable title = 'Search History';
	@observable searchQuery = '';
	@observable currentTab;
	@observable tabs = [];
	@observable
	searchOptions = {
		preMTC: false,
		scheduled: false,
		inResidence: true,
		departed: false,
		all: false,
		allMtcs: false,
	};
	allConnectedTo = ['preMTC', 'inResidence', 'scheduled', 'departed'];
	searchApiSubscription;

	missionaryColumns: OmniTableColumn[] = [
		{ title: '', attr: 'photoUrl', minWidth: '4px', type: 'image' },
		{ title: 'Missionary', attr: 'fullName' },
		{ title: 'MTC', attr: 'mtc' },
		{ title: 'ID', attr: 'missionaryId' },
		{ title: 'Mission', attr: 'missionName' },
		{ title: 'Language', attr: 'trainingLanguage' },
		{ title: 'Status', attr: 'status' },
		{ title: 'Arrival', attr: 'mtcStartDate', type: 'mtcDate' },
		{ title: 'Departure', attr: 'mtcDepartureDate', type: 'mtcDate' },
	];

	employeeColumns: OmniTableColumn[] = [
		{ title: '', attr: 'photoUrl', minWidth: '4px', type: 'image' },
		{ title: 'Name', attr: 'fullName' },
		{ title: 'Department', attr: 'department' },
		{ title: 'Job Title', attr: 'positionDesignation' },
		{ title: 'Office/Classroom', attr: 'room' }, //TODO: room is not a current value that we are returning for employees
		{ title: 'Email', attr: 'email' },
		{ title: 'Phone Number', attr: 'phone' },
	];

	constructor(
		public searchApi: MissionarySearchService,
		public user: UserService,
		public router: Router,
		public configService: ConfigService,
		public toolsInfoService: MtcInfoService,
		private permissions: PermissionService,
	) {
		if (this.permissions.has('MSNY_SEARCH')) {
			this.tabs.push(Tabs.Missionary);
		}
		if (this.permissions.has('PROFILE_EMP_INFO')) {
			this.tabs.push(Tabs.Employee);
		}
		this.currentTab = this.tabs[0];

		Object.keys(this.searchOptions).forEach((option) => {
			if (this.configService.getConfig('Missionary Search', option)) {
				this.searchOptions[option] = this.configService.getConfig('Missionary Search', option).value === '1';
			}
		});
	}

	search(event?) {
		const close: Subject<any> = new Subject();
		this.searching = true;
		if (this.searchApiSubscription) {
			this.searchApiSubscription.unsubscribe();
		}
		this.searchForPersons(close);
		if (event) {
			event.stopPropagation();
		}
		return close.asObservable();
	}

	@computed
	get tableRows() {
		return this.isMissionarySearch ? this.missionarySearchResults : this.employeeSearchResults;
	}

	@computed
	get tableColumns() {
		return this.isMissionarySearch ? this.missionaryColumns : this.employeeColumns;
	}

	@computed
	get isMissionarySearch() {
		return this.currentTab === Tabs.Missionary;
	}

	@computed
	get searchAll() {
		return this.allConnectedTo.every((option) => this.searchOptions[option]);
	}

	@action
	searchForPersons(close) {
		this.title = 'Search Results';
		if (this.isMissionarySearch) {
			this.searchOptions.all = this.searchAll;
			this.searchApiSubscription = this.searchApi
				.getMissionaries(this.searchOptions, this.searchQuery)
				.subscribe((results) => {
					this.missionarySearchResults = results;
					if (this.missionarySearchResults.length === 1) {
						this.navigateToPerson(this.missionarySearchResults[0].missionaryId, close);
					}
					this.searching = false;
				});
		} else {
			this.searchApiSubscription = this.searchApi.getEmployees(this.searchQuery).subscribe((results) => {
				this.employeeSearchResults = results;
				if (this.employeeSearchResults.length === 1) {
					this.navigateToPerson(this.employeeSearchResults[0], close);
				}
				this.searching = false;
			});
		}
	}

	@action
	navigateToPerson(personId, close) {
		this.employeeSearchResults = [];
		this.missionarySearchResults = [];
		if (this.currentTab === 'Missionary') {
			this.router.navigate(['/missionary/profile/overview', personId]);
		} else {
			this.router.navigate(['/staff/employee/view', personId.ldsAccountId, personId.supervisorId || 0]);
		}
		close.next();
		close.complete();
		this.searchQuery = '';
	}

	@action
	getSearchHistory() {
		this.title = 'Search History';
		this.searching = true;
		this.searchApi[`get${this.currentTab}SearchHistory`]().subscribe((searchHistory) => {
			this[`${this.currentTab.toLowerCase()}SearchResults`] = searchHistory;
			this.searching = false;
		});
	}

	@action
	updateAllOptions(updateTo) {
		this.allConnectedTo.forEach((option) => (this.searchOptions[option] = updateTo));
		this.updateOptions();
	}

	@action
	updateOptions() {
		Object.keys(this.searchOptions).forEach((option) => {
			const v = +!!this.searchOptions[option];
			this.configService.setConfig('Missionary Search', option, v).subscribe();
		});
		if (this.title === 'Search Results') {
			this.search();
		}
	}

	@action
	toggleAllMtcs(showAll) {
		this.searchOptions.allMtcs = showAll;
		this.updateOptions();
	}
}
