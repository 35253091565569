import { Directive, Input, HostListener } from '@angular/core';

@Directive({ selector: 'form' })
export class PreventFormSubmitDirective {
	@Input() allowEnterSubmit = false;

	@HostListener('keyup.enter', ['$event'])
	onKeyUp(event) {
		if (!this.allowEnterSubmit) {
			event.preventDefault();
		}
	}
}
