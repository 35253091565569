import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import { get } from 'lodash';
import { UserService } from '@mtc/shared/auth';

@Component({
	selector: 'mtc-print-summary-one-page',
	templateUrl: './print-summary-one-page.component.html',
	styleUrls: ['./print-summary-one-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintSummaryOnePageComponent implements OnInit {
	@Input() missionary: any;
	@Input() schedule = [];
	@Input() noteCategories = [];
	@Input() selectedScheduleDate;
	@Input() displayNotes;
	shouldExpand = false;
	printable = false;
	today = new Date();

	constructor(public user: UserService) {
		this.shouldExpand = Object.keys(get(this.missionary, 'printSummaryModalInfo', {})).length >= 2;
	}

	ngOnInit() {}

	showNotes(category: string): boolean {
		const showNotes = this.missionary.printSummaryModalInfo;
		return showNotes[category];
	}
}
