import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateConfig } from '@mtc/shared/dialog';
import { NavigationStoreService } from '../../services/navigation.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'tools-manage-favorites',
	templateUrl: './manage-favorites.dialog.html',
	styleUrls: ['./manage-favorites.dialog.scss'],
})
export class ManageFavoritesDialog {
	dialogTemplateConfig: DialogTemplateConfig = {
		title: 'Manage Favorites',
		confirmClick: () => this.save(),
		cancelClick: () => this.cancel(),
		closeClick: () => this.cancel(),
	};
	sortedFavorites;
	selectedStarLinks = [];
	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) private dialogData: any,
		public navService: NavigationStoreService,
	) {}

	save() {
		this.navService.originalLinks = cloneDeep(this.navService.updateableLinks);
		this.navService.updateFavoritesSortOrder(this.navService.favorites).subscribe();
		this.navService.favoriteLink(this.selectedStarLinks).subscribe();
		this.dialogRef.close();
	}

	cancel() {
		this.navService.updateableLinks = cloneDeep(this.navService.originalLinks);
		this.dialogRef.close();
	}

	makeFavorite(link) {
		link.isFavorite = !link.isFavorite;
		this.sortFavorites();
		if (link.isFavorite) {
			link.favoriteSort = this.navService.favorites.length;
		}
		this.selectedStarLinks.push(link);
	}

	onItemDrop(event: CdkDragDrop<string[]>) {
		this.sortedFavorites = cloneDeep(this.navService.flatLinks);
		moveItemInArray(this.navService.favorites, event.previousIndex, event.currentIndex);
		this.sortFavorites();
	}

	sortFavorites() {
		this.navService.favorites.forEach((link, index) => {
			link.favoriteSort = index;
			this.navService.updateLink(link);
		});
	}
}
