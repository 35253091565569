<div class="tools-layout" [class.layout-overlay]="nav.showMoreLinks || searchIsOpen">
	<div class="header no-print logo-container">
		<div class="header-overlay" *ngIf="searchIsOpen" (click)="searchIsOpen = false"></div>
		<button [disableRipple]="true" mat-icon-button class="nav-menu-button" (click)="open('navmenu')" [class.open]="navmenu.opened" cy-elm="open-navmenu">
			<mat-icon svgIcon="menu"></mat-icon>
		</button>
		<img src="./assets/images/tools-logo.svg" class="tools-logo" [routerLink]="['']">
		<tools-favorites-bar class="favorites" (closeMenu)="closeMenu()"></tools-favorites-bar>
		<span *ngIf="searchIsOpen" class="search-container" (clickOutside)="searchIsOpen = false" [class.open]="searchIsOpen">
			<div class="search-input">
				<input cy-elm="top-search-input" #searchInput placeholder="Search" *ngIf="searchIsOpen" [(ngModel)]="searchStore.searchQuery" (keyup.enter)="search($event)">
				<mat-icon cy-elm="top-search-confirm" (click)="search($event)" svgIcon="magnify"></mat-icon>
			</div>
			<div class="arrow-up"></div>
			<div class="search-results"*ngIf="searchIsOpen">
				<tools-person-search (close)="closeSearch()"></tools-person-search>
			</div>
		</span>
		<button cy-elm="top-search-button" class="search-button" [disableRipple]="true" mat-icon-button (click)="open('search')" *ngIf="permissions.has('SEARCH')">
			<mat-icon svgIcon="magnify"></mat-icon>
		</button>
		<button cy-elm="top-account-button" [disableRipple]="true" mat-icon-button (click)="open('endmenu')" [class.open]="endmenu.opened" class="account-circle">
			<mat-icon svgIcon="account-circle"></mat-icon>
		</button>
		<button cy-elm="secret" class="secret" mat-icon-button *ngIf="inTesting" (click)="runChangeDetection()"> <mat-icon>security</mat-icon> </button>
	</div>
	<mat-sidenav-container>
		<mat-sidenav class="nav-menu" #navmenu [autoFocus]="false">
			<tools-nav-menu (close)="closeNavMenu()"></tools-nav-menu>
		</mat-sidenav>
		<mat-sidenav-content>
			<router-outlet></router-outlet>
		</mat-sidenav-content>
		<mat-sidenav class="end-menu" position="end" #endmenu>
			<tools-end-menu (close)="closeMenu()" [(showAllMtcs)]="showAllMtcs"></tools-end-menu>
		</mat-sidenav>
	</mat-sidenav-container>
</div>
