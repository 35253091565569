<ng-container *mobxAutorun>
	<div class="action-header">
		<div class="results-count" [class.hidden]="!config.resultsCountName" cy-elm="results-count">
			{{ rows.length }} {{ resultsCountName | pluralize: rows.length }}<span *ngIf="config.newItems">&nbsp;({{ newItemCount }} new)</span>
		</div>
		<div *ngIf="visibleTopButtons || config.export || config.manageColumns">
			<button type="button" class="flat-btn" *ngFor="let button of visibleTopButtons" (click)="button.function(selectedRows, rows)" [attr.cy-elm]="'table ' + button.text | kebab">{{ button.text }}</button>
			<button type="button" class="flat-btn" *ngIf="config.import" (click)="openImport()" cy-elm="table-import">Import</button>
			<button type="button" class="flat-btn" *ngIf="config.export && rows.length" (click)="export()" cy-elm="table-export">
				<a #downloadResults href="csv" download="download.csv">Export</a>
			</button>
			<button type="button" class="flat-btn" *ngIf="config.manageColumns" (click)="openManageColumns()" cy-elm="table-manage-columns">Manage Columns</button>
		</div>
	</div>
	<div class="grid-container" [class.no-action-header]="!config.resultsCountName" [style.grid-template-columns]="gridTemplateColumns">
		<ng-container *ngIf="!config.dateHeader">
			<div class="header-cell" *ngIf="colorBarColumn"></div>
			<mat-checkbox class="header-cell" *ngIf="showCheckboxes" [(ngModel)]="allSelected" cy-elm="table-checkbox"></mat-checkbox>
			<div class="header-cell" *ngIf="config.newItems"></div>
			<ng-container *ngFor="let column of visibleColumns">
				<div class="header-cell" [class.filterable]="column.type!='image'" *ngIf="!(column.colorBarConfig && $any(column.colorBarConfig).hideLabel)" [class.menu-open]="column.menuOpen"
					[class.active-filter]="column.sortDirection || column.filter || column.menuOpen" [class.has-color-bar]="column.colorBarConfig"
					[class.has-checkbox]="showCheckboxes" (click)="sortColumns(column, 'toggle')" (clickOutside)="column.searchQuery = column.filter" [attr.cy-elm]="'header ' + column.title | kebab">
					<span #overlayHost cy-elm="column-title">{{ column.title }}</span>
					<div class="filter-icon" *ngIf="!column.iconConfig && column.type !== 'image'" (click)="openFilter(filterInput, column, $event)">
						<span *ngIf="column.sortOrder > 0">{{ column.sortOrder }}</span>
						<mat-icon>filter_list</mat-icon>
					</div>
					<mtc-overlay [(toggleOverlay)]="column.menuOpen" [connectTo]="overlayHost" [offset]="3">
						<div class="filter-menu">
							<div class="sort-options">
								<div class="sort-direction" (click)="sortColumns(column, 'asc')" [class.active]="column.sortDirection === 'asc'" cy-elm="sort-asc">
									<span>Sort Ascending</span>
									<span *ngIf="column.sortOrder > 0 && column.sortDirection === 'asc'">{{ column.sortOrder }}</span>
								</div>
								<div class="sort-direction" (click)="sortColumns(column, 'desc')" [class.active]="column.sortDirection === 'desc'" cy-elm="sort-desc">
									<span>Sort Descending</span>
									<span *ngIf="column.sortOrder > 0 && column.sortDirection === 'desc'">{{ column.sortOrder }}</span>
								</div>
								<hr/>
								<div class="search-input">
									<input matInput #filterInput placeholder="Search" [ngModel]="column.filter" (ngModelChange)="column.searchQuery = $event" (keyup.enter)="filterColumns(column, column.searchQuery)" cy-elm="filter-input">
									<mat-icon (click)="filterColumns(column, column.searchQuery)" cy-elm="filter-icon">search</mat-icon>
								</div>
							</div>
						</div>
					</mtc-overlay>
				</div>
			</ng-container>
			<div class="header-cell row-btn-container" *ngIf="permissibleRowButtons.length">
				<ng-container *ngFor="let button of permissibleRowButtons">
					<button type="button" class="flat-btn hidden" [class.disabled-btn]="hasDisabledRow">{{ hasDisabledRow && button.disabledText ? button.disabledText : button.text }}</button>
				</ng-container>
			</div>
		</ng-container>
		<div *ngIf="config.dateHeader" class="date-header">
			<div class="day" *ngIf="!config.dateHeader?.excludeDay">{{ config.dateHeader.moment?.format('dddd') }}</div>
			<div class="date" *ngIf="!config.dateHeader?.excludeDate">{{ config.dateHeader.moment | mtcDate }}</div>
		</div>
		<div *ngIf="rows.length" (resized)="height = $event.newHeight" #dataContainer class="data-container" (scroll)="config.showAll ? '' : vsScroll = dataContainer.scrollTop; clearHover()"
			[class.show-hover]="showRowHover" [style.grid-template-columns]="gridTemplateColumns" [style.grid-auto-rows]="rowHeight">
			<ng-container *ngFor="let row of rows; let rowIndex = index">
				<ng-container *ngIf="rowIndex >= topIndex && rowIndex <= bottomIndex">
					<div class="cell color-bar" *ngIf="colorBarColumn" [class.same-as-next]="colorInfo[rowIndex].colorSameAsNext" [style.background-color]="colorInfo[rowIndex].barColor" [style.grid-row-start]="rowIndex + 1" [attr.cy-elm]="'color-bar-' + rowIndex" [class.hover]="row.hover"></div>
					<div [class.hover]="row.hover" class="cell" (mouseenter)="row.hover = true" (mouseleave)="row.hover = false" [class.shaded]="row.selected"  *ngIf="showCheckboxes" [class.show-all]="config.showAll" [style.grid-row-start]="rowIndex + 1">
						<mat-checkbox [attr.cy-elm]="'cell checkbox ' + rowIndex | kebab" [(ngModel)]="row.selected" [class.disabled]="row.disabled"></mat-checkbox>
					</div>
					<div class="cell" *ngIf="config.newItems" (click)="handleRowClick(row)" [class.shaded]="row.shaded || row.selected" [class.hover]="row.hover" [class.disabled]="row.disabled" [class.show-all]="config.showAll" [style.grid-row-start]="rowIndex + 1" (mouseenter)="row.hover = true" (mouseleave)="row.hover = false">
						<div [class.new-circle]="row.newItem" [attr.cy-elm]="'new circle ' + rowIndex | kebab"></div>
					</div>
					<ng-container *ngFor="let column of visibleColumns">
						<div class="cell" *ngIf="!column.colorBarConfig || !$any(column.colorBarConfig).hideLabel" (click)="handleCellClick(column,row)" [class.shaded]="row.shaded || row.selected" [class.hover]="row.hover" [class.disabled]="row.disabled" [class.has-color-bar]="column.colorBarConfig" [class.has-checkbox]="showCheckboxes" [class.has-action]="column.function" [class.show-all]="config.showAll" [style.grid-row-start]="rowIndex + 1" [style.color]="column.colorBarConfig ? colorInfo[rowIndex].barColor : ''" (mouseenter)="row.hover = true" (mouseleave)="row.hover = false" [attr.cy-elm]="'cell-' + column.title + '-' + rowIndex | kebab">
							<ng-container [ngSwitch]="column.type || 'none'" *ngIf="!column.colorBarConfig || colorInfo[rowIndex].isFirst">
								<img *ngSwitchCase="'image'" class="image" [src]="getAttr(row, column.attr)">
								<span *ngSwitchCase="'yesNo'" >
									<span *ngIf="getAttr(row, column.attr) !== ''">{{ getAttr(row, column.attr) | yesNo }}</span>
								</span>
								<span *ngSwitchCase="'icon'" class="icon-container" (mouseenter)="column.hover = true" (mouseleave)="column.hover = false">
									<mat-icon [style.color]="getIconColor(row, column)">{{ column.iconConfig.name || row[column.iconConfig.nameAttr] }}</mat-icon>
								</span>

								<ng-container *ngSwitchCase="'none'">
									<!-- NORMAL TEXT -->
									<span class="cell-text" *ngIf="(!getAttr(row, column.attr + 'Link') && !getAttr(row, column.attr + 'ExternalLink')) || config.noLinks" [innerHTML]="getAttr(row, column.attr)"></span>

									<!--LINKS-->
									<div>
										<a class="cell-text" (click)="stopProp($event)" *ngIf="getAttr(row, column.attr + 'Link') && !config.noLinks" [routerLink]="[getAttr(row, column.attr + 'Link')]" cy-elm="cell-link"><span [innerHTML]="getAttr(row, column.attr)"></span></a> <!-- TODO routerlink doesn't work-->
									</div>
									<div>
										<a class="cell-text" (click)="stopProp($event)" *ngIf="getAttr(row, column.attr + 'ExternalLink') && !config.noLinks" [href]="getAttr(row, column.attr + 'ExternalLink')" target="_blank" cy-elm="cell-external-link"><span [innerHTML]="getAttr(row, column.attr)"></span></a>
									</div>
								</ng-container>

								<!-- Edit Function -->
								<ng-container *ngSwitchCase="'editing'" [ngSwitch]="column.editingConfig.type">
									<mat-form-field *ngSwitchCase="'input'" class="input-field" [floatLabel]="'never'">
										<input matInput #input [type]="column.editingConfig.numeric ? 'number' : ''" class="edit-cell" [class.edit-hover]="row.hover" [placeholder]="column.editingConfig.placeholder" [ngModel]="getAttr(row, column.attr)" (ngModelChange)="setAttr(row, column.attr, $event)" (focusout)="column.editingConfig.function(getAttr(row, column.attr), row)" (keydown)="handleKeyDown($event, input)" (click)="$event.stopPropagation()" cy-elm="edit-input">
									</mat-form-field>
									<div *ngSwitchCase="'textArea'" #textArea class="text-area edit-cell" [class.edit-hover]="row.hover" contenteditable="true" [innerHTML]="getAttr(row, column.attr) || column.editingConfig.placeholder" (focusout)="handleAttrChange($any($event).target.innerHTML, row, column)" (keydown)="handleKeyDown($event, textArea, true)" (keypress)="handleKeyPress(textArea, column.editingConfig.maxLength)" (click)="$event.stopPropagation()"></div>
									<ng-container *ngSwitchCase="'autocomplete'" #autoCompleteCell>
										<span *ngIf="autoCompleteCell !== selectedAutoCompleteCell" class="edit-cell auto-cell" [class.edit-hover]="row.hover" (click)="selectedAutoCompleteCell = autoCompleteCell; $event.stopPropagation();">{{getAttr(row, column.attr)?.name || getAttr(row, column.attr)}}</span>
										<mtc-autocomplete-cell *ngIf="autoCompleteCell === selectedAutoCompleteCell" [row]="row" [column]="column" (optionSelected)="closeAutoComplete()" (click)="$event.stopPropagation()"></mtc-autocomplete-cell>
									</ng-container>
									<mtc-date-picker *ngSwitchCase="'date-picker'" [date]="getAttr(row, column.attr)" (dateChange)="handleAttrChange($event, row, column)" [inOmniTable]="true" [omniHover]="row.hover"></mtc-date-picker>
								</ng-container>

								<span *ngSwitchDefault>{{ getAttr(row, column.attr) || null | dynamic: column.type }}</span>
							</ng-container>
							<!--SECOND ATTRIBUTE-->
							<span class="cell-text">{{ getAttr(row, column.secondAttr) }}</span>
						</div>
					</ng-container>
					<div class="cell row-btn-container" *ngIf="permissibleRowButtons.length" (mouseenter)="row.hover = true" (mouseleave)="row.hover = false" [class.hover]="row.hover" [class.disabled]="row.disabled" [class.shaded]="row.selected" [class.show-all]="config.showAll"  [style.grid-row-start]="rowIndex + 1" [attr.cy-elm]="'cell buttons ' + rowIndex | kebab">
						<ng-container *ngFor="let button of permissibleRowButtons">
							<button type="button" class="flat-btn" [class.hidden]="isButtonHidden(button, row)" [class.disabled-btn]="row.disabled" (click)="handleButtonClick(button, row)" [attr.cy-elm]="'row ' + button.text | kebab">{{row.disabled && button.disabledText ? button.disabledText : button.text}}</button>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
			<div class="dummy-row" [style.grid-row-start]="rows.length" *ngIf="bottomIndex < rows.length - 1">{{rows.length}}</div>
		</div>
		<div class="placeholder" *ngIf="!rows.length && config.placeholder" [innerHTML]="config.placeholder" cy-elm="table-placeholder"></div>
	</div>
</ng-container>
