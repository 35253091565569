import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'tools-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
	@Input() header;
	@Input() message;
	@Input() buttonText;
	@Input() buttonClass;
	@Input() imageUrl;

	@Output() buttonClick = new EventEmitter();

	constructor() {}

	onButtonClick() {
		this.buttonClick.emit();
	}
}
