import { Component, Input, Output, EventEmitter, OnInit, Self, Optional, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgControl, AbstractControl, ValidatorFn, ControlValueAccessor } from '@angular/forms';
import { isString, replace, get } from 'lodash';
import { Subject } from 'rxjs';
import { MtcInfoService } from '@mtc/shared/info';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
	/** Event emits when selection is made. Allows for double binding. (e.g [(itemChosen)]="yourItem") */
	@Output() itemChosenChange: EventEmitter<any> = new EventEmitter<any>();
	/** Event emits when dropdown is toggled */
	@Output() isDropdownOpen: EventEmitter<any> = new EventEmitter<any>();
	@OnChange(function(value) {
		if (isString(value)) {
			this.searchResults = this.infoService.info[value];
			this._items = this.infoService.info[value];
			if (this.childControl.value) {
				this.childControl.updateValueAndValidity();
			}
		} else {
			this.searchResults = value || [];
			this._items = value || [];
		}
	})
	/** Valid items to be used. Can be a custom array of objects or a string corresponding to a shared list from the MtcInfoService */
	@Input()
	items: any[] | string;
	/** The attribute that the autocomplete uses to display and identify elements */
	@Input() displayBy = 'name';
	/** Placeholder for the input field */
	@Input() placeholder: string;
	/** Input that sets the initial selected item */
	@Input() itemChosen;
	/** Whether or not to have a search icon when no item is selected */
	@Input() isSearchField = false;
	@OnChange(function() {
		this.childControl[this.disabled ? 'disable' : 'enable']();
		if (this.disabled) {
			this.remove();
		}
	})
	/** Sets the disabled state of the input */
	@Input()
	disabled;
	private _items: any;
	formChange: (value: any) => void;
	showDropDown: boolean;
	searchResults: any[] = [];
	keyPress: Subject<any> = new Subject<any>();
	childControl = new FormControl('');
	required = false;
	get errors() {
		return this.childControl.errors;
	}
	get parentControl() {
		return this.controlDir.control;
	}
	onTouched = () => {};

	constructor(
		public infoService: MtcInfoService,
		@Self()
		@Optional()
		public controlDir: NgControl,
		public ref: ChangeDetectorRef,
	) {
		if (controlDir) {
			this.controlDir.valueAccessor = this;
		}
	}

	ngOnInit() {
		this.initForm();
		this.childControl.valueChanges.subscribe((value) => {
			this.itemChosen = this.findMatchingItem(value, this._items, this.displayBy) || {
				[this.displayBy]: value,
				input: true,
			};
			this.itemChosenChange.emit(this.itemChosen);
		});
	}

	initForm() {
		if (this.controlDir) {
			let validators: any = this.internalValidator();

			if (this.controlDir.control.validator || this.controlDir.validator) {
				this.required = (
					((this.controlDir.control.validator || this.controlDir.validator) as any)('') || { required: false }
				).required;
				validators = [this.validate(), this.internalValidator()];
			}

			this.parentControl.setValidators(validators);
			this.parentControl.updateValueAndValidity();
			this.childControl.setValidators(validators);
			this.childControl.updateValueAndValidity({ emitEvent: false });
		} else {
			this.childControl.setValidators(this.invalidInput());
		}
	}

	onKeyUp(keyPress: any) {
		switch (keyPress.key) {
			case 'ArrowUp':
			case 'ArrowDown':
			case 'Enter':
				this.keyPress.next(keyPress.key);
				break;
			case 'Tab':
				this.setFocus(true);
				break;
			default:
				this.showDropDown = true;
				this.isDropdownOpen.emit(this.showDropDown);
				this.search();
		}
	}

	chooseItem(searchResult: any) {
		this.itemChosen = searchResult;
		this.childControl.setValue(searchResult[this.displayBy] || searchResult);
		this.showDropDown = false;
		this.isDropdownOpen.emit(this.showDropDown);
	}

	remove() {
		this.childControl.setValue('');
		this.itemChosenChange.emit(null);
	}

	search() {
		if (this._items) {
			const lower = replace(this.childControl.value.toLowerCase(), new RegExp('/[?\\+*()[]]/', 'g'), '');
			this.searchResults = this._items.filter((item) => {
				const regex: RegExp = new RegExp('(^' + lower + '|\\s' + lower + ')');
				return this.filterFunction(item, regex, this.displayBy);
			}, this);
			this.searchResults.forEach((item, index) => {
				item.mtcAutoCompleteIndex = index;
			});
		}
	}

	setFocus(bool: any) {
		if (this.childControl.enabled) {
			setTimeout(() => {
				this.showDropDown = bool;
				this.isDropdownOpen.emit(this.showDropDown);
				if (bool) {
					this.search();
				}
				this.ref.detectChanges();
			}, 200);
			this.onTouched();
		}
	}

	writeValue(value: any) {
		if (value) {
			if (value[this.displayBy] === '') {
				value = '';
			}
			this.childControl.setValue(value[this.displayBy] || value, {
				emitEvent: false,
			});
		} else {
			this.childControl.setValue('');
			this.childControl.markAsUntouched({ onlySelf: true });
			this.childControl.markAsPristine({ onlySelf: true });
		}
	}

	registerOnChange(formChange: (value: any) => void) {
		this.formChange = formChange;
		this.childControl.valueChanges.subscribe((value) => {
			if (
				!this.parentControl.value ||
				(this.childControl.value !== this.parentControl.value &&
					this.childControl.value !== this.parentControl.value[this.displayBy])
			) {
				this.formChange(this.findMatchingItem(value, this._items, this.displayBy) || '');
				this.childControl.updateValueAndValidity({ emitEvent: false });
			}
		});
	}

	registerOnTouched(onTouched) {
		this.onTouched = onTouched;
	}

	validate(): ValidatorFn {
		return (control: AbstractControl) => {
			if (!this.childControl.value) {
				return { required: true };
			}
		};
	}

	//Put all custom validators here
	internalValidator(): ValidatorFn {
		return (control: AbstractControl) => {
			if (
				this.childControl.value &&
				!(
					this.parentControl.value === this.childControl.value ||
					get(this.parentControl.value, this.displayBy) === this.childControl.value
				)
			) {
				return { invalidInput: true };
			}
		};
	}

	public invalidInput() {
		return () => {
			if (!this.childControl.value) {
				return null;
			}
			const isMatching = (this._items || []).some((item) => {
				return (
					item[this.displayBy] &&
					item[this.displayBy].toLowerCase().includes(this.childControl.value.toLowerCase())
				);
			});
			if (!isMatching) {
				return { invalidInput: true };
			}
		};
	}

	findMatchingItem(value, items, displayBy) {
		return (items || []).find((item) => item === value || item[displayBy] === value);
	}

	filterFunction(item: any, regex: RegExp, displayBy) {
		if (item[displayBy]) {
			return item[displayBy].toLowerCase().match(regex) !== null;
		} else {
			return false;
		}
	}
}
