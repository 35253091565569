import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';
import { observable, computed } from 'mobx-angular';
import { MtcInfoService } from '@mtc/shared/info';
import { cloneDeep, get, isString, find, set, isNil } from 'lodash';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'mtc-autocomplete-cell',
	templateUrl: './autocomplete-cell.component.html',
	styleUrls: ['./autocomplete-cell.component.scss'],
})
export class AutocompleteCellComponent implements OnInit, AfterViewInit, OnDestroy {
	@observable @Input() row;
	@observable @Input() column;
	@Output() optionSelected = new EventEmitter();
	@ViewChild('autoInput', { static: false }) autoInput: ElementRef;
	originalValue;
	panelOpen;
	inputFocused;

	constructor(private mtcInfo: MtcInfoService) {}

	ngOnInit() {
		this.originalValue = cloneDeep(get(this.row, this.column.attr));
	}

	ngAfterViewInit() {
		this.autoInput.nativeElement.focus();
	}

	ngOnDestroy() {
		if (
			(isString(get(this.row, this.column.attr)) &&
				this.filteredOptions.includes(get(this.row, this.column.attr))) ||
			find(this.filteredOptions, get(this.row, this.column.attr))
		) {
			this.column.editingConfig.function(get(this.row, this.column.attr), this.row, this.originalValue);
		} else {
			set(this.row, this.column.attr, this.originalValue);
		}
	}

	@computed
	get filteredOptions() {
		if (this.column) {
			if (isString(this.column.editingConfig.items)) {
				return this.mtcInfo.info[this.column.editingConfig.items].filter(
					(option) => option.name && option.name.toLowerCase().includes(this.cellValue),
				);
			}
			return this.column.editingConfig.items.filter((option) => {
				return isString(option)
					? option.toLowerCase().includes(this.cellValue)
					: option.name && option.name.toLowerCase().includes(this.cellValue);
			});
		}
		return [];
	}

	@computed
	get cellValue() {
		return isNil(get(this.row, this.column.attr))
			? ''
			: (get(this.row, this.column.attr).name || get(this.row, this.column.attr)).toLowerCase();
	}

	clearAutocompleteText(trigger) {
		set(this.row, this.column.attr, '');
		this.panelOpen = true;
		setTimeout(() => {
			this.autoInput.nativeElement.focus();
			trigger.openPanel();
		});
	}

	displayAutocompleteValue(item?) {
		if (item) {
			return isString(item) ? item : item.name;
		}
	}

	handleAutocompleteSelect() {
		this.optionSelected.emit();
	}

	handleBlur() {
		this.inputFocused = false;
		setTimeout(() => {
			if ((!this.panelOpen || !this.filteredOptions.length) && !this.inputFocused) {
				this.optionSelected.emit();
			}
		}, 100);
	}

	handleKeyPress() {
		if (!this.panelOpen || !this.filteredOptions.length) {
			this.autoInput.nativeElement.blur();
		}
	}
}
