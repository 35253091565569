import { Component, OnInit } from '@angular/core';
import { NavigationStoreService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
	selector: 'tools-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
	header = 'Access Denied';
	message =
		'You are not authorized to access this page. For help, please contact the IT Service Desk: (801) 422-6980';
	imageUrl = 'assets/images/Access-Denied.svg';
	buttonClass = 'navigation-btn';

	constructor(public nav: NavigationStoreService, private router: Router) {}

	ngOnInit() {}

	homeButtonClick() {
		this.router.navigateByUrl('');
	}
}
