<mtc-dialog-template [config]="dialogTemplateConfig" *mobxAutorun>
	<div class="manage-favorites">
		<div class="header">PAGES</div>
		<div class="options" cdkDropList (cdkDropListDropped)="onItemDrop($event)">
			<div *ngFor="let link of navService.orderedLinks" cdkDrag class="option">
				<mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
				<span class="link-category">{{ link.topMostParent }}</span>
				<span class="link-name">{{ link.title }}</span>
				<mat-icon class="material-icons column-setting favorite-star" [class.is-favorite]="link.isFavorite" (click)="makeFavorite(link)">star</mat-icon>
			</div>
		</div>
	</div>
</mtc-dialog-template>
