import { MTCAuth } from './auth';
import { AuthConfig } from './config.model';

class MTCAuthPresetService {
	configure(domain, provider, clientId, refreshDays, redirectUri, isMobile = false) {
		const scopes = ['openid', 'profile'];
		const notifyBeforeExp = 30; // notify 30 mins before refresh token expires
		const authConfig: AuthConfig = {
			domain,
			provider,
			clientId,
			refreshDays,
			redirectUri,
			scopes,
			isMobile,
			notifyBeforeExp,
		};
		return MTCAuth.configure(authConfig);
	}
}

export const MTCAuthPreset = new MTCAuthPresetService();
