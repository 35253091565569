<div class="component-container">
	<div class="card-container">
		<div class="gender-band" [class.male]="missionary.gender === 'M'"></div>
		<div class="photo-container">
			<img class="pic" [src]="missionary.photoUrl" alt="missionary picture">
			<div *ngIf="missionary.position" class="visitor-center-label">VC</div>
		</div>
		<div class="info-container">
			<div class="name-row">
				<div class="missionary-name no-overflow"><a [routerLink]="['/missionary/profile/overview',missionary.missionaryId]">{{missionary.fullName}}</a></div>
				<div class="missionary-id" cy-elm="missionary-id">({{missionary.missionaryId}})</div>
			</div>
			<div *ngIf="config.homeCountry" class="missionary-country info-row">{{missionary.homeCountry}}</div>
			<div class="info-row" *ngIf="config.status">{{missionary.status}}</div>
			<div class="info-row">
				<div *ngIf="config.mission" class="no-overflow">{{missionary.missionAbbreviation}}</div>
				<div *ngIf="config.branchDistrict">{{missionary.branchDistrict}}</div>
				<div *ngIf="config.language" class="no-overflow">{{missionary.trainingLanguage}}</div>
				<div *ngIf="config.residence">{{missionary.residence}}</div>
			</div>
			<div class="info-row" *ngIf="config.arrivalDeparture">
				<div>{{missionary.scheduledArrival | mtcDate}}</div>
				<div class="departure-date">{{missionary.scheduledDeparture | mtcDate}}</div>
			</div>
		</div>
	</div>
</div>
