import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '@mtc/shared/permissions';
import { OmniTableConfig } from '@mtc/shared/omni-table';
import { MissionarySearchService } from '@mtc/shared/missionary';
import { SearchStore } from '../../services/search.store';

@Component({
	selector: 'tools-person-search',
	templateUrl: './person-search.component.html',
	styleUrls: ['./person-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSearchComponent implements OnInit {
	@Output() close = new EventEmitter();

	config: OmniTableConfig = {
		rowButtons: [
			{
				text: 'Open',
				function: (person) => this.openProfile(person),
				disabledText: 'Opened',
				displayFunction: (person) => {
					return this.opened.includes(person.ldsAccountId) || this.opened.includes(person.missionaryId)
						? 'disabled'
						: '';
				},
			},
		],
		rowFunction: (person) => this.openRow(person),
		noLinks: true,
	};

	opened = [];

	constructor(
		public router: Router,
		public permissions: PermissionService,
		public searchStore: SearchStore,
		public searchApi: MissionarySearchService,
	) {}

	ngOnInit() {
		if (this.router.url.search(/\/employee|\/training\//) !== -1 && this.permissions.has('PROFILE_EMP_INFO')) {
			this.searchStore.currentTab = 'Employee';
		}
		if (this.searchStore.title !== 'Search Results') {
			this.searchStore.getSearchHistory();
		}
	}

	openProfile(person) {
		person.disabled = true;
		if (this.searchStore.currentTab === 'Missionary') {
			this.opened.push(person.missionaryId);
			this.router.navigate(['/missionary/profile/overview', person.missionaryId]);
			this.searchStore.getSearchHistory();
		} else {
			this.opened.push(person.ldsAccountId);
			this.router.navigate(['/staff/employee/view', person.ldsAccountId, person.supervisorId]);
		}
	}

	openRow(person) {
		this.openProfile(person);
		this.close.emit();
	}
}
