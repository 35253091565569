import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';

@Pipe({
	name: 'rotationMinutes',
})
export class RotationMinutesPipe implements PipeTransform {
	@memo((arg1, arg2, arg3) => arg1 + arg2 + arg3)
	transform(type: 'hours' | 'minutes', hour: number, minute: number): any {
		return type === 'hours' ? hour * 5 : minute;
	}
}
