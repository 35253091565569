<mat-card class="mini-calendar-component noselect" #miniCalendar (mouseleave)="checkHoverDay()">
	<div *ngIf="yearPicker" class="header">
		<button type="button" class="chevron-button" (click)="previousYear()" cy-elm="calendar-prev-year">
			<mat-icon>chevron_left</mat-icon>
		</button>
		<span cy-elm="calendar-year-display">{{month.format("YYYY")}}</span>
		<button type="button" class="chevron-button" (click)="nextYear()" cy-elm="calendar-next-year">
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>
	<div class="header">
		<button type="button" class="chevron-button" (click)="previousMonth()" cy-elm="calendar-prev-month">
			<mat-icon>chevron_left</mat-icon>
		</button>
		<span cy-elm="calendar-month-display">{{yearPicker ? month.format("MMM") : month.format("MMM YYYY")}}</span>
		<button type="button" class="chevron-button" (click)="nextMonth()" cy-elm="calendar-next-month">
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>
	<div class="week day-labels">
		<span class="day abbreviation" *ngFor="let dayLabel of dayLabels">
			<b>{{dayLabel}}</b>
		</span>
	</div>
	<div class="week" *ngFor="let week of weeks">
		<span class="day" [ngClass]="{ 'today': day.isToday, 'different-month': !day.isCurrentMonth, 'selected': isInWeek(day.date) && !isWeekPickerHovering(day.date),
			'disabled' : day.disabled }" (mousedown)="daySelect(day)" *ngFor="let day of week.days; let i = index;" [attr.side]="getHighlight(day, i)" (mouseenter)="setHoverDate(day.date)"
		 (mouseleave)="this.hoverDate = null" [attr.cy-elm]="'calendar-week-' + week.numb + '-day-' + day.numb">
			<span>
				{{day.numb}}
			</span>
		</span>
	</div>
</mat-card>
