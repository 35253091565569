import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { MtcDatePipe } from './mtc-date.pipe';
import { MtcTimePipe } from './mtc-time.pipe';

@Pipe({
	name: 'mtcDateTime',
})
export class MtcDateTimePipe implements PipeTransform {
	pipes = {
		mtcDate: new MtcDatePipe(),
		mtcTime: new MtcTimePipe(),
	};

	constructor() {}

	transform(value: any): any {
		if (isNil(value)) {
			return value;
		}
		return `${this.pipes.mtcDate.transform(value)} ${this.pipes.mtcTime.transform(value)}`;
	}
}
