import { Injectable } from '@angular/core';
import { mtcToolsUrl } from '@mtc/shared/urls';
import { HttpClient } from '@angular/common/http';
import { MtcDatePipe } from '@mtc/shared/pipes';
import { PermissionService } from '@mtc/shared/permissions';
@Injectable()
export class MissionaryPickerApi {
	constructor(private http: HttpClient, private mtcDatePipe: MtcDatePipe, private permissions: PermissionService) {}

	getMissionaries(criteria) {
		return this.http.get(
			`${mtcToolsUrl}/missionaries?preMTC=true&inResidence=true&departed=${criteria.inField || false}&query=${
				criteria.name
			}` +
				(criteria.branchDistrict ? `&branchDistrict=${criteria.branchDistrict}` : '') +
				(criteria.scheduledArrivalStart
					? `&arrivalDateStart=${this.mtcDatePipe.transform(criteria.scheduledArrivalStart)}`
					: '') +
				(criteria.scheduledArrivalEnd
					? `&arrivalDateEnd=${this.mtcDatePipe.transform(criteria.scheduledArrivalEnd)}`
					: '') +
				`&allMtcs=${this.permissions.has('MSNY_SEARCH_ALL')}`,
		);
	}
}
