import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { ToastStore } from './toast.store';

@Injectable({
	providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private toaster: ToastStore) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			filter((event) => event instanceof HttpResponse),
			map((event: HttpResponse<any>) => {
				const { body } = event;
				let error;
				if (_.isArray(body) && _.has(body, '[0].error')) {
					error = body[0].error;
					body.shift();
				} else if (_.has(body, 'error')) {
					error = body.error;
					delete body.error;
				}
				if (error) {
					this.toaster.failure(error);
				}
				return event.clone({ body });
			}),
		);
	}
}
