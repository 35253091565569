import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { mtcToolsUrl, travelUrl, mtcUrl, standardsUrl, missionaryUrl, colUrl, messagingUrl } from '@mtc/shared/urls';
import { AppInitService } from '@mtc/shared/info';
import { MTCAuth } from '@mtc/shared/auth';
import { Token } from '@mtc/shared/auth';
import { switchMap } from 'rxjs/operators';
import { ToastStore } from '@mtc/shared/toast';

@Component({
	selector: 'tools-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	loaded = false;

	apiUrls = [mtcToolsUrl, travelUrl, mtcUrl, standardsUrl, missionaryUrl, colUrl, messagingUrl].map(
		(s) => s + '/version',
	);

	constructor(private initService: AppInitService, private toaster: ToastStore, private ngZone: NgZone) {
		MTCAuth.loaded()
			.pipe(switchMap(() => this.initService.load()))
			.subscribe({ next: () => this.handleLoaded(), error: () => this.handleError() });
	}

	handleLoaded() {
		this.ngZone.run(() => (this.loaded = true));
	}

	handleError() {
		this.ngZone.run(() => this.toaster.failure('Authentication Failed.  Please contact the service desk.'));
	}
}
