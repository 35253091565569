import { mtcToolsUrl } from '@mtc/shared/urls';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { computed, observable, action } from 'mobx';
import { flatten } from 'lodash';
import { Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class NavigationStoreService {
	@observable originalLinks = [];
	@observable updateableLinks = [];
	@observable moreIndex = -1;
	@observable showMoreLinks = false;
	favoriteChange = new Subject();
	public areLoaded = false;

	private observable = this.http.get(`${mtcToolsUrl}/links?linkType=DesktopNav`).pipe(
		map((links: any[]) => {
			this.areLoaded = true;
			this.originalLinks = links;
			this.updateableLinks = links;
			return links;
		}),
		shareReplay(1),
	);

	constructor(private http: HttpClient) {
		if (!this.areLoaded) {
			this.load().subscribe();
		}
	}

	load() {
		return this.observable;
	}

	favoriteLink(link) {
		this.favoriteChange.next();
		return this.http.post(`${mtcToolsUrl}/links/favorite`, link);
	}

	updateFavoritesSortOrder(links) {
		return this.http.post(`${mtcToolsUrl}/links/updateOrder`, links);
	}

	postNavAction(url) {
		this.http
			.post(`${mtcToolsUrl}/action?type=Navigation&origin=desktop`, `${url}`, {
				responseType: 'text',
			})
			.subscribe();
	}

	@computed
	get flatLinks() {
		return flatten(
			flatten(this.updateableLinks.map((l) => l.children)).map((l) => (l.childrenCount ? l.children : l)),
		);
	}

	@computed
	get favorites() {
		return this.flatLinks.filter((l) => l.isFavorite).sort((a, b) => a.favoriteSort - b.favoriteSort);
	}

	@computed
	get orderedLinks() {
		return this.favorites.concat(this.flatLinks.filter((l) => !l.isFavorite));
	}

	@computed
	get favoritesThatFit() {
		const more = [{ isMore: true, links: this.favorites.slice(this.moreIndex), url: '/none' }];
		return this.moreIndex !== -1 ? this.favorites.slice(0, this.moreIndex).concat(more) : this.favorites;
	}

	@computed
	get noInternalLinks() {
		return this.flatLinks.every((l) => l.isExternal);
	}

	@computed get firstLink() {
		return this.noInternalLinks ? 'unauthorized' : this.flatLinks[0].url;
	}

	@action
	updateLink(link) {
		this.flatLinks.forEach((l) => {
			if (l.linkId === link.linkId) {
				l.favoriteSort = link.favoriteSort;
				return;
			}
		});
	}
}
