import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations/';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { EnvironmentModule } from '@mtc/shared/environment';
import { AuthInterceptor } from '@mtc/shared/auth';
import { ToastModule, ErrorInterceptor } from '@mtc/shared/toast';
import { CoreModule } from '@mtc/tools/core';
import { IconModule } from '@mtc/shared/icon';
import { DirectivesModule } from '@mtc/shared/directives';

import { AppComponent } from './app.component';
import { Route, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { SharedPermissionModule } from '@mtc/shared/permissions';
import { environment } from '../environments/environment';

const routes: Route[] = [
	{
		path: 'developer/action-log',
		loadChildren: () => import('@mtc/tools/developer/action-log').then((m) => m.ActionLogModule),
	},
	{
		path: 'developer/permissions',
		loadChildren: () => import('@mtc/tools/developer/permissions').then((m) => m.PermissionsModule),
	},
	{
		path: 'developer/server-logs',
		loadChildren: () => import('@mtc/tools/developer/server-log').then((m) => m.ServerLogModule),
	},
	{
		path: 'developer/shared',
		loadChildren: () => import('@mtc/tools/developer/shared-examples').then((m) => m.SharedExamplesModule),
	},
	{
		path: 'developer/site-banner',
		loadChildren: () => import('@mtc/tools/developer/site-banner').then((m) => m.SiteBannerModule),
	},
	{
		path: 'ecclesiastical/branches',
		loadChildren: () => import('@mtc/tools/ecclesiastical/branches').then((m) => m.BranchesModule),
	},
	{
		path: 'ecclesiastical/missionary-reports',
		loadChildren: () =>
			import('@mtc/tools/ecclesiastical/missionary-reports').then((m) => m.MissionaryReportsModule),
	},
	{
		path: 'ecclesiastical/missions',
		loadChildren: () => import('@mtc/tools/ecclesiastical/missions').then((m) => m.MissionsModule),
	},
	{
		path: 'facilities/mailboxes',
		loadChildren: () => import('@mtc/tools/facilities/mailboxes').then((m) => m.MailboxesModule),
	},
	{
		path: 'facilities/rooms',
		loadChildren: () => import('@mtc/tools/facilities/rooms').then((m) => m.RoomsModule),
	},
	{
		path: 'facilities/service-assignments',
		loadChildren: () => import('@mtc/tools/facilities/service-assignments').then((m) => m.ServiceAssignmentsModule),
	},
	{
		path: 'home/dashboard',
		loadChildren: () => import('@mtc/tools/home/dashboard').then((m) => m.DashboardModule),
	},
	{
		path: 'missionary/appointments',
		loadChildren: () => import('@mtc/tools/missionary/appointments').then((m) => m.AppointmentsModule),
	},
	{
		path: 'missionary/arrivals/missionary-assignments',
		loadChildren: () =>
			import('@mtc/tools/missionary/arrivals/missionary-assignments').then((m) => m.MissionaryAssignmentsModule),
	},
	{
		path: 'missionary/arrivals/times',
		loadChildren: () => import('@mtc/tools/missionary/arrivals/times').then((m) => m.ArrivalTimesModule),
	},
	{
		path: 'missionary/assistance',
		loadChildren: () => import('@mtc/tools/missionary/assistance').then((m) => m.AssistanceModule),
	},
	{
		path: 'missionary/check-in-out',
		loadChildren: () => import('@mtc/tools/missionary/check-in-out').then((m) => m.CheckInOutModule),
	},
	{
		path: 'missionary/companionships',
		loadChildren: () => import('@mtc/tools/missionary/companionships').then((m) => m.CompanionshipsModule),
	},
	{
		path: 'missionary/departures/drivers-vehicles',
		loadChildren: () =>
			import('@mtc/tools/missionary/departures/drivers-vehicles').then((m) => m.DriversVehiclesModule),
	},
	{
		path: 'missionary/departures/itineraries',
		loadChildren: () => import('@mtc/tools/missionary/departures/itineraries').then((m) => m.ItinerariesModule),
	},
	{
		path: 'missionary/departures/departure-schedule',
		loadChildren: () => import('@mtc/tools/missionary/departures/schedule').then((m) => m.ScheduleModule),
	},
	{
		path: 'missionary/devices',
		loadChildren: () => import('@mtc/tools/missionary/mobile-devices').then((m) => m.MobileDevicesModule),
	},
	{
		path: 'missionary/notifications/digital-signage',
		loadChildren: () =>
			import('@mtc/tools/missionary/notifications/digital-signage').then((m) => m.DigitalSignageModule),
	},
	{
		path: 'missionary/notifications/send-email',
		loadChildren: () => import('@mtc/tools/missionary/notifications/email').then((m) => m.EmailModule),
	},
	{
		path: 'missionary/profile',
		loadChildren: () => import('@mtc/tools/missionary/profile').then((m) => m.MissionaryProfileModule),
	},
	{
		path: 'missionary/records',
		loadChildren: () => import('@mtc/tools/missionary/records').then((m) => m.RecordsModule),
	},
	{
		path: 'missionary/training-groups',
		loadChildren: () => import('@mtc/tools/missionary/training-groups').then((m) => m.TrainingGroupsModule),
	},
	{
		path: 'missionary/training-schedules',
		loadChildren: () => import('@mtc/tools/missionary/training-schedules').then((m) => m.TrainingSchedulesModule),
	},
	{
		path: 'missionary/zones',
		loadChildren: () => import('@mtc/tools/missionary/zones').then((m) => m.ZonesModule),
	},
	{
		path: 'old-missionary',
		loadChildren: () => import('@mtc/tools/old-missionary').then((m) => m.OldMissionaryModule),
	},
	{
		path: 'orders/ministerial-certificates',
		loadChildren: () =>
			import('@mtc/tools/orders/ministerial-certificates').then((m) => m.MinisterialCertificatesModule),
	},
	{
		path: 'orders/nametag-settings',
		loadChildren: () => import('@mtc/tools/orders/nametag-settings').then((m) => m.NametagSettingsModule),
	},
	{
		path: 'orders/translations',
		loadChildren: () => import('@mtc/tools/orders/translations').then((m) => m.TranslationsModule),
	},
	{
		path: 'search-and-reports/changes',
		loadChildren: () => import('@mtc/tools/search-and-reports/changes').then((m) => m.ChangesModule),
	},
	{
		path: 'search-and-reports/custom-search',
		loadChildren: () => import('@mtc/tools/search-and-reports/custom-search').then((m) => m.CustomSearchModule),
	},
	{
		path: 'search-and-reports/reports',
		loadChildren: () => import('@mtc/tools/search-and-reports/reports').then((m) => m.ReportsModule),
	},
	{
		path: 'search-and-reports/saved-searches',
		loadChildren: () => import('@mtc/tools/search-and-reports/saved-searches').then((m) => m.SavedSearchesModule),
	},
	{
		path: 'system-settings/activities',
		loadChildren: () => import('@mtc/tools/settings/activities').then((m) => m.ActivitiesModule),
	},
	{
		path: 'system-settings/companionship-priorities',
		loadChildren: () =>
			import('@mtc/tools/settings/companionship-priorities').then((m) => m.CompanionshipPrioritiesModule),
	},
	{
		path: 'staff/calendar',
		loadChildren: () => import('@mtc/tools/staff/calendar').then((m) => m.CalendarModule),
	},
	{
		path: 'staff/pay-raises',
		loadChildren: () => import('@mtc/tools/staff/pay-raises').then((m) => m.PayRaisesModule),
	},
	{
		path: 'staff/employee',
		loadChildren: () => import('@mtc/tools/staff/profile').then((m) => m.StaffProfileModule),
	},
	{
		path: 'training/assignments/supervisor',
		loadChildren: () =>
			import('@mtc/tools/training/supervisor-assignments').then((m) => m.SupervisorAssignmentsModule),
	},
	{
		path: 'training/assignments/teacher',
		loadChildren: () => import('@mtc/tools/training/teacher-assignments').then((m) => m.TeacherAssignmentsModule),
	},
	{ path: '**', redirectTo: 'landing', pathMatch: 'full' },
];
@NgModule({
	declarations: [AppComponent],
	imports: [
		SharedPermissionModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		EnvironmentModule,
		ToastModule,
		CoreModule,
		MatProgressBarModule,
		IconModule,
		DirectivesModule,
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			// preloadingStrategy: environment.production ? PreloadAllModules : NoPreloading, TODO get this to work on prod releases
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: {
				disabled: true,
				animation: {
					enterDuration: 0,
					exitDuration: 0,
				},
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
