import * as jwtDecode from 'jwt-decode';

const MINUTES_BEFORE_EXP = 5;

export class TokenGroup {
	public access: Token;
	public id: Token;
	public refresh: Token;

	constructor(tokenJson: any) {
		this.access = new Token(tokenJson.access_token);
		this.id = new Token(tokenJson.id_token);
		this.refresh = new Token(tokenJson.refresh_token, tokenJson.refresh_exp);
	}

	public static refreshExp(days: number): number {
		return Math.round(Date.now() / 1000) + days * 24 * 60 * 60;
	}
}

export class Token {
	public decoded: any = null;

	constructor(public value: string, public exp: number = 0) {}

	public isValid(minsAgo: number = 0): boolean {
		this.decode(); // decode jwt if needed
		minsAgo += MINUTES_BEFORE_EXP; // allow a little time buffer
		const cutoff = Math.round(Date.now() / 1000) - minsAgo * 60;
		const exp = this.decoded ? this.decoded.exp : this.exp ? this.exp : cutoff + 1;
		const valid = exp > cutoff;
		return valid;
	}

	public decode() {
		if (!this.exp && this.value && !this.decoded) {
			try {
				this.decoded = jwtDecode(this.value);
			} catch (error) {
				// TODO: error handling
			}
		}
	}
}
