import { Component, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { DialogTemplateConfig } from '../../models/dialog-template.config';
import { MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { OnChange } from 'property-watch-decorator';
import { isFunction } from 'lodash';

@Component({
	selector: 'mtc-dialog-template',
	templateUrl: './dialog-template.component.html',
	styleUrls: ['./dialog-template.component.scss'],
})
export class DialogTemplateComponent implements OnDestroy {
	@ViewChild('content', { static: false }) content: ElementRef;
	@ViewChild('dialog', { static: false }) dialog;

	@OnChange(function() {
		this.dialogConfig = Object.assign(cloneDeep(this.defaultConfig), this.config);
		this.itemNames = Object.keys(this.dialogConfig.items || []);
		this.usingDatePicker = this.config.hasOwnProperty('effectiveDate') && !this.config.hideEffectiveDate;
		if (this.dialogConfig.name || this.dialogConfig.title) {
			this.dialogService.postDialogAction(this.dialogConfig.name || this.dialogConfig.title);
		}
	})
	@Input()
	config;

	defaultConfig: DialogTemplateConfig = {
		title: '',
		formId: 'formId',
		confirmText: 'save',
		cancelText: 'cancel',
	};
	dialogConfig;
	usingDatePicker: boolean;
	itemNames;

	constructor(public dialogService: DialogService, public dialogRef: MatDialogRef<any>) {}

	ngOnDestroy() {
		this.dialogService.dialogLoading = false;
	}

	cancel() {
		if (this.dialogConfig.cancelClick) {
			this.dialogConfig.cancelClick();
		} else {
			this.dialogRef.close();
		}
	}

	close() {
		if (this.dialogConfig.closeClick) {
			this.dialogConfig.closeClick();
		} else {
			this.dialogRef.close();
		}
	}

	confirm() {
		if (this.dialogConfig.confirmClick) {
			this.dialogConfig.confirmClick();
		}
	}

	effectiveDateChange(event) {
		if (this.dialogConfig.effectiveDateChange) {
			this.dialogConfig.effectiveDateChange(event);
		}
	}

	get cancelText() {
		if (isFunction(this.dialogConfig.cancelText)) {
			return this.dialogConfig.cancelText();
		}
		return this.dialogConfig.cancelText;
	}

	get confirmText() {
		if (isFunction(this.dialogConfig.confirmText)) {
			return this.dialogConfig.confirmText();
		}
		return this.dialogConfig.confirmText;
	}
}
