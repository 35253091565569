<div class="autocomplete">
	<span class="mtc-autocomplete-input-container">
		<mat-form-field (click)="setFocus(true)">
			<input matInput #overlayHost cy-elm="autocomplete-input" matInput type="text" class="mtc-autocomplete-input" (keyup)="onKeyUp($event)" [placeholder]="placeholder + (required ? ' *' : '')"
			 (focusout)="setFocus(false)" [formControl]="childControl" [disabled]="disabled">
			<span class="icon" [class.active]="showDropDown" matSuffix>
				<i class="material-icons search" [class.hidden]="childControl.value && childControl.enabled || !isSearchField">search</i>
				<i cy-elm="autocomplete-clear" class="material-icons" [class.hidden]="!(childControl.value && childControl.enabled)" (click)="remove()">clear</i>
			</span>
			<mat-error>
				<span *ngIf="errors?.required" cy-elm="auto-required">Required Field</span>
				<span *ngIf="errors?.invalidInput && !errors?.required" cy-elm="auto-invalid">Invalid Input</span>
			</mat-error>
		</mat-form-field>
	</span>
	<mtc-overlay [toggleOverlay]="showDropDown && searchResults.length > 0" (toggleOverlayChange)="showDropDown = false" [connectTo]="overlayHost">
		<mtc-autocomplete-dropdown [keyPress]="keyPress" [items]="searchResults" [displayBy]="displayBy"
		(currentItemIndexChange)="chooseItem(searchResults[$event])"></mtc-autocomplete-dropdown>
	</mtc-overlay>
</div>
