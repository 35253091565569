import { Component, OnInit, Input } from '@angular/core';
import { OnChange } from 'property-watch-decorator';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'tools-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
	@Input() title = '';
	@Input() hideLoadingBar = false;
	@Input() showLoadingImage: 'always' | 'first' | 'never' = 'first';
	@Input() tabPage = false;
	@Input() backLink;
	@OnChange(function() {
		this.loadingCount++;
	})
	@Input()
	loading = false;
	loadingCount = -1;

	constructor(private router: Router, private titleService: Title) {}

	ngOnInit() {
		if (this.title) {
			this.titleService.setTitle(this.title + ' - MTC Tools');
		}
	}

	isLoadingImageVisible() {
		switch (this.showLoadingImage) {
			case 'always':
				return this.loading;
			case 'never':
				return false;
			case 'first':
				return this.loading && !this.loadingCount;
		}
	}

	back() {
		this.router.navigate([this.backLink]);
	}
}
