export const PROD_DEFAULTS = {
	authDomain: 'auth.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: true,
	testing: false,
};

export const BETA_DEFAULTS = {
	authDomain: 'auth.dev.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: true,
	testing: false,
};

export const TEST_DEFAULTS = {
	authDomain: 'auth.dev.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: false,
	testing: false,
};

export const DEV_DEFAULTS = {
	authDomain: 'auth.dev.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: false,
	testing: false,
};

export const CYPRESS_DEFAULTS = {
	authDomain: 'auth.dev.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: false,
	testing: true,
};

export const LOCAL_DEFAULTS = {
	authDomain: 'auth.dev.tools.mtc.byu.edu',
	authProvider: 'Church-Account',
	refreshDays: 1,
	production: false,
	testing: false,
};
