import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OmniTableColumn } from '../../models/column.model';
import { DialogTemplateConfig } from '@mtc/shared/dialog';
import { cloneDeep, isFunction } from 'lodash';

@Component({
	selector: 'mtc-manage-columns',
	templateUrl: './manage-columns.dialog.html',
	styleUrls: ['./manage-columns.dialog.scss'],
})
export class ManageColumnsDialog implements OnInit {
	columns: OmniTableColumn[];
	saveAsDefault = false;

	dialogTemplateConfig: DialogTemplateConfig = {
		title: 'Manage Columns',
		confirmClick: () => this.save(),
		formId: 'modal',
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<ManageColumnsDialog>) {}

	ngOnInit() {
		this.columns = cloneDeep(this.data.columns);
	}

	change(setting, column) {
		if (setting === 'fixed' && !column.fixed) {
			column.visible = true;
		}
		if (setting === 'visible' && column.visible) {
			column.fixed = false;
		}
		column[setting] = !column[setting];
		this.columns = this.columns.filter((c) => c.visible).concat(this.columns.filter((c) => !c.visible));
	}

	save() {
		this.dialog.close({
			columns: this.columns,
			saveAsDefault: this.saveAsDefault,
		});
	}

	onItemDrop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
		const nextColumn = this.columns[event.currentIndex + 1] || { fixed: false, visible: false };
		this.columns[event.currentIndex].visible = nextColumn.visible;
	}
}
