import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dialogTemplateHeight',
})
export class DialogTemplateHeightPipe implements PipeTransform {
	transform(value): string {
		return value ? value.clientHeight + 'px' : '100%';
	}
}
