import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OnChange } from 'property-watch-decorator';

@Component({
	selector: 'mtc-mini-clock',
	templateUrl: './mini-clock.component.html',
	styleUrls: ['./mini-clock.component.scss'],
})
export class MiniClockComponent implements OnInit {
	@Input() type: 'hours' | 'minutes' = 'hours';
	@Input() autoSwitch = true;
	@Input() timezone;
	@Output() timeChange = new EventEmitter();
	@OnChange(function() {
		if (this.time) {
			this.minutes = this.time.minutes();
			this.hours = this.time.hours() > 12 ? this.time.hours() - 12 : this.time.hours();
			this.meridian = this.time.hours() >= 12 ? 'PM' : 'AM';
		}
	})
	@Input()
	time;

	minutes = 0;
	hours = 0;
	meridian = '';

	hoverAngle: number;
	clockNumbers: number[] = Array.from({ length: 60 }, (v, i) => i + 1);

	constructor() {}

	ngOnInit() {}

	timeChangeLocal({ clientX, clientY, currentTarget, type }) {
		const { clientWidth, clientHeight } = currentTarget;
		const { left, top } = currentTarget.getClientRects()[0];

		this.hoverAngle = this.calculateDegreeFromClick(clientX, clientY, clientWidth, clientHeight, left, top);
		if (type === 'click') {
			if (this.type === 'hours') {
				this.time.hours(this.calculateHoursFromDegree(this.hoverAngle));
			} else {
				this.time.minutes(this.calculateMinutesFromDegree(this.hoverAngle));
			}
			this.timeChange.emit(this.time);
		}

		if (['mouseup', 'click'].includes(type) && this.type === 'hours' && this.autoSwitch) {
			this.type = 'minutes';
		}
	}

	toggle(newMeridian) {
		if (newMeridian !== this.meridian) {
			const change = newMeridian === 'AM' ? -12 : 12;
			this.time.hours(this.time.hours() + change);
			this.meridian = newMeridian;
			this.timeChange.emit(this.time);
		}
	}

	calculateDegreeFromClick(x: number, y: number, width: number, height: number, left: number, top: number): number {
		x = x - (width / 2 + left);
		y = y - (height / 2 + top);

		let degrees = Math.round(Math.atan2(y, x) * (180 / Math.PI)) + 90;
		if (degrees < 0) {
			degrees += 360;
		}
		return degrees;
	}

	calculateHoursFromDegree(degree: number): number {
		const additive = this.hours < 12 ? 0 : 12;
		let hours = Math.round(degree / 30);
		if (hours === 12) {
			hours = 0;
		}
		return hours + additive;
	}

	calculateMinutesFromDegree(degree: number): number {
		let minutes = Math.round(degree / 6);
		if (minutes === 60) {
			minutes = 0;
		}
		return minutes;
	}
}
