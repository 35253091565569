import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'mtc-missionary-drag-card',
	templateUrl: './missionary-drag-card.component.html',
	styleUrls: ['./missionary-drag-card.component.scss'],
})
export class MissionaryDragCardComponent implements OnInit {
	@Input() missionary: any;
	@Input() config: any = {};
	@Input() dragging;

	constructor() {}

	ngOnInit() {}
}
