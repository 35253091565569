import { TokenGroup, Token } from './token';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthConfig } from '../config.model';
import { Platform } from './platform';
import { Machine, State, MachineDefinition } from './machine';
import { Utils } from './utils';

const MINUTES_CHECK_EXP = 5 * 60 * 1000;

export class OAuth {
	private config: AuthConfig;
	private platform: Platform;
	private params: any = {};
	private machine: Machine;
	private accessToken = new BehaviorSubject<Token>(null);
	private event = new BehaviorSubject<string>(null);

	constructor(config: AuthConfig, platform: Platform) {
		this.config = config;
		this.platform = platform;
		const params = new URL(window.location.href).searchParams; // get query params from url
		params.forEach((value, key) => (this.params[key] = value)); // save query params in a map
		this.platform.get('state').then((stateString) => {
			const state = State.fromString(stateString); // get current state out of platform storage
			this.machine = new Machine(this.platform, this.machineDefinition(), state);
			this.machine.init(); // initialize the current state
			setInterval(() => {
				this.machine.transition(new State('checkExpired', null));
			}, MINUTES_CHECK_EXP);
		});
	}

	public getAccessToken(): Observable<Token> {
		return this.accessToken.asObservable();
	}

	public getEvent(): Observable<any> {
		return this.event.asObservable();
	}

	public dispatch(type: string) {
		setTimeout(() => this.machine.transition(new State(type, null)));
	}

	private machineDefinition(): MachineDefinition {
		return {
			init: { function: () => this.machine.transition(new State('login')), validTransitions: ['login'] },
			login: { function: () => this.handleLogin(), validTransitions: ['checkReply'] },
			checkReply: { function: (data) => this.handleCheckReply(data), validTransitions: ['postCode'] },
			postCode: { function: (data) => this.handlePostCode(data), validTransitions: ['checkExpired'] },
			checkExpired: {
				function: (data) => this.handleCheckExpired(data),
				validTransitions: ['refreshToken', 'logout', 'checkExpired'],
			},
			refreshToken: {
				function: (data) => this.handleRefreshToken(data),
				validTransitions: ['checkExpired', 'logout'],
			},
			logout: { function: () => this.handleLogout(), validTransitions: ['init'] },
		};
	}

	private handleLogin() {
		const state = Utils.base64URLEncode(Utils.random(32));
		const pkce = Utils.base64URLEncode(Utils.random(128));
		const challenge = Utils.base64URLEncode(Utils.sha256(pkce));
		const url =
			`https://${this.config.domain}/oauth2/authorize?` +
			`identity_provider=${this.config.provider}&` +
			`scope=${this.config.scopes.join(' ').replace(/\s/g, '%20')}&` +
			`redirect_uri=${this.config.redirectUri}&` +
			`client_id=${this.config.clientId}&` +
			`state=${state}&` +
			`code_challenge=${challenge}&` +
			`code_challenge_method=S256&` +
			`response_type=code`;
		this.machine.transition(new State('checkReply', { state, pkce, url: window.location.href }), true);
		this.platform.open(url);
	}

	private handleCheckReply(data: { state: string; pkce: string; url: string }) {
		if (this.params.error && this.params.error_description) {
			this.accessToken.error(this.params.error_description);
		} else {
			if (this.params.code && data.state === this.params.state) {
				this.machine.transition(
					new State('postCode', { code: this.params.code, pkce: data.pkce, url: data.url }),
				);
			} else {
				this.machine.transition(new State('init', {}));
			}
		}
	}

	private handlePostCode(data: { code: string; pkce: string; url: string }) {
		const url = `https://${this.config.domain}/oauth2/token`;
		const body =
			`identity_provider=${this.config.provider}&` +
			`redirect_uri=${this.config.redirectUri}&` +
			`client_id=${this.config.clientId}&` +
			`code_verifier=${data.pkce}&` +
			`grant_type=authorization_code&` +
			`code=${data.code}`;
		const headers = {
			Accept: 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
		};
		fetch(url, { method: 'post', headers, body })
			.then((response) => {
				if (!response.ok) {
					return null; // trigger error handling below
				}
				return response.json();
			})
			.then((newTokens) => {
				if (newTokens) {
					if (newTokens.error) {
						// TODO: on all of these error cases, do we need to transition machine to an "error" state?
						this.accessToken.error(newTokens.error);
					} else {
						newTokens.refresh_exp = TokenGroup.refreshExp(this.config.refreshDays);
						this.machine.transition(new State('checkExpired', { tokens: newTokens }), true);
						this.platform.open(data.url); // take them back to the url they started from
					}
				} else {
					this.accessToken.error('No tokens found');
				}
			})
			.catch((error) => {
				this.accessToken.error('token post call failed');
			});
	}

	private handleCheckExpired(data: { tokens: any }) {
		if (data.tokens) {
			const tokens = new TokenGroup(data.tokens);
			if (tokens.access && tokens.access.isValid()) {
				this.accessToken.next(tokens.access); // notify listeners of new valid access token
				if (
					this.config.notifyBeforeExp &&
					tokens.refresh &&
					!tokens.refresh.isValid(this.config.notifyBeforeExp)
				) {
					this.event.next('refresh_expiring');
				}
			} else {
				if (tokens.refresh && tokens.refresh.isValid()) {
					this.machine.transition(new State('refreshToken', data));
				} else {
					this.machine.transition(new State('logout', {}));
				}
			}
		}
	}

	private handleRefreshToken(data: { tokens: any }) {
		if (data.tokens) {
			const tokens = new TokenGroup(data.tokens);
			if (tokens.refresh && tokens.refresh.value) {
				const url = `https://${this.config.domain}/oauth2/token`;
				const body =
					`identity_provider=${this.config.provider}&` +
					`redirect_uri=${this.config.redirectUri}&` +
					`client_id=${this.config.clientId}&` +
					`grant_type=refresh_token&` +
					`refresh_token=${tokens.refresh.value}`;
				const headers = {
					Accept: 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded',
				};
				fetch(url, { method: 'post', headers, body })
					.then((response) => {
						// TODO: how do they report back expired refresh?
						if (!response.ok) {
							return null; // trigger error handling below
						}
						return response.json();
					})
					.then((newTokens) => {
						if (newTokens) {
							if (newTokens.error) {
								this.accessToken.error(newTokens.error);
							} else {
								// new tokens won't contain a refresh token, so preserve from old
								const mergedTokens = { ...data.tokens, ...newTokens };
								this.machine.transition(new State('checkExpired', { tokens: mergedTokens }));
							}
						} else {
							// TODO: on all of these error cases, do we need to transition machine to an "error" state?
							this.accessToken.error('Error getting refresh token');
						}
					})
					.catch((error) => {
						this.accessToken.error(error);
					});
			}
		}
	}

	private handleLogout() {
		// TODO: get logout really working - check into flow from church or stick in iframe or something
		const url =
			`https://${this.config.domain}/logout?` +
			`identity_provider=${this.config.provider}&` +
			`logout_uri=${this.config.logoutUri || this.config.redirectUri}&` +
			`client_id=${this.config.clientId}`;
		this.machine.transition(new State('init', {}), true);
		this.platform.open(url);
		//TODO actually logout instead of just refreshing page.
		//Options
		//	1) Figure out how to logout of lds.org when we logout
		//	2) Redirect to a page that says "We've logged you out of tools, but not of lds.  Go here to logout of lds"
	}
}
