import { Platform } from './platform';
import { AuthConfig } from '../config.model';

export class MobilePlatform implements Platform {
	private config: any;

	constructor(config: AuthConfig) {
		this.config = config;
	}

	// storage
	public get(key: string): Promise<string> {
		return this.config.Storage.get({ key });
	}

	public set(key: string, value: string) {
		this.config.Storage.set({ key, value });
	}

	public remove(key: string) {
		this.config.Storage.remove({ key });
	}

	// browser
	public open(url: string) {
		this.config.Browser.open({ url });
	}
}
