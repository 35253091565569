import { Injectable } from '@angular/core';
import { observable, action } from 'mobx';
import { Subject } from 'rxjs';
import { env } from '@mtc/shared/urls';

@Injectable({
	providedIn: 'root',
})
export class ToastStore {
	@observable type: 'success' | 'failure' | 'alert' | '' = '';
	@observable message = '';
	@observable closable = true;
	private bannerClose: Subject<Boolean> = new Subject();

	constructor() {}

	@action
	clearMessage() {
		this.message = '';
		this.type = '';
	}

	@action
	closeMessage() {
		this.clearMessage();
		this.bannerClose.next();
		this.bannerClose.complete();
	}

	success(message = '', closable = true) {
		this.displayToast('success', message, closable, true);
	}

	failure(message = '', closable = true) {
		this.displayToast('failure', message, closable);
	}

	alert(message = '', closable = true) {
		this.displayToast('alert', message, closable);
		this.bannerClose.next();
		this.bannerClose.complete();
		this.bannerClose = new Subject();
		return this.bannerClose.asObservable();
	}

	@action
	displayToast(type: 'success' | 'alert' | 'failure', message = '', closable = true, autoClose = env === 'cypress') {
		this.clearMessage();
		this.type = type;
		this.message = message;
		this.closable = closable;
		if (autoClose) {
			setTimeout(() => this.closeMessage(), 5000);
		}
	}
}
