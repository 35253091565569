import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTabsComponent } from './components/dynamic-tabs/dynamic-tabs.component';
import { StaticTabsComponent } from './components/static-tabs/static-tabs.component';
import { PipesModule } from '@mtc/shared/pipes';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MobxAngularModule } from 'mobx-angular';

@NgModule({
	imports: [CommonModule, PipesModule, MatProgressBarModule, MatIconModule, MobxAngularModule],
	declarations: [DynamicTabsComponent, StaticTabsComponent],
	exports: [DynamicTabsComponent, StaticTabsComponent],
})
export class TabsModule {}
