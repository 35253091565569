import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MTCAuth } from './auth';
import { mtcToolsUrl } from '@mtc/shared/urls';
import { AuthUser } from './user.model';

@Injectable({
	providedIn: 'root',
})
export class UserService implements AuthUser {
	ldsId: string;
	preferredName: string;
	email?: string;
	mtc?: string;
	mtcId?: string;

	constructor(private http: HttpClient) {
		this.http.get<AuthUser>(`${mtcToolsUrl}/user`).subscribe((user) => {
			Object.keys(user).forEach((prop) => (this[prop] = user[prop]));
		});
	}

	public invalidate() {
		this.http
			.get(
				`https://auth.mtc.byu.edu/oauth2/tokeninfo?access_token=${
					MTCAuth.getToken().accessToken
				}&invalidate=true`,
			)
			.subscribe(() => {
				location.reload();
			});
	}
}
