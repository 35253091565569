import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MtcInfoService, ConfigService } from '@mtc/shared/info';
import { PermissionService } from '@mtc/shared/permissions';
import { AuthUser, MTCAuth, UserService } from '@mtc/shared/auth';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ManageFavoritesDialog } from '../../dialogs/manage-favorites/manage-favorites.dialog';

@Component({
	selector: 'tools-end-menu',
	templateUrl: './end-menu.component.html',
	styleUrls: ['./end-menu.component.scss'],
})
export class EndMenuComponent implements OnInit {
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@Input() showAllMtcs;
	@Output() showAllMtcsChange: EventEmitter<any> = new EventEmitter<any>();

	selectedMTC = '';
	version = '';
	mtcs = [];

	constructor(
		public permissions: PermissionService,
		public mtcInfo: MtcInfoService,
		public configService: ConfigService,
		public user: UserService,
		private http: HttpClient,
		private dialog: MatDialog,
	) {}

	ngOnInit() {
		this.configService.getMtc().subscribe((mtc: any) => {
			this.selectedMTC = mtc.unitName + ' MTC';

			this.mtcs = this.mtcInfo.info.mtcs.filter((m) => m.unitName !== mtc.unitName);
		});
		this.http.get('assets/version.json?v=' + Date.now()).subscribe((version: any) => {
			this.version = version.version;
		});
	}

	logout() {
		this.close.emit();
		MTCAuth.logout();
	}

	updateUserMtc(mtc) {
		this.configService.setMtcId(mtc.unitNum).subscribe(() => {
			this.user.invalidate();
		});
		this.showAllMtcs = false;
		this.close.emit();
	}

	showMtcs() {
		this.showAllMtcs = !this.showAllMtcs;
		this.showAllMtcsChange.emit(this.showAllMtcs);
	}

	openManageFavoritesModal() {
		this.close.emit();
		return this.dialog.open(ManageFavoritesDialog, { width: '532px', disableClose: true });
	}
}
