import { Component, Input, Output, EventEmitter, Self, Optional, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { MtcDatePipe } from '@mtc/shared/pipes';
import { get, difference, isArray, isString } from 'lodash';
import { OnChange } from 'property-watch-decorator';
import { env } from '@mtc/shared/urls';

@Component({
	selector: 'mtc-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	providers: [],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
	@ViewChild('input', { static: false }) input: ElementRef;
	@Output() dateChange = new EventEmitter();
	@Output() endDateChange = new EventEmitter();

	/** This is the text that is displayed in the date picker before a date is selected */
	@Input() placeholder: String = '';
	/** Whether or not to display a search icon instead of the calendar icon. Also adds X icon to easily clear the field */
	@Input() isSearchField = false;
	/** When equal to "freeRange", the date selector allows the user to pick a range of dates instead of a single date */
	@Input() rangePickerType: 'freeRange' | '';
	/** Whether or not to display the year toggle when selecting a date */
	@Input() yearPicker: boolean;
	/** Controls whether the css class 'error' is applied to the input field */
	@Input() error;
	/** Whether or not the date picker is inside an omni table */
	@Input() inOmniTable = false;
	/** Controls hovering when date picker is inside an omni table */
	@Input() omniHover = false;
	/** Adds a hint underneath the date picker */
	@Input() hint;

	/** Whether or not the date picker should be in a disabled state */
	@OnChange(function(value) {
		this.childControl[value ? 'disable' : 'enable']();
	})
	@Input()
	disabled;

	/** Validates user input to ensure the selected date is after the given moment */
	@OnChange(function(value) {
		if (value) {
			this.afterDate = value === 'today' ? moment() : moment(value);
		}
	})
	@Input()
	isAfter;

	/** Validates user input to ensure the selected date is before the given moment */
	@OnChange(function(value) {
		if (value) {
			this.beforeDate = value === 'today' ? moment().subtract(1, 'd') : moment(value);
		}
	})
	@Input()
	isBefore;

	/** When selecting a range of dates this date is the second date that marks the end of the range */
	@OnChange(function(value) {
		if (value) {
			this.rangePickerType = 'freeRange';
			this.internalEndDate = moment(value).isValid ? moment(value) : null;
			this.childControl.setValue(this.mtcDate.transform(this.internalStartDate, this.internalEndDate));
		} else {
			this.internalEndDate = null;
		}
	})
	@Input()
	endDate;

	/** The date that is currently selected in the date picker */
	@OnChange(function(value) {
		value = value === 'today' ? moment() : value;

		if ((moment(value).isValid() && !moment(value).isSame(this.internalStartDate)) || !value) {
			this.internalStartDate = value ? moment(value) : null;
			this.childControl.setValue(this.mtcDate.transform(this.internalStartDate, this.internalEndDate));
		}
	})
	@Input()
	date;

	dateFormat = 'DD MMM YYYY';
	afterDate;
	beforeDate;
	required = false;
	internalStartDate: any;
	internalEndDate: any;
	showCalendar = false;
	offPage = false;
	childControl = new FormControl('');
	commonErrors = ['required', 'invalidFormat', 'isBefore', 'isAfter', 'endBeforeStart'];

	get parentControl() {
		return get(this.controlDir, 'control');
	}
	get exoticError() {
		return (
			this.childControl.errors &&
			this.childControl.errors[difference(Object.keys(this.childControl.errors), this.commonErrors)[0]]
		);
	}
	formChange: any = () => {};
	onTouched = () => {};
	@Input()
	setDisabled = (date) => {
		return (this.afterDate && date.isBefore(this.afterDate)) || (this.beforeDate && date.isAfter(this.beforeDate));
	};

	constructor(
		public mtcDate: MtcDatePipe,
		@Self()
		@Optional()
		public controlDir: NgControl,
	) {
		if (controlDir) {
			this.controlDir.valueAccessor = this;
		}
	}

	ngOnInit() {
		let validator: any = () => null;
		if (this.controlDir) {
			validator = this.parentControl.validator || this.controlDir.validator || validator;
			this.required = (validator('') || { required: false }).required;
			this.parentControl.setValidators([validator, this.validate()]);
			this.parentControl.updateValueAndValidity();
		}
		this.childControl.setValidators([validator.bind(this.parentControl), this.validate()]);
		this.childControl.updateValueAndValidity();
	}

	parseStringDate(date) {
		let startDate = date.split(' - ')[0];
		const endDate = date.split(' - ')[1];
		if ((startDate.length === 2 || startDate.length === 6) && endDate) {
			startDate += endDate.substring(startDate.length);
		}
		return {
			startDate: moment(startDate, this.dateFormat, true),
			startDateSelected: date.length,
			endDate: moment(endDate, this.dateFormat, true),
			endDateSelected: date.includes('-'),
		};
	}

	changeFirstDate(newDate) {
		this.date = newDate;
		if (!this.rangePickerType) {
			this.closeCalendar();
		}
	}

	emitDates() {
		const startEmitDate = this.internalStartDate ? this.internalStartDate.toDate() : null;
		const endEmitDate = this.internalEndDate ? this.internalEndDate.toDate() : null;
		this.dateChange.emit(startEmitDate);
		this.endDateChange.emit(endEmitDate);
		this.formChange(this.rangePickerType ? [startEmitDate, endEmitDate] : startEmitDate);
		this.childControl.updateValueAndValidity();
		if (this.parentControl) {
			this.parentControl.updateValueAndValidity();
		}
	}

	changeDisplayDate() {
		const dates = this.parseStringDate(this.childControl.value);
		if (dates.startDate.isValid()) {
			if (dates.endDateSelected && dates.endDate.isValid()) {
				this.internalEndDate = dates.endDate;
			}
			if (!dates.endDateSelected || (dates.endDateSelected && dates.endDate.isValid())) {
				if (this.rangePickerType && !dates.endDateSelected) {
					this.internalEndDate = dates.startDate;
				}
				this.internalStartDate = dates.startDate;
			}
			this.emitDates();
		} else if (!dates.startDateSelected) {
			this.internalStartDate = this.internalEndDate = null;
			this.emitDates();
		} else {
			this.parentControl.updateValueAndValidity({ emitEvent: false });
		}
	}

	openCalendar() {
		if (this.childControl.enabled) {
			this.showCalendar = true;
			if (!this.inOmniTable) {
				this.input.nativeElement.focus();
			}
			if (env === 'cypress') {
				setTimeout(() => {
					this.closeCalendar();
				}, 2000);
			}
		}
	}

	closeCalendar() {
		if (this.showCalendar) {
			if (this.rangePickerType && !this.internalEndDate) {
				this.endDate = this.internalStartDate;
			}
			this.showCalendar = false;
			this.emitDates();
		}
	}

	writeValue(value: any) {
		if (!value) {
			if (this.rangePickerType) {
				this.endDate = null;
			}
			this.date = null;
		} else if (isArray(value)) {
			this.endDate = value[1];
			this.date = value[0];
		} else if (isString(value) && value) {
			this.childControl.setValue(moment(value).format(this.dateFormat));
			this.changeDisplayDate();
		} else if (moment.isMoment(value) || moment.isDate(value)) {
			this.date = moment(value);
		}
	}

	registerOnChange(formChange?: (value: any) => void) {
		this.formChange = formChange;
	}

	registerOnTouched(onTouched) {
		this.onTouched = onTouched;
	}

	validate() {
		return (control) => {
			const dates = this.parseStringDate(this.childControl.value);
			if (
				this.required &&
				this.rangePickerType === 'freeRange' &&
				(!dates.startDate.isValid() || !dates.endDate.isValid())
			) {
				return { required: true };
			} else if (
				this.childControl.value &&
				(!dates.startDate.isValid() || (dates.endDateSelected && !dates.endDate.isValid()))
			) {
				return { invalidFormat: true };
			} else if (dates.endDate && dates.endDate.isBefore(dates.startDate)) {
				return { endBeforeStart: true };
			} else if (this.beforeDate && dates.startDate.isAfter(this.beforeDate)) {
				return { isBefore: true };
			} else if (this.afterDate && dates.startDate.isBefore(this.afterDate)) {
				return { isAfter: true };
			}
		};
	}
}
