<div class="screen">
	<img [src]="imageUrl" class="image">
	<div class="content">
		<h1>{{ header }}</h1>
		<p [innerHTML]="message"></p>
		<div>
			<button *ngIf="buttonText" [class]="buttonClass" (click)="onButtonClick()" [attr.cy-elm]="buttonText | kebab">
				{{ buttonText }}
			</button>
		</div>
	</div>
</div>
