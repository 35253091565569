<div class="date-picker" #datePicker>
	<div *ngIf="inOmniTable" [class.omni-hover]="omniHover" [class.omni-active]="showCalendar" (click)="openCalendar()">
		<span>{{date | mtcDate}}</span>
		<mat-icon class="icon" *ngIf="omniHover || showCalendar" cy-elm="date-picker-icon">{{rangePickerType ? 'date_range' : 'event'}}</mat-icon>
	</div>
	<mat-form-field (click)="openCalendar()" *ngIf="!inOmniTable">
		<input cy-elm="date-picker-input" matInput [readonly]="childControl.disabled" type="text" [class.disabled]="disabled" class="clickable noselect" [class.error]="error"
			[placeholder]="placeholder + (required ? ' *' : '')" [formControl]="childControl" (input)="changeDisplayDate()" #input
			(keyup.enter)="openCalendar()" cy-elm="date-picker-input">
		<mat-icon class="icon" *ngIf="!isSearchField" [class.error]="this.childControl.touched && childControl.errors" matSuffix cy-elm="date-picker-icon">{{rangePickerType ? 'date_range' : 'event'}}</mat-icon>
		<mat-icon class="icon search" matSuffix *ngIf="isSearchField && !childControl.value" cy-elm="date-picker-icon">search</mat-icon>
        <mat-icon class="icon search" matSuffix *ngIf="isSearchField && childControl.value" (click)="childControl.setValue(''); changeDisplayDate()" cy-elm="date-picker-icon">clear</mat-icon>
        <mat-hint *ngIf="hint">{{hint}}</mat-hint>
		<mat-error *ngIf="childControl.errors?.required" cy-elm="date-picker-required">Required Field</mat-error>
		<mat-error *ngIf="childControl.errors?.invalidFormat" cy-elm="date-picker-invalid">{{rangePickerType ? 'DD - DD MMM YYYY' : dateFormat}}</mat-error>
		<mat-error *ngIf="childControl.errors?.endBeforeStart" cy-elm="date-picker-invalid-range">Please Select a Valid Date Range</mat-error>
		<mat-error *ngIf="childControl.errors?.isBefore" cy-elm="date-picker-before">Date must be before {{beforeDate | mtcDate}}</mat-error>
		<mat-error *ngIf="childControl.errors?.isAfter" cy-elm="date-picker-after">Date must be after {{afterDate | mtcDate}}</mat-error>
        <mat-error *ngIf="exoticError" cy-elm="date-picker-exotic-error">{{exoticError}}</mat-error>
	</mat-form-field>
	<mtc-overlay [(toggleOverlay)]="showCalendar" [connectTo]="datePicker" [offset]="inOmniTable ? 0 : -20">
		<mtc-mini-calendar (clickOutside)="closeCalendar()" [rangePickerType]="rangePickerType" [isAfter]="afterDate" [isBefore]="beforeDate" [setDisabled]="setDisabled"
			[secondDate]="internalEndDate" [date]="internalStartDate" (offPage)="offPage = $event" [yearPicker]="yearPicker" (changeFirstDate)="changeFirstDate($event)"
			(changeSecondDate)="rangePickerType ? endDate = $event || null : ''" (calendarClose)="closeCalendar()"></mtc-mini-calendar>
	</mtc-overlay>
</div>
