import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isNil } from 'lodash';
import { MtcDatePipe } from './mtc-date.pipe';
import { MtcTimePipe } from './mtc-time.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { MtcDateTimePipe } from './mtc-date-time.pipe';

@Pipe({
	name: 'dynamic',
})
export class DynamicPipe implements PipeTransform {
	pipes = {
		mtcDate: new MtcDatePipe(),
		mtcTime: new MtcTimePipe(),
		yesNo: new YesNoPipe(),
		mtcDateTime: new MtcDateTimePipe(),
		date: new DatePipe('en-US'),
	};

	constructor() {}

	transform(value: any, pipeName: any, ...pipeParams: any): any {
		if (isNil(value)) {
			return '';
		} else if (this.pipes[pipeName]) {
			return this.pipes[pipeName].transform(value, ...pipeParams);
		} else {
			return value;
		}
	}
}
