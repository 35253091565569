export const env: 'local' | 'cypress' | 'dev' | 'test' | 'stage' | 'support' | 'beta' | 'prod' = 'dev';
export const url = 'https://devapplications.mtc.byu.edu';
export const mtcToolsUrl = `${url}/mtctools/v1`;
export const launchpadUrl = `${url}/launchpad/v1`;
export const travelUrl = `${url}/travel/v2`;
export const mtcUrl = `${url}/mtc/v2`;
export const standardsUrl = `${url}/standards/v2`;
export const missionaryUrl = `${url}/missionary/v2`;
export const noteUrl = `${url}/note/v1`;
export const colUrl = `${url}/col/v2`;
export const userUrl = `${url}/user/v2`;
export const messagingUrl = `https://app.mtc.byu.edu/messaging/v2`;
