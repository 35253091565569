import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateConfig } from '@mtc/shared/dialog';
import { OmniTableColumn, OmniTableConfig } from '@mtc/shared/omni-table';
import { filter } from 'lodash';

export interface AddMissionaryConfig {
	excludedMissionaries?: any[];
	groupDate?: Date;
	preMTC?: boolean;
	inResidence?: boolean;
	inField?: boolean;
	effectiveDate: Date;
}

@Component({
	selector: 'mtc-add-missionary-dialog',
	templateUrl: './add-missionary.dialog.html',
	styleUrls: ['./add-missionary.dialog.scss'],
})
export class AddMissionaryDialog implements OnInit {
	dialogTemplateConfig: DialogTemplateConfig;
	missionaries = [];
	columns: OmniTableColumn[] = [
		{ title: 'ID', attr: 'missionaryId' },
		{ title: 'Type', attr: 'missionaryType' },
		{ title: 'Name', attr: 'fullName' },
	];
	config: OmniTableConfig = {
		noLinks: true,
		rowButtons: [
			{
				function: (missionary) =>
					(this.missionaries = filter(this.missionaries, (m) => m.missionaryId !== missionary.missionaryId)),
				text: 'Remove',
			},
		],
	};

	constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: AddMissionaryConfig) {}

	ngOnInit() {
		this.dialogTemplateConfig = {
			title: 'Add Missionary',
			confirmClick: () => this.save(),
			effectiveDate: this.data.effectiveDate,
			effectiveDateChange: (date) => (this.data.effectiveDate = date),
		};
	}

	save() {
		this.dialogRef.close({
			missionaries: this.missionaries.map((missionary) => {
				missionary.type = missionary.missionaryType;
				missionary.scheduledArrival = missionary.schedArrivalDate;
				missionary.scheduledDeparture = missionary.schedDepartureDate;
				missionary.mission = missionary.missionName;
				return missionary;
			}),
			effectiveDate: this.data.effectiveDate,
		});
	}
}
