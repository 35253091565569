<div class="page" [class.no-subheader]="!title" [class.tab-page]="tabPage">
	<mat-progress-bar mode="indeterminate" [class.hidden]="!loading || hideLoadingBar"></mat-progress-bar>
	<div class="subheader">
		<mat-icon *ngIf="backLink" (click)="back()" class="back">arrow_back</mat-icon>
		<div cy-elm="page-title" class="title">{{title}}</div>
		<div class="actions"><ng-container *ngIf="!isLoadingImageVisible()"><ng-content select="[actions]"></ng-content></ng-container></div>
	</div>
	<ng-container *ngIf="isLoadingImageVisible()">
		<div class="loading-image"></div>
		<div class="loading-text">Loading</div>
	</ng-container>
	<div class="content" id="page-container" [class.hidden]="isLoadingImageVisible()"><ng-content select="[content]"></ng-content></div>
</div>
