import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from '@mtc/shared/pipes';
import { MtcOverlayModule } from '@mtc/shared/overlay';

import { MiniClockComponent } from './components/mini-clock/mini-clock.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ClockHighlightPipe } from './pipes/clock-highlight.pipe';
import { ClockNumPipe } from './pipes/clock-num.pipe';
import { RotationMinutesPipe } from './pipes/rotation-minutes.pipe';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatCardModule,
		MatInputModule,
		MatIconModule,
		MtcOverlayModule,
		PipesModule,
	],
	declarations: [MiniClockComponent, TimePickerComponent, ClockHighlightPipe, ClockNumPipe, RotationMinutesPipe],
	exports: [MiniClockComponent, TimePickerComponent],
	providers: [DatePipe],
})
export class TimePickerModule {}
