import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { parse } from 'papaparse';
import * as _ from 'lodash';
import { DialogTemplateConfig } from '@mtc/shared/dialog';

@Component({
	selector: 'mtc-import',
	templateUrl: 'import.dialog.html',
	styleUrls: ['import.dialog.scss'],
})
export class ImportDialog implements OnInit {
	dialogTemplateConfig: DialogTemplateConfig = {
		title: 'Import',
		confirmText: 'Import',
		confirmClick: () => this.import(),
	};
	templateCSV;
	templateFileName;

	get filename() {
		return this.file ? this.file.name : 'No File Chosen';
	}
	file;

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private sanitized: DomSanitizer,
	) {}

	ngOnInit() {
		this.dialogTemplateConfig.title = `Import ${this.dialogData.name}`;
		this.templateCSV = this.sanitized.bypassSecurityTrustUrl(
			'data:application/csv;charset=utf-8,' + this.dialogData.columns.map((c) => c.title).join(','),
		);
		this.templateFileName = this.dialogData.fileName + '.csv';
	}

	clearFile() {
		delete this.file;
	}

	import() {
		if (this.file) {
			parse(this.file, {
				header: true,
				skipEmptyLines: true,
				complete: (results) => {
					this.dialogRef.close(this.convertToAttr(results.data));
				},
			});
		}
	}

	convertToAttr(rows) {
		return rows.map((r) => {
			const row = {};
			Object.keys(r).forEach((a) => {
				const column = this.dialogData.columns.find((c) => this.hideWhiteSpace(c.title) === a);
				row[column.attr] = r[a];
			});
			return row;
		});
	}

	hideWhiteSpace(string) {
		return _.cloneDeep(string)
			.split(' ')
			.join('');
	}
}
