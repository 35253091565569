import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { zip, combineLatest } from 'rxjs';

@Component({
	selector: 'mtc-api-versions-dialog',
	templateUrl: './api-versions.dialog.html',
	styleUrls: ['./api-versions.dialog.scss'],
})
export class ApiVersionsDialog implements OnInit {
	apiInformation: any = [];
	loading = true;

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public urls: any,
		private http: HttpClient,
	) {
		zip(...this.urls.map((url) => this.http.get(url))).subscribe((responses) => {
			this.apiInformation = responses.map((r) => ({
				title: r['Implementation-Title'],
				version: r['Implementation-Version'].match(/([1-9]+[0-9]*)\.*|(0\.)|(0$)/g).join(''),
			}));
			this.loading = false;
		});
	}

	ngOnInit() {}
}
