import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'mtc-training-info-expander',
	templateUrl: './training-info-expander.component.html',
	styleUrls: ['./training-info-expander.component.scss'],
})
export class TrainingInfoExpanderComponent implements OnInit {
	@Input() trainingInfo: any = {};
	@Input() title = 'Training';

	constructor() {}

	ngOnInit(): void {}
}
