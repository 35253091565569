import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { mtcUrl, mtcToolsUrl, standardsUrl, missionaryUrl, colUrl } from '@mtc/shared/urls';

export interface MtcInfo {
	actions?: any[];
	booleanList?: any[];
	branches?: any[];
	buildings?: any[];
	classrooms?: any[];
	countries?: any[];
	defaultColors?: any[];
	omniColors?: any[];
	districts?: any[];
	genders?: any[];
	languages?: any[];
	languagecolors?: any[];
	mailboxes?: any[];
	missions?: any[];
	permissions?: any[];
	mtcs?: any[];
	residences?: any[];
	cameras?: any[];
	roomStatuses?: any[];
	schedules?: any[];
	securesubstatuses?: any[];
	statuses?: any[];
	sublanguages?: any[];
	substatuses?: any[];
	subusage?: any[];
	submissions?: any[];
	traininggroups?: any[];
	traininglanguages?: any[];
	trainingWeeks?: any[];
	types?: any[];
	assignments?: any[];
	usage?: any[];
	zones?: any[];
	recordTypes?: any[];
	noteConfigurations?: { tags: any[]; templates: any[]; visibilities: any[] };
	evaluationConfigurations?: { visibilities: any[] };
}

@Injectable({
	providedIn: 'root',
})
export class MtcInfoService {
	public info: MtcInfo = {};
	public sizes = [];

	constructor(private http: HttpClient) {}

	load(include: string[] = []) {
		const observables = [
			{ url: `${mtcUrl}/mtcs/buildings`, label: 'buildings' },
			{ url: `${mtcUrl}/cameras`, label: 'cameras' },
			{ url: `${mtcUrl}/mtcs/residences`, label: 'residences' },
			{ url: `${mtcUrl}/mtcs/classrooms`, label: 'classrooms' },
			{ url: `${mtcUrl}/mtcs/mailboxes`, label: 'mailboxes' },
			{ url: `${mtcUrl}/mtcs/usage`, label: 'usage' },
			{ url: `${mtcUrl}/mtcs/subusage`, label: 'subusage' },
			{ url: `${mtcUrl}/mtcs/schedules`, label: 'schedules' },
			{ url: `${mtcUrl}/mtcs/zones`, label: 'zones' },
			{ url: `${mtcToolsUrl}/records/types`, label: 'recordTypes' },
			{ url: `${mtcUrl}/mtcs/branches`, label: 'branches' },
			{ url: `${mtcUrl}/mtcs/ecclgroups`, label: 'districts' },
			{ url: `${mtcUrl}/mtcs/traininglanguages`, label: 'traininglanguages' },
			{ url: `${mtcUrl}/mtcs/sublanguages`, label: 'sublanguages' },
			{ url: `${mtcToolsUrl}/missions`, label: 'missions' },
			{ url: `${colUrl}/churchunits/mtcs`, label: 'mtcs' },
			{ url: `${missionaryUrl}/statuses`, label: 'statuses' },
			{ url: `${missionaryUrl}/substatuses`, label: 'substatuses' },
			{ url: `${missionaryUrl}/types`, label: 'types' },
			{ url: `${missionaryUrl}/submissions`, label: 'submissions' },
			{ url: `${missionaryUrl}/traininggroups`, label: 'traininggroups' },
			{ url: `${missionaryUrl}/securesubstatuses`, label: 'securesubstatuses' },
			{ url: `${standardsUrl}/languages`, label: 'languages' },
			{ url: `${standardsUrl}/countries`, label: 'countries' },
			{ url: `${mtcToolsUrl}/change/actions`, label: 'actions' },
			{ url: `${mtcToolsUrl}/tools/languagecolors`, label: 'languagecolors' },
			{ url: `${mtcToolsUrl}/noteconfig`, label: 'noteConfigurations' },
			{ url: `${mtcToolsUrl}/defaultcolors`, label: 'defaultColors' },
			{ url: `${mtcToolsUrl}/permissions/all`, label: 'permissions' },
			{ url: `${mtcToolsUrl}/defaultcolors?distinct-sort=true`, label: 'omniColors' },
			{ url: `${mtcToolsUrl}/missionaries/leadership/positions`, label: 'assignments' },
			{
				url: `${mtcToolsUrl}/employeemanager/evaluation/configurations`,
				label: 'evaluationConfigurations',
			},
		]
			.filter((endpoint) => !include.length || include.includes(endpoint.label))
			.map((endpoint) => {
				return this.http.get(endpoint.url).pipe(
					tap((data: any[]) => {
						this.info[endpoint.label] = data;
					}),
				);
			});

		const nameMapper = (item) => ({ name: item, id: item });
		this.info.roomStatuses = [
			{ name: 'Active', id: '1' },
			{ name: 'Offline', id: '2' },
		];
		this.info.booleanList = [
			{ name: 'Yes', id: '1' },
			{ name: 'No', id: '0' },
		];
		this.info.genders = [
			{ id: 'M', name: 'Male' },
			{ id: 'F', name: 'Female' },
		];
		this.info.trainingWeeks = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'].map(
			nameMapper,
		);
		return zip(...observables);
	}
}
