import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { observable, computed, action } from 'mobx';
import { isString, orderBy, isNil, assign, cloneDeep, get, uniq, pullAllBy, find, findIndex, set } from 'lodash';
import { MtcInfoService } from '@mtc/shared/info';
import { PermissionService } from '@mtc/shared/permissions';
import { DynamicPipe } from '@mtc/shared/pipes';
import { OmniTableColumn, ColorBarConfig } from '../../models/column.model';
import { OmniTableConfig } from '../../models/config.model';
import { DialogService } from '@mtc/shared/dialog';
import { ImportDialog } from '../../dialogs/import/import.dialog';
import { ManageColumnsDialog } from '../../dialogs/manage-columns/manage-columns.dialog';
import { remove as removeAccents } from 'diacritics';
import { HttpClient } from '@angular/common/http';
import { mtcToolsUrl } from '@mtc/shared/urls';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'mtc-omni-table',
	templateUrl: './omni-table.component.html',
	styleUrls: ['./omni-table.component.scss'],
})
export class OmniTableComponent implements OnInit {
	@observable @Input('rows') passedRows = [];
	@observable @Input('columns') passedColumns: OmniTableColumn[] = [];
	@observable @Input('config') passedConfig: OmniTableConfig = {};
	@observable sortedColumns = [];
	@observable columnFilters = [];
	@observable vsScroll = 0;
	@ViewChild('downloadResults', { static: false }) downloadResults: ElementRef;
	/** Outputs the rows that were imported from the .csv file. */
	@Output() rowsImported: EventEmitter<any> = new EventEmitter<any>();
	hoverIndex;
	selectedAutoCompleteCell;
	bufferCells = 10;
	defaultConfig: OmniTableConfig = {
		placeholder: 'No items to display',
		resultsCountName: 'Item',
		topButtons: [],
		rowButtons: [],
	};
	@observable height = 0;

	constructor(
		private mtcInfo: MtcInfoService,
		private permissions: PermissionService,
		private domSanitizer: DomSanitizer,
		private dynamicPipe: DynamicPipe,
		private dialogService: DialogService,
		private http: HttpClient,
	) {}

	ngOnInit() {}

	@computed get rows() {
		const getLowerValue = (attr: string) => (row: any) => {
			return isString(row[attr]) ? (row[attr] || '').toLowerCase() : row[attr];
		};
		const cols = this.sortedColumns.reduce(
			(arr, c) => arr.concat([getLowerValue(c.attr), getLowerValue(c.secondAttr)]),
			[],
		);
		const sortDirections = this.sortedColumns.reduce(
			(arr, c) => arr.concat([c.sortDirection, c.sortDirection]),
			[],
		);
		return orderBy(
			(this.passedRows || []).filter((r) =>
				this.columnFilters.every((c) =>
					this.dynamicPipe
						.transform(removeAccents(r[c.attr] || ''), c.type)
						.toString()
						.toLowerCase()
						.includes(removeAccents((c.filter || '').toLowerCase())),
				),
			),
			cols,
			sortDirections,
		);
	}

	@computed get colorInfo() {
		return this.rows.map((row, i) => {
			const colorInfo: any = {};
			if (this.colorBarColumn) {
				const colorAttrValue = row[this.colorBarColumn.attr];
				colorInfo.barColor = this.colorGroups[colorAttrValue];
				if (i !== this.rows.length - 1) {
					const nextRow = this.rows[i + 1];
					const nextRowAttr = nextRow[this.colorBarColumn.attr];
					const nextRowColor = this.colorGroups[nextRowAttr];
					colorInfo.colorSameAsNext = colorInfo.barColor === nextRowColor;
				}

				if (i !== 0) {
					const prevRow = this.rows[i - 1];
					const prevRowAttr = prevRow[this.colorBarColumn.attr];
					const prevRowColor = this.colorGroups[prevRowAttr];
					colorInfo.isFirst = colorInfo.barColor !== prevRowColor;
				} else {
					colorInfo.isFirst = true;
				}
			}

			return colorInfo;
		});
	}

	@computed get columns(): OmniTableColumn[] {
		return this.passedColumns.map((c, i) => {
			const column = { ...c, columnId: i };
			if (column.iconConfig) {
				column.type = 'icon';
			}
			if (column.editingConfig) {
				column.type = 'editing';
				if (column.editingConfig.permission && !this.permissions.has(column.editingConfig.permission)) {
					column.type = null;
				}
			}
			if (isNil(column.visible)) {
				column.visible = true;
			}
			return column;
		});
	}

	@computed get config() {
		return assign(cloneDeep(this.defaultConfig), this.passedConfig);
	}

	@computed get colorGroups() {
		if (this.colorBarColumn) {
			return this.colorValues.reduce((groups, value, index) => {
				groups[value] = this.colorBarConfig.attrIsColor ? value : this.colors[index];
				return groups;
			}, {});
		}
		return null;
	}

	@computed get colors() {
		if (this.colorBarColumn) {
			return (this.colorBarConfig.colors || []).concat(this.mtcInfo.info.omniColors.map((color) => color.name));
		}
		return [];
	}

	@computed get colorValues() {
		if (!this.colorBarColumn) {
			return [];
		}
		return get(
			this.colorBarColumn,
			'colorBarConfig.values',
			uniq(this.passedRows.map((r) => r[this.colorBarColumn.attr])),
		);
	}

	@computed get colorBarColumn() {
		return this.columns.find((c) => !!c.colorBarConfig);
	}

	@computed get colorBarConfig(): ColorBarConfig {
		if (this.colorBarColumn && this.colorBarColumn.colorBarConfig !== true) {
			return this.colorBarColumn.colorBarConfig;
		}
		return {};
	}

	@computed get gridTemplateColumns() {
		const leftColumns = [];
		if (this.colorBarColumn) {
			leftColumns.push('8px');
		}
		if (this.showCheckboxes) {
			leftColumns.push('45px');
		}
		if (this.colorBarColumn && !this.colorBarConfig.hideLabel) {
			leftColumns.push(
				`minmax(${this.colorBarColumn.minWidth || '100px'}, ${this.colorBarColumn.maxWidth || '1fr'})`,
			);
		}
		if (this.config.newItems) {
			leftColumns.push('20px');
		}

		const buttonColumn = this.permissibleRowButtons.length ? ' min-content' : '';

		return this.domSanitizer.bypassSecurityTrustStyle(
			leftColumns.join(' ') +
				' ' +
				this.visibleColumns
					.filter((c) => !c.colorBarConfig)
					.map((c) => {
						if (c.type === 'image') {
							return '65px';
						}
						return `minmax(${c.minWidth || '100px'}, ${c.maxWidth || '1fr'})`;
					})
					.join(' ') +
				buttonColumn,
		);
	}

	@computed get hasSecondAttr() {
		return this.visibleColumns.some((c) => !!c.secondAttr);
	}

	@computed get rowHeight() {
		return this.config.showAll ? 'min-content' : this.hasSecondAttr ? '48px' : '40px';
	}

	@computed get newItemCount() {
		return this.rows.reduce((acc, row) => (row.newItem ? acc + 1 : acc), 0);
	}

	@computed get showCheckboxes() {
		return this.permissibleTopButtons.some((b) => !b.alwaysVisible);
	}

	@computed get allSelected() {
		return this.rows.length && this.rows.every((r) => r.selected);
	}

	set allSelected(val) {
		this.rows.forEach((r) => (r.selected = val));
	}

	@computed get showRowHover() {
		return (
			this.showCheckboxes ||
			this.config.rowFunction ||
			get(this.config.rowButtons, 'length') ||
			this.columns.some((c) => !!c.editingConfig)
		);
	}

	@computed get selectedRows() {
		return this.rows.filter((row) => row.selected);
	}

	hasButtonPermission(button) {
		return !button.permission || this.permissions.has(button.permission);
	}

	@computed
	get permissibleTopButtons() {
		return this.config.topButtons.filter((b) => this.hasButtonPermission(b));
	}

	@computed
	get permissibleRowButtons() {
		return this.config.rowButtons.filter((b) => this.hasButtonPermission(b));
	}

	@computed get hasDisabledRow() {
		return this.rows.some((row) => row.disabled);
	}

	@computed
	get permissibleColumns() {
		return this.columns.filter((c) => !c.permission || this.permissions.has(c.permission));
	}

	@computed get visibleColumns() {
		return this.permissibleColumns.filter((c) => isNil(c.visible) || c.visible);
	}

	@computed
	get visibleTopButtons() {
		return this.permissibleTopButtons.filter((button) =>
			button.displayFunction
				? button.displayFunction(this.selectedRows)
				: button.alwaysVisible || this.selectedRows.length,
		);
	}

	@computed
	get visibleRowCount() {
		return !this.config.showAll
			? Math.ceil(this.height / +this.rowHeight.substring(0, 2)) + this.bufferCells
			: this.passedRows.length;
	}

	@computed
	get topIndex() {
		return this.config.showAll ? 0 : Math.floor(this.vsScroll / +this.rowHeight.substring(0, 2));
	}

	@computed
	get bottomIndex() {
		return Math.min(this.topIndex + this.visibleRowCount + 1, this.passedRows.length);
	}

	export() {
		const exportColumns = this.visibleColumns.filter((col) => col.title && (col.attr || col.secondAttr));

		const csv =
			exportColumns.map((c) => c.title).join(',') +
			'\n' +
			this.rows
				.map((row) => {
					return exportColumns
						.map((column) => {
							const type = column.attr || column.secondAttr;
							return `"${this.dynamicPipe.transform(get(row, type), column.type)}"`;
						})
						.join(',');
				})
				.join('\n');
		const csvBlob = new Blob(['\ufeff', csv], { type: 'text/csv' });

		this.downloadResults.nativeElement.setAttribute('href', URL.createObjectURL(csvBlob));
	}

	@computed
	get resultsCountName(): string {
		return String(this.config.resultsCountName);
	}

	openImport() {
		this.dialogService
			.open(ImportDialog, {
				data: {
					name: this.config.resultsCountName,
					columns: this.permissibleColumns,
					fileName: this.config.import,
				},
			})
			.subscribe((importedRows) => {
				this.rowsImported.emit(importedRows);
			});
	}

	@action
	openManageColumns() {
		const manageColumnsconfig = {
			data: {
				columns: this.permissibleColumns,
				buttonFunction: this.config.manageColumns,
				new: true, //TODO remove this when all omni-tables that use manage columns are new
			},
			width: '400px',
		};
		this.dialogService.open(ManageColumnsDialog, manageColumnsconfig).subscribe((columnInfo) => {
			this.passedColumns = columnInfo.columns;
			if (columnInfo.saveAsDefault) {
				this.http
					.post(`${mtcToolsUrl}/defaultcolumns`, {
						columns: JSON.stringify(this.permissibleColumns),
						tableGroup: this.config.manageColumns,
					})
					.subscribe();
			}
		});
	}

	@action
	sortColumns(column, sortDirection) {
		if (column.type === 'image') return;
		if (sortDirection === 'toggle') {
			sortDirection = column.sortDirection ? 'desc' : 'asc';
		}
		if (column.sortDirection === sortDirection) {
			pullAllBy(this.sortedColumns, [column], 'columnId');
			delete column.sortDirection;
			delete column.sortOrder;
		} else if (!find(this.sortedColumns, ['columnId', column.columnId])) {
			column.sortDirection = sortDirection;
			this.sortedColumns.push(column);
		} else {
			column.sortDirection = sortDirection;
			find(this.sortedColumns, ['columnId', column.columnId]).sortDirection = sortDirection;
		}

		this.visibleColumns.forEach((c) => {
			c.sortOrder = findIndex(this.sortedColumns, ['columnId', c['columnId']]) + 1;
		});
	}

	@action
	filterColumns(column, searchQuery) {
		if (column.filter && !searchQuery) {
			pullAllBy(this.columnFilters, [column], 'columnId');
			delete column.filter;
		} else if (!find(this.columnFilters, ['columnId', column.columnId])) {
			column.filter = searchQuery;
			this.columnFilters.push(column);
		} else {
			column.filter = searchQuery;
			find(this.columnFilters, ['columnId', column.columnId]).filter = searchQuery;
		}
	}

	@action
	clearHover() {
		this.passedRows.forEach((r) => (r.hover = false));
	}

	handleCellClick(column, row) {
		if (column.function) {
			column.function(row, column);
		} else {
			this.handleRowClick(row);
		}
	}

	handleRowClick(row) {
		if (this.config.rowFunction && !row.disabled) {
			this.config.rowFunction(row);
		}
		if (this.showCheckboxes) {
			row.selected = !row.selected;
		}
	}

	getAttr(object, path) {
		return get(object, path, '');
	}

	setAttr(object, attr, value) {
		set(object, attr, value);
	}

	stopProp(event: Event) {
		event.stopPropagation();
	}

	isButtonHidden(button, row) {
		return !row.hover || (button.displayFunction && !button.displayFunction(row));
	}

	handleButtonClick(button, row) {
		if (!row.disabled) {
			button.function(row);
		}
	}

	getIconColor(row, column) {
		if (row.hover && column.hover && (column.iconConfig.hoverColor || column.iconConfig.hoverColorAttr)) {
			return column.iconConfig.hoverColor || row[column.iconConfig.hoverColorAttr];
		} else {
			return column.iconConfig.color || row[column.iconConfig.colorAttr];
		}
	}

	handleAttrChange(newValue, row, column) {
		set(row, column.attr, newValue);
		column.editingConfig.function(newValue, row);
	}

	handleKeyDown(key, element, isTextArea = false) {
		if (key.code === 'Enter' && (!isTextArea || !key.shiftKey)) {
			element.blur();
		}
	}

	handleKeyPress(element, maxLength) {
		// This doesn't protect against pasted text length
		return !(maxLength && element.innerHTML.length >= maxLength);
	}

	closeAutoComplete() {
		this.selectedAutoCompleteCell = false;
	}

	openFilter(element, column, event) {
		if (!column.iconConfig && column.type !== 'image') {
			column.menuOpen = true;
			setTimeout(() => element.focus());
		}
		event.stopPropagation();
	}
}
