import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: 'input' })
export class PreventAutocompleteDirective {
	@Input() allowAutocomplete = false;

	constructor(el: ElementRef) {
		if (!this.allowAutocomplete) {
			el.nativeElement.autocomplete = 'off';
		}
	}
}
